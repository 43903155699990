import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../services/Notification.service';
import { iStudentNavUnits } from '../models/studentunits.interface';
import { SharedService } from '../shared/services/shared.service';
import {timer} from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  providers: [ NotificationService ]
})

export class LayoutComponent implements OnInit, OnDestroy {
  user_name: string;
  selected_nav_unit: iStudentNavUnits;
  bell_classes: string;
  little: boolean;
  footer_class: string;
  nservice: any;
  new_notifications: boolean;
  course_logo: string;
  current_page: string;
  move_footer: string;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private nServ: NotificationService) {
      document.documentElement.style.setProperty('--primary-color', this.authService.getPrimaryColor());
      document.documentElement.style.setProperty('--secondary-color', this.authService.getSecondaryColor());
      document.documentElement.style.setProperty('--primary-font', this.authService.getPrimaryFont());
      document.documentElement.style.setProperty('--secondary-font', this.authService.getSecondaryFont());

      this.little = this.authService.getLittleKid() == 'true';
      this.footer_class = this.little ? "little-footer-circle-mask" : "footer-circle-mask";

      timer(0, 300000).subscribe(() => { 
        this.GetUnreadNotificationCount();
      });
  }

  ngOnInit() {
    this.sharedService.getSelectedUnit().subscribe((value) => {
      this.selected_nav_unit = value;
    })

    this.sharedService.getBellClasses().subscribe((value) => {
      this.bell_classes = value;
    })

    this.user_name = this.authService.getName();
    this.sharedService.getNotifications();

    this.course_logo = this.authService.getCourseLogo();

    this.sharedService.navbarCollapsedSubject.subscribe(value => {
      this.move_footer = value ? "" : "move_footer";
    });
  }

  GetUnreadNotificationCount(){
    this.nservice = this.nServ.GetUnreadCount().subscribe(count => {
      this.new_notifications = count['data']['total_unread'] > 0;
      
      if (this.new_notifications){
        this.sharedService.setBellClasses("nav-link notification-indicator notification-indicator-primary px-0 icon-indicator");
      } else {
        this.sharedService.setBellClasses("nav-link notification-indicator-primary px-0 icon-indicator");
      }
    })
  }

  triggerNotifications(){
    this.sharedService.getNotifications();
  }

  logout(){
    this.authService.logOut();
  }

  ngOnDestroy(){
    this.nservice.unsubscribe();
  }
}
