import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { BigUnitSelectComponent } from "./bigunitselect.component";

const BIG_UNIT_SELECT_ROUTE = [
    { path: '', component: BigUnitSelectComponent }
];

@NgModule ({
    declarations: [
        BigUnitSelectComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(BIG_UNIT_SELECT_ROUTE)
    ],
    exports: [
        BigUnitSelectComponent
    ]
})

export class BigUnitSelectModule {  }