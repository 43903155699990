<ul *ngIf="navbar_collapsed" class="navbar-nav flex-column">
  <li class="nav-item">
    <div class="nav-background little-vert-icon">
      <a class="nav-link" (click)="collapseVerticalNavbar(false); setSelected('nav')">
        <span class="nav-link-icon">
            <img width="50" src="../../assets/img/icons/unit-nav-icon.svg" />
        </span>
      </a>
    </div>

    <div class="nav-background little-vert-icon">
      <a [ngClass]="bell_classes" class="little_navbar_indicator" role="button" data-toggle="modal" href="#notifications-modal" (click)="triggerNotifications()">
        <span class="nav-link-icon">
          <img width="50" src="../../assets/img/icons/button-bell.svg" />
        </span>
      </a>
    </div>
  </li>
</ul>
      