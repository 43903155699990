<span data-toggle="tooltip" data-placement="top" title="Mark each step DONE to submit to teacher">
  <svg xmlns="http://www.w3.org/2000/svg" width="150" viewBox="0 0 256 50">
    <g id="Group_250" data-name="Group 250" transform="translate(1062 -18656)">
      <path id="Path_149" data-name="Path 149" d="M4,0H252a4,4,0,0,1,4,4V46a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(-1062 18656)" fill="#707070"/>
      <text id="Finish_Section" data-name="Finish Section" transform="translate(-1011.75 18669)" fill="#fff" font-size="22" font-family="Arimo-Bold, Arimo" font-weight="700"><tspan x="12" y="20">FINISH SECTION</tspan></text>
      <g id="Icon_Activities" data-name="Icon / Activities" transform="translate(-1041 18666)">
        <path id="Icon_Activities-2" data-name="Icon / Activities" d="M25,3H8.333A3.343,3.343,0,0,0,5,6.333V33l11.667-5,11.667,5V6.333A3.343,3.343,0,0,0,25,3ZM22.167,23.833l-5.5-3.333-5.5,3.333,1.5-6.167L7.833,13.5l6.333-.5,2.5-5.833L19.167,13l6.333.5-4.833,4.167Z" transform="translate(-5 -3)" fill="#fff"/>
      </g>
    </g>
  </svg>
</span>
