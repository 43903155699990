import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iPuzzleActivity } from '../../models/puzzleactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { SharedService } from '../../shared/services/shared.service';
import { AuthService } from '../../auth/auth.service';

import { imagePuzzle, isCompleted } from '../../../assets/js/imagepuzzle';

declare var imagePuzzle: any;
declare var isCompleted: any;

export interface activity_response {
	answerId: number,
	questionId: number
}

@Component({
	selector: 'bigpuzzle',
	templateUrl: './bigpuzzle.component.html',
	providers: [StudentGradesService, StudentSectionsService]
})

export class BigPuzzleComponent implements OnInit, OnDestroy {
	@Input() activity_id: string;
	@Output() resubmitResponse = new EventEmitter();
	sgservice: any;
	activity: iPuzzleActivity;
	selected_section: iStudentSection;
	ssservice: any;
	response: activity_response[];
	directions: string;
	isComplete = false;

	constructor(
		private sgServ: StudentGradesService,
		private ssServ: StudentSectionsService,
		private sharedService: SharedService,
		private authService: AuthService ) {
		this.response = [];
	}

	ngOnInit() {
		this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe(activity => {
			this.activity = activity['data'];

			this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
				this.selected_section = section['data'];

				if (this.activity.data['directions'] && this.activity.data['directions'] !== ""){
				  this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
				} else if (this.selected_section.section_directions){
				  this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
				}
			})

			setTimeout(() => {
				this.splitImage();
			}, 1000);
		})
	}

	splitImage() {
		this.isComplete = isCompleted;
		imagePuzzle.startGame(this.activity.data.puzzle_image.file, 3);
	}

	submitActivity(show_success: boolean) {
		this.sharedService.submitActivityResponse(this.activity.id, { completed: true }, show_success);
	}
	resubmit() {
		this.resubmitResponse.emit({ completed: true });
	}

	ngOnDestroy() {
		if (this.sgservice) { this.sgservice.unsubscribe(); };
		if (this.ssservice) { this.ssservice.unsubscribe(); };
	}

}
