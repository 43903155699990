<div class="collapse navbar-collapse" id="navbarVerticalCollapse" [ngClass]="vert_navbar_class">
    <div class="navbar-vertical-content scrollbar">
      <!-- Collapsed Nav Bar -->
      <bigvertcollapsed *ngIf="!little"></bigvertcollapsed>
      <littlevertcollapsed *ngIf="little"></littlevertcollapsed>

      <!-- Expanded Nav Bar -->
      <bigvertexpanded *ngIf="!little"></bigvertexpanded>
      <littlevertexpanded *ngIf="little"></littlevertexpanded>

      <!-- Unit Vocab in Nav Bar -->
      <unitvocab></unitvocab>

      <!-- Unit Nav in Nav Bar -->
      <unitnav *ngIf="!little"></unitnav>
      <littleunitnav *ngIf="little"></littleunitnav>
    </div>
</div>