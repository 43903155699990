<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>
  <div *ngIf="activity.response" style="margin-bottom: 40px;">
    <img *ngIf="activity.response.data" [src]="activity.response.data.file" style="width: 100%; border-radius: 6px;" />
  </div>
  <fileupload (uploadEvent)="uploadedFiles($event)" name="image"></fileupload>
  
  <div class="text-center save-activity-button">
    <bigactivitydonedisabledbutton *ngIf="!activity.locked && !selected_section.completed && disabled" data-dismiss="modal"></bigactivitydonedisabledbutton>
    <bigactivitydonebutton *ngIf="!activity.locked && !activity.completed && !disabled" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed && !disabled" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div>