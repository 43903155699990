<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>

  <div class="row">
    <div class="col-md-4 text-right" style="padding:10px;">
      <span>{{review_deck.length}} / {{totalWords}}</span>
      <div class="card-deck-container">
        <div class="flashcard-container-title">
          <div class="card-pile-title" >Needs more work</div>
          <div class="card-text-wrapper" (click)="selectCard()"
               cdkDropList 
               #reviewCards="cdkDropList"
               [cdkDropListConnectedTo]="[viewCards]"
               (cdkDropListDropped)="drop($event)"
               [cdkDropListData]="review_deck">
            <bigreviewcardspile></bigreviewcardspile>
            <div class="card-text" *ngIf="review_deck.length > 0">{{ review_deck[0].word }}</div>
          </div>
          <div class="deck-action-button" (click)="shuffleCards()">
            Shuffle
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4" style="padding:10px;">
      <div class="current-flashcard-container">
        <div *ngIf="!show_definition">
          <div *ngIf="current_card" (click)="flipCard(current_card)" class="flashcard-front" >
            {{ current_card.word }}
          </div>
        </div>
        <div *ngIf="show_definition">
          <div class="flashcard-back" 
               cdkDropList 
               #viewCards="cdkDropList"
               [cdkDropListConnectedTo]="[reviewCards, learnCards]"
               [cdkDropListData]="current_card"
               style="height: 400px; overflow-y: auto"
               cdkScrollable>
            <div cdkDrag>
              <div class="card-back-word">
                {{ current_card?.word }}
              </div>
                <div class="card-back-definition">
                  {{ current_card?.explanation }}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-right" style="padding:10px;">
      <span>{{learned_deck.length}} / {{totalWords}}</span>
      <div class="card-deck-container">
        <div class="flashcard-container-title">
          <div class="card-pile-title">I got this one!</div>
          <div class="card-text-wrapper"
               cdkDropList 
               #learnCards="cdkDropList"
               (cdkDropListDropped)="drop1($event)"
               [cdkDropListData]="learned_deck">
            <biglearnedcardspile></biglearnedcardspile>
            <div class="card-text" *ngIf="learned_deck.length > 0">{{ learned_deck[0].word }}</div>
          </div>
          <div class="deck-action-button" (click)="resetDecks()">
            Review Again
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center save-activity-button">
    <bigactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div>