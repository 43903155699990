import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { BigNotificationsComponent } from "./bignotifications.component";

const BIG_NOTIFICATIONS_ROUTE = [
    { path: '', component: BigNotificationsComponent }
];

@NgModule ({
    declarations: [
        BigNotificationsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(BIG_NOTIFICATIONS_ROUTE)
    ],
    exports: [
        BigNotificationsComponent
    ]
})

export class BigNotificationsModule {  }