<div *ngIf="!navbar_collapsed && nav_icon_selected == 'vocab'" class="unit-vocab-container">
  <div style="padding-right: 20px; margin-bottom: 10px;">
    <input type="email" class="form-control" id="search-text" aria-describedby="search-text" 
      [(ngModel)]="searchText" placeholder="Enter text to search" autofocus>
  </div>
  <perfect-scrollbar class="unit-vocab-scrollbar-height">
    <div *ngFor="let vocab of unit_vocab | vocabFilter: searchText; let i = index;" style="padding-right: 20px;">
      <span class="vocab-word">{{ vocab.word }}:</span>
      <br>
      <span class="vocab-definition">{{ vocab.explanation }}</span>
      <div *ngIf="i < unit_vocab.length - 1" class="navbar-vertical-divider" style="padding-top: 10px; padding-bottom: 10px;">
        <hr class="navbar-vertical-hr my-2" />
      </div>
    </div>
  </perfect-scrollbar>
</div>