import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iInstituteCurriculums } from '../models/institute.interface';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
    })
};

@Injectable()
export class InstituteCurriculumsService {
    constructor(private http: HttpClient) { }

    CreateInstituteCurriculum(Institute: iInstituteCurriculums): Observable<any> {
        return this.http.post(environment.conn_string + "api/institute_curriculums", Institute, httpOptions);
    }

    UpdateInstituteCurriculum(InstituteId, formdata): Observable<any> {
        const httpOptions1 = {
            headers: new HttpHeaders({
              'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
            })
        };

        // var requestOptions = {
        //     method: 'PUT',
        //     headers: myHeaders,
        //     body: formdata,
        //     redirect: 'follow'
        //   };
        return this.http.put(environment.conn_string + "api/institute_curriculums/update/" + InstituteId, formdata, httpOptions1);
    }

    AppendInsituteCurriculum(InstituteId, formdata): Observable<any> {
        const httpOptions1 = {
            headers: new HttpHeaders({
              'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
            })
        };

        return this.http.put(environment.conn_string + "api/institute_curriculums/append/" + InstituteId, formdata, httpOptions1);
    }
}