<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>
  <div [innerHtml]="activity.data.text | safeHtml"></div>


  <div class="text-center save-activity-button">
    <littleactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></littleactivitysubmittedbutton>
  </div>
</div>

