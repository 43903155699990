import { Component } from '@angular/core';

@Component({
    selector: 'bigactivitygetstartedbutton',
    templateUrl: './bigactivitygetstartedbutton.component.html'
})

export class BigActivityGetStartedButton {

  constructor( ) {  }
}
