import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { LittleVertExpandedComponent } from "./littlevertexpanded.component";

const LITTLE_VERT_EXPANDED_ROUTE = [
    { path: '', component: LittleVertExpandedComponent }
];

@NgModule ({
    declarations: [
        LittleVertExpandedComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(LITTLE_VERT_EXPANDED_ROUTE)
    ],
    exports: [
        LittleVertExpandedComponent
    ]
})

export class LittleVertExpandedModule {  }