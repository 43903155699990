import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { SharedService } from '../../shared/services/shared.service';

@Component({
    selector: 'littlevertcollapsed',
    templateUrl: './littlevertcollapsed.component.html',
    providers: [ ]
})

export class LittleVertCollapsedComponent implements OnInit, OnDestroy {
  navbar_collapsed: boolean;
  open_notifications: any;
  bell_classes: string;

  constructor( private sharedService: SharedService, private elRef: ElementRef ) { 
    this.navbar_collapsed = true; 
    this.sharedService.navbarCollapsedSubject.subscribe((value) => {
      this.navbar_collapsed = value;
    })
  }

  ngOnInit() { 
    const parentElement = this.elRef.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    this.open_notifications = document.getElementById('notifications-modal');

    this.sharedService.getBellClasses().subscribe((value) => {
      this.bell_classes = value;
    })
  }

  collapseVerticalNavbar(value: boolean){
    this.sharedService.collapseNavbar(value);
  }

  setSelected(type: string){
    this.sharedService.setCurrentPage(type);
  }

  triggerNotifications(){
    this.sharedService.getNotifications();
  }
  
  ngOnDestroy() { }
}
