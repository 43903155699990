import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iGrade } from '../models/grade.interface';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
    })
};

@Injectable()
export class GradeService {

    private class: Observable<iGrade[]>;

    constructor(private http: HttpClient) { }

    GetGrades() {
        return this.http.get<iGrade[]>(environment.conn_string + "api/grades", httpOptions);
    }
}