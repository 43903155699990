import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SharedModule } from "../../shared/shared.module";

import { LittleUnitNavComponent } from "./littleunitnav.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

const LITTLE_UNIT_NAV_ROUTE = [
    { path: '', component: LittleUnitNavComponent }
];

@NgModule ({
    declarations: [
        LittleUnitNavComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(LITTLE_UNIT_NAV_ROUTE),
        PerfectScrollbarModule,
        SharedModule
    ],
    providers: [
      {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      }
    ],
    exports: [
        LittleUnitNavComponent
    ]
})

export class LittleUnitNavModule {  }