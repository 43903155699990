<!-- <div *ngIf="activity && selected_section && selected_word">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>

  <div class="row">
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-6"><strong>Across</strong></div>
        <div class="col-md-6"><strong>Down</strong></div>
      </div>
    
      <div *ngFor="let item of [].constructor(max_num_words); let i = index;" class="row" style="margin-bottom: 20px; font-size: 12px;">
        <div class="col-md-6">
          <div *ngIf="across_words[i]" (click)="highlightHint(across_words[i])" [ngClass]="across_words[i].id == selected_word.id ? 'highlight' : ''" style="padding:10px; border-radius: 6px;">
            {{ across_words[i].number }}. {{ across_words[i].question }}
          </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="down_words[i]" (click)="highlightHint(down_words[i])" [ngClass]="down_words[i].id == selected_word.id ? 'highlight' : ''" style="padding:10px; border-radius: 6px;">
            {{ down_words[i].number }}. {{ down_words[i].question }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="gridWrapper">
        <div *ngFor="let cell of cells; let i = index;" class="box">
            <input type="text" 
                   maxlength="1" 
                   class="cwInput" 
                   id="{{i}}" 
                   [ngClass]="{'highlight': selected_word.locations.indexOf(cell.id) > -1, 'white-bg': cell.wbg, 'dark-bg': !cell.wbg}" 
                   [(ngModel)]="cell.letter" 
                   oninput="this.value = this.value.toUpperCase()"
                   (click)="highlightCells(i)" />
        </div>
      </div>
    </div>
  </div> -->

   
      <!-- <div class="row">
        <div class="col-md-6"><strong>Across</strong></div>
        <div class="col-md-6"><strong>Down</strong></div>
      </div>
    
      <div *ngFor="let item of [].constructor(max_num_words); let i = index;" class="row" style="margin-bottom: 20px; font-size: 12px;">
        <div class="col-md-6">
          <div *ngIf="across_words[i]" (click)="highlightHint(across_words[i])" [ngClass]="across_words[i].id == selected_word.id ? 'highlight' : ''" style="padding:10px; border-radius: 6px;">
            {{ across_words[i].number }}. {{ across_words[i].question }}
          </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="down_words[i]" (click)="highlightHint(down_words[i])" [ngClass]="down_words[i].id == selected_word.id ? 'highlight' : ''" style="padding:10px; border-radius: 6px;">
            {{ down_words[i].number }}. {{ down_words[i].question }}
          </div>
        </div>
      </div> -->
    
      <!-- <div class="gridWrapper">
        <div *ngFor="let cell of cells; let i = index;" class="box">
            <input type="text" 
                    maxlength="1" 
                    class="cwInput" 
                    id="{{i}}" 
                    [ngClass]="{'highlight': selected_word.locations.indexOf(cell.id) > -1, 'white-bg': cell.wbg, 'dark-bg': !cell.wbg}" 
                    [(ngModel)]="cell.letter" 
                    oninput="this.value = this.value.toUpperCase()"
                    (click)="highlightCells(i)" />
        </div>
      </div> -->
   
          
  <!-- <div class="text-center save-activity-button">
    <bigactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div> -->



<div *ngIf="activity">
  <div class="secondary-font text-center">
    Have fun completing the crossword puzzle!
  </div>
  <div class="text-center save-activity-button">
    <bigactivitygetstartedbutton 
      *ngIf="!activity.locked" 
      [attr.href]="'#crossword-modal' + activity.activity_number"
      data-toggle="modal" 
      data-backdrop="static" 
      data-keyboard="false">
    </bigactivitygetstartedbutton>
    <bigactivityviewsubmissionbutton 
      *ngIf="activity.locked && activity.completed"
      [attr.href]="'#crossword-modal' + activity.activity_number"
      data-toggle="modal" 
      data-backdrop="static" 
      data-keyboard="false">
    </bigactivityviewsubmissionbutton>
  </div>
</div>

<div *ngIf="activity && selected_section && selected_word" class="modal fade" id="crossword-modal{{activity.activity_number}}" tabindex="-1" role="dialog" aria-labelledby="details-modal-label" aria-hidden="true">
  <div class="modal-dialog mt-6 modal-xl sc-modal-border" role="document">
      <div class="modal-content border-0 sc-modal-content">
          <div class="modal-header px-5 text-white position-relative sc-modal-header">
            <div class="row" style="width: 100% !important;">
              <div class="col-md-12">
                <h4 class="mb-0 font-secondary" style="color: white;" id="hotspot-modal-label"><strong>{{ activity.title }}</strong></h4>
              </div>
            </div>
            <button class="btn-close position-absolute top-0 right-0 mt-2 mr-2" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body py-4 px-5">
            <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>
          
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-6"><strong>Across</strong></div>
                  <div class="col-md-6"><strong>Down</strong></div>
                </div>
              
                <div *ngFor="let item of [].constructor(max_num_words); let i = index;" class="row" style="margin-bottom: 20px; font-size: 12px;">
                  <div class="col-md-6">
                    <div *ngIf="across_words[i]" (click)="highlightHint(across_words[i])" [ngClass]="across_words[i].id == selected_word.id ? 'highlight' : ''" style="padding:10px; border-radius: 6px;">
                      {{ across_words[i].number }}. {{ across_words[i].question }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div *ngIf="down_words[i]" (click)="highlightHint(down_words[i])" [ngClass]="down_words[i].id == selected_word.id ? 'highlight' : ''" style="padding:10px; border-radius: 6px;">
                      {{ down_words[i].number }}. {{ down_words[i].question }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="gridWrapper">
                  <div *ngFor="let cell of cells; let i = index;" class="box">
                      <input type="text" 
                             maxlength="1" 
                             class="cwInput" 
                             id="{{i}}" 
                             [ngClass]="{'highlight': selected_word.locations.indexOf(cell.id) > -1, 'white-bg': cell.wbg, 'dark-bg': !cell.wbg}" 
                             [(ngModel)]="cell.letter" 
                             (click)="highlightCells(i)"
                             (keypress)="onKeyPress($event, i)"/>
                  </div>
                </div>
              </div>
            </div>
            
          
            <div class="text-center save-activity-button">
              <bigactivitydonebutton *ngIf="!activity.locked && !activity.completed" data-dismiss="modal" (click)="submitActivity(true)"></bigactivitydonebutton>
              <bigactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" data-dismiss="modal" (click)="submitActivity(true)"></bigactivitydonebutton>
              <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
              <bigactivityresubmitbutton *ngIf="activity.completed && !activity.locked && selected_section.completed" data-dismiss="modal" (click)="resubmit();" class="vert-align"></bigactivityresubmitbutton>
            </div>
            <div *ngIf="activity.locked && activity.completed" class="greyedOutModal"></div>
          </div>
      </div>
  </div>
</div>