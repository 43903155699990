import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { iFingerDraw } from '../../models/fingerdraw.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';
import { DrawingCanvasComponent } from '../../components/drawingcanvas.component';
import { ActivityService } from '../../services/Activity.service';
import { iImage } from '../../models/image.interface';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'bigfingerdraw',
  templateUrl: './bigfingerdraw.component.html',
  styleUrls: ['./bigfingerdraw.component.scss'],
  providers: [StudentGradesService, StudentSectionsService, ActivityService]
})

export class BigFingerDrawComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('canvas_container') public canvas_container: ElementRef;
  @ViewChild(DrawingCanvasComponent) public canvas: DrawingCanvasComponent;
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iFingerDraw;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  selected_color: string;
  selected_button: string;
  selected_px: number;
  directions: string;
  aservice: any;

  icon: string;

  canvas_width: number;
  canvas_height: number;

  num_textboxes: number;
  textboxes: number[];
  background_image: string;
  fdForm: FormGroup;

  constructor(
    private sgServ: StudentGradesService,
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService,
    private aServ: ActivityService,
    private formBuilder: FormBuilder) {
    this.selected_button = "draw";
    this.selected_color = "#000";
    this.selected_px = 2;
    this.icon = "assets/img/icons/pencil.png"
    this.num_textboxes = 0;
  }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];

      if (this.activity.response){
        this.background_image = this.activity.response.data['file'];
      } else if (this.activity.data.background_image){
        this.background_image = this.activity.data.background_image.file;
      }

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data['directions'] && this.activity.data['directions'] !== "") {
          this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
        } else if (this.activity.data['text'] && this.activity.data['text'] !== "") {
          this.directions = "<strong>Directions:</strong> " + this.activity.data['text'];
        } else if (this.selected_section.section_directions) {
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })

      this.fdForm = this.formBuilder.group({
        itemRows: this.formBuilder.array([])
      });
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.canvas_container) {
        this.canvas_width = this.canvas_container.nativeElement.clientWidth;
        this.canvas_height = this.canvas_container.nativeElement.clientHeight;
      }
    }, 1000);
  }

  initItemRows() {
    return this.formBuilder.group({
      text: ['']
    });
  }

  addNewRow() {
    const control = <FormArray>this.fdForm.controls['itemRows'];
    control.push(this.initItemRows());
  }

  deleteRow(index: number) {
      const control = <FormArray>this.fdForm.controls['itemRows'];
      control.removeAt(index);
  }

  getControls() {
    return (<FormArray>this.fdForm.get('itemRows')).controls;
  }

  setColor(color: string) {
    this.selected_color = color;
    this.selected_button = "draw";
    this.icon = "assets/img/icons/pencil.png"
  }

  setEraser() {
    this.selected_button = "erase";
    this.icon = "assets/img/icons/eraser.png"
  }

  setclear() {
    if (confirm('Are you sure you want to clear the drawing? Once done, your current drawing with be lost.')) {
      this.selected_button = "erase";
      setTimeout(() => {
        this.selected_button = "clear";
        this.icon = "assets/img/icons/pencil.png";
        if (this.activity.data.background_image){
          this.background_image = this.activity.data.background_image.file;
        } else {
          this.background_image = "";
        }

        let num = this.fdForm.controls['itemRows']['controls'].length;
        
        if(num > 0){
          for (let i = num - 1; i >= 0; i--){
            this.deleteRow(i);
          }
        }
      }, 500);
    }
  }

  autoGrowTextZone(e) {
    if (e.target.scrollHeight <  251){
      e.target.style.height = "0px";
      e.target.style.height = (e.target.scrollHeight + 25) + "px";
    }
  }

  submitActivity(show_success: boolean) {
    let text: string;
    let x_coord: number;
    let y_coord: number;
    let parent_position = document.getElementById('canvas_container').getBoundingClientRect();
    let input_list: {text: string, x: number, y: number}[];
    input_list = [];
    
    this.fdForm.value['itemRows'].forEach((t, index) => {
      let child_position = document.getElementById('tb' + index).getBoundingClientRect();
    
      text = t.text;
      x_coord = (child_position.left - parent_position.left) + 18;
      y_coord = (child_position.top - parent_position.top) + 20;

      input_list.push({text: text, x: x_coord, y: y_coord});
      this.deleteRow(0);
    });
    
    let saved_drawing = this.canvas.saveDrawing(input_list);

    const formData = new FormData();
    formData.append('filepond', saved_drawing);

    this.aservice = this.aServ.UploadFile(formData).subscribe(file => {
      let uploaded_image: iImage = file['data'];
      this.sharedService.submitActivityResponse(this.activity.id, uploaded_image, show_success);
    })
  }

  resubmit() {
    let text: string;
    let x_coord: number;
    let y_coord: number;
    let parent_position = document.getElementById('canvas_container').getBoundingClientRect();
    let input_list: {text: string, x: number, y: number}[];
    input_list = [];
    
    this.fdForm.value['itemRows'].forEach((t, index) => {
      let child_position = document.getElementById('tb' + index).getBoundingClientRect();
    
      text = t.text;
      x_coord = (child_position.left - parent_position.left) + 18;
      y_coord = (child_position.top - parent_position.top) + 20;

      input_list.push({text: text, x: x_coord, y: y_coord});
    });
    
    let saved_drawing = this.canvas.saveDrawing(input_list);

    const formData = new FormData();
    formData.append('filepond', saved_drawing);

    this.aservice = this.aServ.UploadFile(formData).subscribe(file => {
      let uploaded_image: iImage = file['data'];
      this.resubmitResponse.emit(uploaded_image);
    })
  }

  ngOnDestroy() {
    if (this.sgservice) { this.sgservice.unsubscribe(); };
    if (this.ssservice) { this.ssservice.unsubscribe(); };
    if (this.aservice) { this.aservice.unsubscribe(); };
  }
}
