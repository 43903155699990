import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iStudentCreds } from '../models/studentsessions.interface';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'jagyw1xbTYRq15AJF2uP6k3z'
  })
};

@Injectable()
export class StudentSessionsService {
  constructor(private http: HttpClient) {}

  AuthorizeStudent(creds: iStudentCreds): Observable<iStudentCreds> {
    return this.http.post<iStudentCreds>(environment.conn_string + 'api/student_sessions', creds, httpOptions);
  }
}
