import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { layoutrouting } from './layout.routing';
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import { LayoutComponent } from './layout.component';


import { VerticalNavBarModule } from '../navigation/verticalnavbar/verticalnavbar.module';
import { BigUnitSelectModule } from "../navigation/bigunitselect/bigunitselect.module";
import { BigNotificationsModule } from "../navigation/bignotifications/bignotifications.module";
import { LittleNotificationsModule } from "../navigation/littlenotifications/littlenotifications.module";
import { LittleUnitSelectModule } from "../navigation/littleunitselect/littleunitselect.module";


import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImagePreview);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    layoutrouting,
    FormsModule,
    ToastrModule.forRoot(),
    PerfectScrollbarModule,
    FilePondModule,
    VerticalNavBarModule,
    BigUnitSelectModule,
    BigNotificationsModule,
    LittleNotificationsModule,
    LittleUnitSelectModule
  ],
  exports: [
    // LayoutComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class LayoutModule {}
