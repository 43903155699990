<div style="background-color: #edf2f9;">
  <div class="container" data-layout="container">
    <div class="row flex-center min-vh-100 py-6">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <a class="d-flex flex-center mb-4" href="https://sophiainstituteforteachers.org/" target="_blank">
          <img class="mr-2" src="../../assets/img/logos/SIFT logo-grayH.png" alt="" width="400" />
        </a>
        <div class="card" style="box-shadow: 0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07); border-radius: 6px !important;">
          <div class="card-body p-4 p-sm-5">
            <div class="row text-left justify-content-between align-items-center mb-2">
              <div class="col-auto">
                <h5>Log in</h5>
              </div>
            </div>
            <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()" >
              <div class="form-group"><input class="form-control" type="username" placeholder="Username" formControlName="email" style="margin-bottom: 20px;" /></div>
              <div class="form-group">
                <input class="form-control" type="password" placeholder="Password" style="margin-bottom: 20px;" class="form-control" formControlName="password" [ngClass]="{
                  'is-invalid': password.invalid && password.dirty,
                  'is-valid': password.valid && password.dirty
                }"/>
            </div>
              <div class="form-group"><button class="btn btn-primary btn-block mt-3" type="submit" name="submit" [disabled]="loginForm.invalid">Log in</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>