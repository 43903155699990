import { Component } from '@angular/core';

declare var $: any;

@Component({
    selector: 'bigsectionsubmitdisabledbutton',
    templateUrl: './bigsectionsubmitdisabledbutton.component.html'
})

export class BigSectionSubmitDisabledButton {

  constructor( ) {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 2000);
  }
}
