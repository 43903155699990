import { Component } from '@angular/core';

@Component({
    selector: 'bigbookmarkicon',
    templateUrl: './bigbookmarkicon.component.html'
})

export class BigBookmarkIconComponent {

  constructor( ) {  }
}
