import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iChooseActivity } from '../../models/chooseactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';

export class iLocalImage{
  id: number;
  file: string; 
  selected: boolean;
}

@Component({
    selector: 'littlechoose',
    templateUrl: './littlechoose.component.html',
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class LittleChooseComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iChooseActivity;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  images: iLocalImage[];
  response: {file: string, imageId: number}[];
  border_color: string;
  border_style: string;
  directions: string;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService ) {
      this.images = [];
      this.response = [];
     }

  ngOnInit() {
    this.border_color = this.authService.getSecondaryColor();
    this.border_style = "6px solid " + this.border_color;

    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];
      this.activity.data.images.forEach(i => {
        this.images.push({id: i.image.id, file: i.image.file, selected: false});
      })

      if (this.activity.response && this.activity.response.data.length > 0){
        this.activity.response.data.forEach(x => {
          this.images.find(i => i.id === x.imageId).selected = true;
        })
      }

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data.directions && this.activity.data.directions !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data.directions;
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  selectImage(image: iLocalImage){
    image.selected = !image.selected;
  }

  submitActivity(show_success: boolean){
    this.response = [];
    this.images.forEach(i => {
      if(i.selected){
        this.response.push({file: i.file, imageId: i.id});
      }
    })

    this.sharedService.submitActivityResponse(this.activity.id, this.response, show_success);
  }

  resubmit(){
    this.response = [];
    this.images.forEach(i => {
      if(i.selected){
        this.response.push({file: i.file, imageId: i.id});
      }
    })

    this.resubmitResponse.emit(this.response);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
