<main class="main" id="top">
  <div class="page-container">
    <nav class="navbar navbar-light navbar-vertical navbar-expand-xl">
      <!-- Logo -->
      <div class="d-flex align-items-center" style="padding-left: 20px;">
        <a class="navbar-brand">
          <img *ngIf="!little" class="mr-2" [src]="course_logo" width="110" alt="" style="margin-top: 24px; padding-bottom:2px; margin-left:40px;" />
          <img *ngIf="little" class="mr-2" [src]="course_logo" width="110" alt="" style="margin-top: 36px; padding-bottom:2px; margin-left:40px;" />
        </a>
      </div>
      <!-- Vertical Nav Bar -->
      <verticalnavbar></verticalnavbar>
    </nav>
    <!-- Header Bar -->
    <div class="content">
      <!-- Collapsed header bar on small screen -->
      <nav class="navbar navbar-light navbar-top navbar-expand" [ngClass]="{'big-header':little === false}">
        <button class="btn navbar-toggler-humburger-icon navbar-toggler mr-1 mr-sm-3" type="button" data-toggle="collapse" data-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation" style="margin-left:20px;"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
        <a class="navbar-brand mr-1 mr-sm-3">
          <img class="mr-2" [src]="course_logo" width="110" alt=""  />
        </a>
        <!-- Unit Select -->
        <bigunitselect *ngIf="!little"></bigunitselect>
        <littleunitselect *ngIf="little"></littleunitselect>
        <!-- Right-side elements -->
        <ul class="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center">
          <li class="nav-item">
            <p class="dropdown-item font-secondary right-side-text-elements" style="padding-top:18px;">
              <span *ngIf="little" style="font-size: 20px;">
                Hi, {{ user_name }}
              </span>
              <span *ngIf="!little" style="font-size: 18.6px">
                HELLO, {{ user_name | uppercase}}
              </span>
            </p>
          </li>
          <li *ngIf="!little" class="nav-item dropdown">
            <a [ngClass]="bell_classes" id="navbarDropdownNotification" href="#notifications-modal" role="button" data-toggle="modal" (click)="triggerNotifications()">
              <span color="#5b5b5b" class="fas fa-bell fs-4" data-fa-transform="shrink-6"></span>
            </a>
          </li>
          <li *ngIf="little" class="nav-item">
            <a [ngClass]="bell_classes" id="navbarDropdownNotification" href="#notifications-modal" role="button" data-toggle="modal" (click)="triggerNotifications()">
              <img width="43" src="../../assets/img/icons/button-bell.svg" />
            </a>
          </li>
          <li *ngIf="!little" class="nav-item">
            <a class="dropdown-item font-secondary right-side-text-elements" href="" (click)="logout()" style="font-size: 18.6px">LOGOUT</a>
          </li>
          <li *ngIf="little" class="nav-item">
            <a class="dropdown-item font-secondary" href="" (click)="logout()">
              <img width="140" src="../../assets/img/icons/logout-button.svg" />
            </a>
          </li>
        </ul>
      </nav>
      <!-- Unit Banner -->
      <div *ngIf="selected_nav_unit" class="banner-image-wrapper">
        <img [src]="selected_nav_unit.unit_banner_image.file" style="width:100%;">
        <div class="banner-text-overlay">
          <span *ngIf="!little" class="banner-unit-number">
            Unit {{ selected_nav_unit.number }}
          </span>
          <span *ngIf="little" class="banner-unit-number-little">
            Unit {{ selected_nav_unit.number }}
          </span>
          <div style="border-top: 2px dotted #F1BD46; width:150px; margin-top:0px; margin-bottom: -10px; padding-bottom:0px;"></div>
          <span class="banner-unit-name">
            {{ selected_nav_unit.name }}
          </span>
        </div>
        <div class="circle-mask"></div>
        <div class="banner-mask"></div>
      </div>
      <!-- Router Outlet -->
      <div class="container">
        <router-outlet></router-outlet>
      </div>
      <!-- Footer -->
      <footer [class]="move_footer">
        <div class="banner-image-wrapper">
          <div class="footer-div"></div>
          <img class="footer-logo-img" src="../../assets/img/logos/SIFT logo-whiteH.png" alt="" />
          <div [ngClass]="footer_class"></div>
        </div>
      </footer>
    </div>
    <!-- Notifications Modal -->
    <bignotifications *ngIf="!little"></bignotifications>
    <littlenotifications *ngIf="little"></littlenotifications>
  </div>
</main>