import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

import { SharedService } from '../../shared/services/shared.service';

@Component({
    selector: 'bigvertexpanded',
    templateUrl: './bigvertexpanded.component.html',
    providers: [ ]
})

export class BigVertExpandedComponent implements OnInit, OnDestroy {
  navbar_collapsed: boolean;
  current_page: string;
  vocab_class: string;
  unit_nav_class: string;
  bell_classes: string;
  curriculum_type: string;

  constructor( private sharedService: SharedService, private authService: AuthService, ) { 
    this.curriculum_type = this.authService.getCurriculumType();

    this.navbar_collapsed = true;
    this.sharedService.navbarCollapsedSubject.subscribe((value) => {
      this.navbar_collapsed = value;
    })
  }

  ngOnInit() {
    this.sharedService.getBellClasses().subscribe((value) => {
      this.bell_classes = value;
    })

    this.sharedService.getCurrentPage().subscribe((value) => {
      this.current_page = value;

      if (this.current_page == 'vocab'){
        this.vocab_class = "nav-link active";
        this.unit_nav_class = "nav-link";
      } else if ( this.current_page == 'nav'){
        this.vocab_class = "nav-link";
        this.unit_nav_class = "nav-link active";
      } else {
        this.vocab_class = "nav-link";
        this.unit_nav_class = "nav-link";
      }
    })
  }

  collapseVerticalNavbar(value: boolean){
    this.sharedService.collapseNavbar(value);
  }

  setSelected(type: string){
    if(this.current_page == type) {
      this.sharedService.collapseNavbar(true);

    } else {
      this.sharedService.setCurrentPage(type);
    }
  }

  triggerNotifications(){
    this.sharedService.getNotifications();
  }
  
  ngOnDestroy() { }
}
