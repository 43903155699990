import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iDragAndDropTimelineActivity } from '../../models/draganddroptimeline.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';
import { iImage } from '../../models/image.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export class iElement {
    id: number;
    image: iImage;
    order: number;
    text: string;
}

@Component({
    selector: 'littledraganddroptimeline',
    templateUrl: './littledraganddroptimeline.component.html',
    styleUrls: ['./littledraganddroptimeline.component.scss'],
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class LittleDragAndDropTimelineComponent implements OnInit, OnDestroy {
    @Input() activity_id: string;
    @Output() resubmitResponse = new EventEmitter();
    sgservice: any;
    activity: iDragAndDropTimelineActivity;
    selected_section: iStudentSection;
    ssservice: any;
    activity_response: iActivityResponse;
    response: number[];
    timeline_elements: iElement[];
    directions: string;

    constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService) {
        this.response = [];
        this.timeline_elements = [];
    }

    ngOnInit() {
        this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
            this.activity = activity['data'];
            
            if (this.activity.response && this.activity.response.data){
                let temp: iElement[] = [];
                this.activity.response.data.forEach(n => {
                    temp.push(this.activity.data.resources[n]);
                })

                this.timeline_elements = temp;
            } else {
                this.activity.data.resources.forEach((g) => {
                    this.timeline_elements.push(g);
                    this.timeline_elements.sort(() => Math.random() - 0.5);
                });
            }
            
            this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
                this.selected_section = section['data'];

                if (this.activity.data.directions && this.activity.data.directions !== ""){
                  this.directions = "<strong>Directions:</strong> " + this.activity.data.directions;
                } else if (this.selected_section.section_directions){
                  this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
                }
            })
        })
    }
    
    drop(event: CdkDragDrop<{event: iElement[]}>) {
        moveItemInArray(
            this.timeline_elements,
            event.previousIndex,
            event.currentIndex
        );
    }
        
    submitActivity(show_success: boolean){
        this.response = [];
        this.timeline_elements.forEach(e => {
            this.response.push(e.id);
        })
        
        this.sharedService.submitActivityResponse(this.activity.id, this.response, show_success);
    }

    resubmit(){
        this.response = [];
        this.timeline_elements.forEach(e => {
            this.response.push(e.id);
        })
        
        this.resubmitResponse.emit(this.response);
    }

    ngOnDestroy() {
        if (this.sgservice){ this.sgservice.unsubscribe(); };
        if (this.ssservice){ this.ssservice.unsubscribe(); };
    }
}