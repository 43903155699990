import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../auth/auth.service';
import { iStudentNavActivities } from '../../models/studentactivities.interface';
import { iStudentSection } from '../../models/studentsections.interface';
import { StudentSectionsService } from '../../services/StudentSections.service';

import { SharedService } from '../../shared/services/shared.service';

import { BigChartComponent } from "../../activities/bigchart/bigchart.component";
import { BigChooseComponent } from "../../activities/bigchoose/bigchoose.component";
import { BigCrosswordComponent } from "../../activities/bigcrossword/bigcrossword.component";
import { BigFibComponent } from "../../activities/bigfib/bigfib.component";
import { BigFingerDrawComponent } from "../../activities/bigfingerdraw/bigfingerdraw.component";
import { BigFlashcardsComponent } from "../../activities/bigflashcards/bigflashcards.component";
import { BigGroupWorkComponent } from "../../activities/biggroupwork/biggroupwork.component";
import { BigHotSpotComponent } from "../../activities/bighotspot/bighotspot.component";
import { BigiSpyComponent } from "../../activities/bigispy/bigispy.component";
import { BigMutlipleChoiceComponent } from "../../activities/bigmultiplechoice/bigmultiplechoice.component";
import { BigPhotoUploadComponent } from "../../activities/bigphotoupload/bigphotoupload.component";
import { BigPuzzleComponent } from "../../activities/bigpuzzle/bigpuzzle.component";
import { BigRespondComponent } from "../../activities/bigrespond/bigrespond.component";
import { BigSpotTheDifferenceComponent } from "../../activities/bigspotthedifference/bigspotthedifference.component";
import { BigTextComponent } from "../../activities/bigtext/bigtext.component";
import { BigDragAndDropTimelineComponent } from "../../activities/bigdraganddroptimeline/bigdraganddroptimeline.component";
import { BigDragAndDropFIBComponent } from "../../activities/bigdraganddropfib/bigdraganddropfib.component";
import { BigMatchingComponent } from "../../activities/bigmatching/bigmatching.component";
import { BigWordSearchComponent } from "../../activities/bigwordsearch/bigwordsearch.component";
import { ShootAndSortComponent } from "../../activities/shootandsort/shootandsort.component";
import { DrivingGameComponent } from "../../activities/drivinggame/drivinggame.component";

@Component({
    selector: 'activitycard',
    templateUrl: './activitycard.component.html',
    providers: [ 
      StudentSectionsService
    ]
})

export class ActivityCardComponent implements OnInit, OnDestroy {
  @ViewChild (BigChartComponent) private bigchartcomponent: BigChartComponent;
  @ViewChild (BigChooseComponent) private bigchoosecomponent: BigChooseComponent;
  @ViewChild (BigCrosswordComponent) private bigcrosswordcomponent: BigCrosswordComponent;
  @ViewChild (BigFibComponent) private bigfibcomponent: BigFibComponent;
  @ViewChild (BigFingerDrawComponent) private bigfingerdrawcomponent: BigFingerDrawComponent;
  @ViewChild (BigFlashcardsComponent) private bigflashcardscomponent: BigFlashcardsComponent;
  @ViewChild (BigGroupWorkComponent) private biggroupworkcomponent: BigGroupWorkComponent;
  @ViewChild (BigHotSpotComponent) private bighotspotcomponent: BigHotSpotComponent;
  @ViewChild (BigiSpyComponent) private bigispycomponent: BigiSpyComponent;
  @ViewChild (BigMutlipleChoiceComponent) private bigmultiplechoicecomponent: BigMutlipleChoiceComponent;
  @ViewChild (BigPhotoUploadComponent) private bigphotouploadcomponent: BigPhotoUploadComponent;
  @ViewChild (BigPuzzleComponent) private bigpuzzlecomponent: BigPuzzleComponent;
  @ViewChild (BigRespondComponent) private bigrespondcomponent: BigRespondComponent;
  @ViewChild (BigSpotTheDifferenceComponent) private bigspotthedifferencecomponent: BigSpotTheDifferenceComponent;
  @ViewChild (BigTextComponent) private bigtextcomponent: BigTextComponent;
  @ViewChild (BigDragAndDropTimelineComponent) private bigdraganddroptimelinecomponent: BigDragAndDropTimelineComponent;
  @ViewChild (BigDragAndDropFIBComponent) private bigdraganddropfibcomponent: BigDragAndDropFIBComponent;
  @ViewChild (BigMatchingComponent) private bigmatchingcomponent: BigMatchingComponent;
  @ViewChild (BigWordSearchComponent) private bigwordsearchcomponent: BigWordSearchComponent;
  @ViewChild (ShootAndSortComponent) private shootandsortcomponent: ShootAndSortComponent;
  @ViewChild (DrivingGameComponent) private drivinggamecomponent: DrivingGameComponent;

  @Input() activity: iStudentNavActivities;
  @Input() index: string;
  @Output() resubmitResponse = new EventEmitter();
  selected_section: iStudentSection;
  ssservice: any;
  disabled_modal: string = '';

  constructor(
    private sharedService: SharedService,
    private authService: AuthService,
    private ssServ: StudentSectionsService,
    private sanitizer: DomSanitizer
  ) {  }

  ngOnInit() {
    this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
      this.selected_section = section['data'];

      if (this.activity.locked && this.activity.completed && 
         (this.activity.activity_type == 'Crossword Puzzle' ||
          this.activity.activity_type == 'Spot the Difference' ||
          this.activity.activity_type == 'iSpy' ||
          this.activity.activity_type == 'Hot Spot' ||
          this.activity.activity_type == 'Group Activity')){
        this.disabled_modal = "disabled-modal";
      } else {
        this.disabled_modal = '';
      }
    })
  }

  togglecaret(i: string){
    let test = document.querySelector('#activityCaret' + i);
    var caret = test.querySelector('.card-header-caret-dropdown');
    caret.classList.toggle('active');
  }

  storeDataForResubmit(data: any) {
    this.resubmitResponse.emit(data);
  }

  getResubmitResponse(activity: iStudentNavActivities){
    switch(activity.activity_type) { 
      case 'Text': { 
         this.bigtextcomponent.resubmit(); 
         break; 
      } 
      case 'Respond': { 
        this.bigrespondcomponent.resubmit(); 
         break; 
      } 
      case 'Hot Spot': { 
        this.bighotspotcomponent.resubmit(); 
         break; 
      } 
      case 'Multiple Choice': { 
        this.bigmultiplechoicecomponent.resubmit(); 
         break; 
      } 
      case 'Photo Upload': { 
        this.bigphotouploadcomponent.resubmit(); 
         break; 
      } 
      case 'Choose': { 
        this.bigchoosecomponent.resubmit(); 
         break; 
      } 
      case 'iSpy': { 
        this.bigispycomponent.resubmit(); 
         break; 
      } 
      case 'Spot the Difference': { 
        this.bigspotthedifferencecomponent.resubmit(); 
         break; 
      } 
      case 'Fill in the blank': { 
        this.bigfibcomponent.resubmit(); 
         break; 
      } 
      case 'Group Activity': { 
        this.biggroupworkcomponent.resubmit(); 
         break; 
      } 
      case 'Crossword Puzzle': { 
        this.bigcrosswordcomponent.resubmit(); 
         break; 
      } 
      case 'Finger Draw': { 
        this.bigfingerdrawcomponent.resubmit(); 
         break; 
      } 
      case 'Chart': { 
        this.bigchartcomponent.resubmit(); 
         break; 
      } 
      case 'Puzzle': { 
        this.bigpuzzlecomponent.resubmit(); 
         break; 
      } 
      case 'Flash Cards': { 
        this.bigflashcardscomponent.resubmit(); 
         break; 
      } 
      case 'Drag and Drop - Timeline': { 
        this.bigdraganddroptimelinecomponent.resubmit(); 
         break; 
      } 
      case 'Drag and Drop FIB': { 
        this.bigdraganddropfibcomponent.resubmit(); 
         break; 
      } 
      case 'Matching': { 
        this.bigmatchingcomponent.resubmit(); 
         break; 
      } 
      case 'Word Search': { 
        this.bigwordsearchcomponent.resubmit(); 
         break; 
      } 
      case 'Shoot and Sort': { 
        this.shootandsortcomponent.resubmit(); 
         break; 
      }  
      case 'Driving Game': { 
        this.drivinggamecomponent.resubmit(); 
         break; 
      } 
      default: { 
         //statements; 
         break; 
      } 
   } 
  }
  
  ngOnDestroy() {
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
