import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { SmartyStreetsService } from "../../services/SmartyStreets.service";
import { iDiocese } from "../../models/diocese.interface";
import { DioceseService } from "../../services/Diocese.service";
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

import { iInstitute, iInstituteCurriculums } from "../../models/institute.interface";
import { InstituteService } from "../../services/Institute.service";
import { iPayment, iOrder } from "../../models/order.interface";
import { OrderService } from "../../services/Order.service";
import { iProduct } from "../../models/product.interface";
import { ProductService } from "../../services/Product.service";
import { iProductCategory } from "../../models/productcategories.interface";
import { ProductCategoryService } from "../../services/ProductCategories.service";
import { iStandardAcademicYear } from "../../models/academicyear.interface";
import { AcademicYearService } from '../../services/AcademicYear.service';
import { InstituteCurriculumsService } from '../../services/InstituteCurriculums.service';
import { iUserCreate, iUserExisting } from '../../models/user.interface';
import { UserService } from '../../services/User.service';
import { GradeService } from '../../services/Grade.service';
import { iGrade } from "../../models/grade.interface";
import { UpsService } from '../../services/UPS.service';
import { iShippingData } from "../../models/order.interface";


import * as Card from "card";
import { Select2OptionData } from 'ng-select2';

@Component({
    selector: 'productorderform',
    templateUrl: './productorderform.component.html',
    providers: [ 
      SmartyStreetsService, 
      DioceseService,
      InstituteService,
      OrderService,
      ProductService,
      ProductCategoryService,
      AcademicYearService,
      InstituteCurriculumsService,
      UserService,
      GradeService,
      UpsService
    ]
})

export class ProductOrderFormComponent implements OnInit, OnDestroy {
  @ViewChild('OrderTypeModalButton') OrderTypeModalButton:ElementRef; 
  orderForm: FormGroup;
  institute_type: FormControl;
  diocese: FormControl;
  institute_name:  FormControl;
  phone: FormControl;
  website: FormControl;
  i_address_1: FormControl;
  i_address_2: FormControl;
  i_city: FormControl;
  i_state: FormControl;
  i_zip: FormControl;
  contact_name: FormControl;
  billing_first_name: string;
  billing_last_name: string;
  b_address_1: string;
  b_address_2: string;
  b_city: string;
  b_state: string;
  b_zip: string;
  b_phone: string;
  b_email: string;
  s_address_1: string;
  s_address_2: string;
  s_city: string;
  s_state: string;
  s_zip: string;
  addressOptions = <any>[];
  billingAddressOptions = <any>[];
  selected_address: any;
  selected_billing_address: any;
  institute_types: {id: number, name: string}[];
  product_types: iProduct[];
  filtered_products: iProduct[];
  payment_types: {id: number, name: string}[];
  payment_type: number;
  purchase_types: {id: number, name: string}[];
  purchase_type: number;
  productForm: FormGroup;
  institute_id: string;
  order_id: string;
  dservice: any;
  dioceses: iDiocese[];
  total: string;
  iservice: any;
  oservice: any;
  pservice: any;
  pcservice: any;
  aservice: any;
  upsservice: any;
  academic_years: iStandardAcademicYear[];
  recaptcha_success: boolean = false;
  disabled: boolean = true;
  shipping_calculated: boolean = false;
  enable_submit: boolean = false;
  discount: number;
  current_form: string;
  first_name: FormControl;
  last_name: FormControl;
  admin_phone: FormControl;
  email: FormControl;
  password: FormControl;
  invoice: boolean = false;
  same_as_billing: boolean = false;
  number: string;
  expiry: string;
  cvc: string;
  institutes: iInstitute[];
  existing_institute_id: string;
  product_categories: iProductCategory[];
  see_school: boolean;
  see_parish: boolean;
  category_status: {
    id: number,
    name: string,
    active: boolean
  }[];
  newUser: iUserCreate = {} as {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phone_number: string;
    role_id: number;
    institute_id: number;
  };
  gservice: any;
  grades: iGrade[];
  existing_institute: iInstitute;
  total_shipping_cost: number;
  shipping_cost_label: string;
  shipping_data: iShippingData;

  public diocesesData: Array<Select2OptionData> = [];
  public instituteData: Array<Select2OptionData> = [];
  public productData: Array<Select2OptionData> = [];
  public filteredProductData: Array<Select2OptionData> = [];
  public filteredProductsData: Array<Select2OptionData>[] = [];

  constructor(
    private ssServ: SmartyStreetsService,
    private formBuilder: FormBuilder, 
    private router: Router,
    private dServ: DioceseService,
    private toastr: ToastrService,
    private oServ: OrderService,
    private iServ: InstituteService,
    private aServ: AcademicYearService,
    private icServ: InstituteCurriculumsService,
    private pServ: ProductService,
    private pcServ: ProductCategoryService,
    private gServ: GradeService,
    private uServ: UserService,
    private upsServ: UpsService,
    private titleServ: Title
   ) {
     this.institute_types = [
       {id: 0, name: 'School'},
       {id: 1, name: 'Parish'}
     ];

    this.payment_types = [
      {id: 0, name: 'Credit Card'},
      {id: 1, name: 'Invoice'}
    ];

    this.purchase_types = [
      {id: 0, name: 'New'},
      {id: 1, name: 'Renewal'},
      {id: 2, name: 'AddOn'}
    ];

    this.payment_type = 1;
    this.purchase_type = 0;
    this.total = "0";
    this.current_form = "institute";
    this.titleServ.setTitle("Product Order Form");
    this.see_parish = true;
    this.see_school = true;
    this.category_status = [];
    this.shipping_cost_label = "";
    this.total_shipping_cost = 0;
  }

  ngOnInit() {
    setTimeout(() => {
      this.OrderTypeModalButton.nativeElement.click();
    }, 500);

    this.createInstituteFormControls();
    this.createInstituteForm();

    this.productForm = this.formBuilder.group({
      itemRows: this.formBuilder.array([this.initItemRows()])
    });

    this.dservice = this.dServ.GetDioceses().subscribe(d => {
      this.dioceses = d['data']['dioceses'];
      
      let tempD: Select2OptionData[] = [];
      this.dioceses.forEach(d => {
        tempD.push({
          id: d.id.toString(),
          text: d.name
        })
      })

      this.diocesesData = tempD;

      this.getInstitutesByDiocese();
    })

    this.gservice = this.gServ.GetGrades().subscribe(g => {
      this.grades = g['data']['grades'];
    })

    this.pservice = this.pServ.GetProducts().subscribe(products => {
      this.product_types = products['data']['products'];
      this.product_types.sort(function(a, b){
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      });
      

      this.aservice = this.aServ.GetStandardAcademicYears().subscribe(years => {
        this.academic_years = years["data"]["standard_academic_years"];

        this.product_types.forEach(p => {
          p['year_name'] = this.academic_years.find(x => x.id.toString() == p.year_id).name; 
        });

        let tempP: Select2OptionData[] = [];
        this.product_types.forEach(p => {
          tempP.push({
            id: p.id.toString(),
            text: p.name + ' (' + p['year_name'] + ')'
          })
        })

        this.productData = tempP;
        this.filteredProductData = this.productData;
        this.filteredProductsData.push(this.filteredProductData);
      });
    })

    this.pcservice = this.pcServ.GetProductCategories().subscribe(p => {
      this.product_categories = p['data']['product_categories'];
      this.product_categories.forEach(p => {
        this.category_status.push({
          id: p.id,
          name: p.name,
          active: false
        })
      })

      let checked = {
        target: {
          checked: false
        }
      }
      this.filterCategories(checked, 1)
    })
  }

  renderCard(){
    new Card({ 
      form: "form#cc-form",
      container: ".card-wrapper",
      formSelectors: { 
        numberInput: "input#number",
        expiryInput: "input#expiry",
        cvcInput: "input#cvc",
        nameInput: 'input[name="billing_first_name"], input[name="billing_last_name"]'
      },
      width: 270,
      formatting: true,
      placeholders: {
        number: "•••• •••• •••• ••••",
        name: "Full Name",
        expiry: "••/••",
        cvc: "•••"
      }
    });
  }

  createInstituteFormControls() {
    this.institute_type = new FormControl(0, Validators.required);
    this.diocese = new FormControl(186, Validators.required);
    this.institute_name = new FormControl('', Validators.required);
    this.phone = new FormControl('', Validators.required);
    this.website = new FormControl('', Validators.required);
    this.i_address_1 = new FormControl('', Validators.required);
    this.i_address_2 = new FormControl('');
    this.i_city = new FormControl('', Validators.required);
    this.i_state = new FormControl('', Validators.required);
    this.i_zip = new FormControl('', Validators.required);
    this.contact_name = new FormControl('', Validators.required);
    this.first_name = new FormControl('', Validators.required);
    this.last_name = new FormControl('', Validators.required);
    this.admin_phone = new FormControl('', Validators.required);
    this.email = new FormControl('', Validators.required);
    this.password = new FormControl('', Validators.required);
  }

  createInstituteForm() {
    this.orderForm = new FormGroup({
      institute_type: this.institute_type,
      diocese: this.diocese,
      institute_name: this.institute_name,
      phone: this.phone,
      website: this.website,
      i_address_1: this.i_address_1,
      i_address_2: this.i_address_2,
      i_city: this.i_city,
      i_state: this.i_state,
      i_zip: this.i_zip,
      contact_name: this.contact_name,
      first_name: this.first_name,
      last_name: this.last_name,
      admin_phone: this.admin_phone,
      email: this.email,
      password: this.password
    });
  }

  initItemRows() {
    return this.formBuilder.group({
      grade_filter: [this.grades],
      product: [this.filteredProductData[0]?.id],
      price: [],
      quantity: [],
      subtotal: {value:[], disabled: true},
      curriculum_id: [],
      year_id: [],
      discount_eligible: [],
      weight_in_ounces: []
    });
  }

  addNewRow() {
    const control = <FormArray>this.productForm.controls['itemRows'];
    control.push(this.initItemRows());
    this.filteredProductsData.push(this.filteredProductData);
  }

  deleteRow(index: number) {
    const control = <FormArray>this.productForm.controls['itemRows'];
    control.removeAt(index);
    this.calculateTotal();
    this.filteredProductsData.splice(index, 1);
  }

  getControls() {
    return (<FormArray>this.productForm.get('itemRows')).controls;
  }

  onChange(term: any) {
    this.ssServ.AutocompleteAddress(term).then(data => {
      this.addressOptions = data.result as any[];
    }).catch();
  }

  onBillingChange(term: any) {
    this.ssServ.AutocompleteAddress(term).then(data => {
      this.billingAddressOptions = data.result as any[];
    }).catch();
  }

  checkValue(event: any) {
    this.invoice = event.target.checked;
    this.validateForm(); 
  }

  sameAsBilling(event: any) {
    this.same_as_billing = event.target.checked;
    if(this.same_as_billing){
      this.s_address_1 = this.b_address_1;
      this.s_address_2 = this.b_address_2;
      this.s_city = this.b_city;
      this.s_state = this.b_state;
      this.s_zip = this.b_zip;
    } else {
      this.s_address_1 = '';
      this.s_address_2 = '';
      this.s_city = '';
      this.s_state = '';
      this.s_zip = '';
    }

    this.validateForm(); 
  }

  setAddress(value){
    this.ssServ.StreetAddressLookup(value.streetLine, value.city, value.state).subscribe( x => {
      this.selected_address = x[0];
      
      this.i_address_1.setValue(this.selected_address.delivery_line_1);
      this.i_address_2.setValue(this.selected_address.delivery_line_2);
      this.i_city.setValue(this.selected_address.components.city_name);
      this.i_state.setValue(this.selected_address.components.state_abbreviation);
      this.i_zip.setValue(this.selected_address.components.zipcode);
      setTimeout(() => {
        this.addressOptions = [];
      }, 500);
    })
  }

  setBillingAddress(value){
    this.ssServ.StreetAddressLookup(value.streetLine, value.city, value.state).subscribe( x => {
      this.selected_billing_address = x[0];
      
      this.b_address_1 = this.selected_billing_address.delivery_line_1;
      this.b_address_2 = this.selected_billing_address.delivery_line_2;
      this.b_city = this.selected_billing_address.components.city_name;
      this.b_state = this.selected_billing_address.components.state_abbreviation;
      this.b_zip = this.selected_billing_address.components.zipcode;
      setTimeout(() => {
        this.billingAddressOptions = [];
      }, 500);
    })
  }

  calculateSubtotal(row, index){
    if (row !== null){
      let prod = row['product'] == null ? row : row['product'];
      
      this.productForm.controls["itemRows"].value[index]["price"] = this.product_types.find(x => x.id.toString() == prod).price;
      let subtotal = (this.product_types.find(x => x.id == prod).price * this.productForm.controls["itemRows"].value[index]["quantity"]).toFixed(2);
      this.productForm.controls["itemRows"].value[index]["subtotal"] = subtotal;
      this.productForm.controls["itemRows"].value[index]["curriculum_id"] = this.product_types.find(x => x.id == prod).curriculum_id;
      this.productForm.controls["itemRows"].value[index]["year_id"] = this.product_types.find(x => x.id == prod).year_id;
      this.productForm.controls["itemRows"].value[index]["discount_eligible"] = this.product_types.find(x => x.id == prod).discount_eligible;
      this.productForm.controls["itemRows"].value[index]["weight_in_ounces"] = this.product_types.find(x => x.id == prod).weight;

      this.productForm.patchValue({
        subtotal: subtotal
      })

      this.calculateTotal();
    }
  }

  calculateTotal(){
    let sum = 0
    let discount_qty = 0;
    this.productForm.controls["itemRows"].value.forEach(row => {
      sum += row.subtotal == null ? 0 : Number(row.subtotal);
      discount_qty += row.discount_eligible == true ? Number(row.quantity) : 0;
    });

    if (discount_qty > 9){
      sum = sum - (10 * discount_qty);
      this.discount = Number((10 * discount_qty).toFixed(2));
    } else {
      this.discount = null;
    }
    
    let total_total: number = sum + this.total_shipping_cost;
    this.total = total_total.toFixed(2);

    setTimeout(() => {
      this.enable_submit = this.shipping_calculated;
    }, 5000);
  }

  calculateShippingCost(){
    //get the weight of each item selected. multiply times the quantitiy. add all weight up and send through api
    let weight: number = 0;
    this.productForm.controls["itemRows"].value.forEach(row => {
      weight += row.quantity * row.weight_in_ounces;
    });

    setTimeout(() => {
      if (weight > 0){
        this.shipping_data = {
          ounces: weight,
          address_line_1: this.s_address_1,
          city: this.s_city,
          state: this.s_state,
          postal_code: this.s_zip
        }
  
        this.upsservice = this.upsServ.GetShippingCosts(this.shipping_data).subscribe(cost => {
          this.total_shipping_cost = (Number(cost['data']));
          this.shipping_cost_label = "$" + this.total_shipping_cost;
          this.shipping_calculated = true;
          this.calculateTotal();
        })
      } else {
        this.shipping_cost_label = "FREE";
        this.shipping_calculated = true;
        this.calculateTotal();
      }
    }, 500);
  }


  showCard(){
    if(this.payment_type == 0){
      setTimeout(() => {
        this.renderCard();
      }, 500);
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.recaptcha_success = true;
    this.validateForm();
  }

  validateForm(){
    let prevent_submit: boolean = false;

    if (this.productForm.value.itemRows[0]['product'] == null || this.productForm.value.itemRows[0]['quantity'] == null){
      prevent_submit = true;
    }

    if (this.billing_first_name == undefined || this.billing_first_name == ''){
      prevent_submit = true;
    }
    if (this.billing_last_name == undefined || this.billing_last_name == ''){
      prevent_submit = true;
    }
    if (this.b_phone == undefined || this.b_phone == ''){
      prevent_submit = true;
    }
    if (this.b_email == undefined || this.b_email == ''){
      prevent_submit = true;
    }
    if (this.b_address_1 == undefined || this.b_address_1 == ''){
      prevent_submit = true;
    }
    if (this.b_city == undefined || this.b_city == ''){
      prevent_submit = true;
    }
    if (this.b_state == undefined || this.b_state == ''){
      prevent_submit = true;
    }
    if (this.b_zip == undefined || this.b_zip == ''){
      prevent_submit = true;
    }
    if (this.s_address_1 == undefined || this.s_address_1 == ''){
      prevent_submit = true;
    }
    if (this.s_city == undefined || this.s_city == ''){
      prevent_submit = true;
    }
    if (this.s_state == undefined || this.s_state == ''){
      prevent_submit = true;
    }
    if (this.s_zip == undefined || this.s_zip == ''){
      prevent_submit = true;
    }

    if (this.payment_type == 0){
      if (this.number == undefined || this.number == ''){
        prevent_submit = true;
      }
      if (this.expiry == undefined || this.expiry == ''){
        prevent_submit = true;
      }
      if (this.cvc == undefined || this.cvc == ''){
        prevent_submit = true;
      }    
    } else {
      if (!this.invoice) {
        prevent_submit = true;
      }
    }
    
    if (!this.recaptcha_success){
      prevent_submit = true;
    }

    this.disabled = prevent_submit;
  }

  proceedToInstitute() {
    this.current_form = "institute";
  }
  
  proceedToProduct() {
    if (this.orderForm.valid){
      this.current_form = "product";
    }
    //TODO: Remove the line below. It is for testing purposes only
    // this.current_form = "product";
  }

  updatePaymentType(value){
    this.payment_type = value;
    this.validateForm();
  }

  updatePurchaseType(value){
    this.purchase_type = value;
    if (this.purchase_type !== 0){
      this.getInstitutesByDiocese();
    }
  }

  getInstitutesByDiocese(){
    if (this.purchase_type !== 0){
      setTimeout(() => {
        this.iservice = this.iServ.GetInstitutionsByDiocese(this.diocese.value).subscribe(i => {
          this.institutes = i['data']['institutes'];
          
          if (this.institutes.length > 0){
            this.existing_institute_id = this.institutes[0].id.toString();
            let i = this.institutes[0];
  
            this.institute_name.setValue(i.name);
            this.phone.setValue(i.phone_number);
            this.website.setValue(i.website);
            this.i_address_1.setValue(i.address1);
            this.i_address_2.setValue(i.address2);
            this.i_city.setValue(i.city);
            this.i_state.setValue(i.state);
            this.i_zip.setValue(i.zip);
            this.contact_name.setValue(i.contact_name !== null ? i.contact_name : '');
          } else {
            this.existing_institute_id = null;
          }
    
          let tempI: Select2OptionData[] = [];
          this.institutes.forEach(i => {
            tempI.push({
              id: i.id.toString(),
              text: i.name
            })
          })
    
          this.instituteData = tempI;
        })
      }, 500);
    }
  }

  getInstituteData(){
    setTimeout(() => {
      let i = this.institutes.find(i => i.id.toString() == this.existing_institute_id);

      this.institute_name.setValue(i.name);
      this.phone.setValue(i.phone_number);
      this.website.setValue(i.website);
      this.i_address_1.setValue(i.address1);
      this.i_address_2.setValue(i.address2);
      this.i_city.setValue(i.city);
      this.i_state.setValue(i.state);
      this.i_zip.setValue(i.zip);
      this.contact_name.setValue(i.contact_name !== null ? i.contact_name : '');
    }, 500);
  }

  filterCategories(event, id){
    this.category_status.find(c => c.id == id).active = event.target.checked;

    this.filtered_products = [];
    this.category_status.forEach(c => {
      if (c.active){
        let temp: iProduct[] = this.product_types.filter(p => p.product_category_id == c.id);
        this.filtered_products = this.filtered_products.concat(temp);
      }
    });

    this.filteredProductData = [];
    this.filtered_products.forEach(p => {
      let tempProd = this.productData.find(x => x.id == p.id.toString());
      this.filteredProductData.push(tempProd);
    });
    
    if (this.filteredProductData.length > 0){
      this.filteredProductsData[0] = this.filteredProductData;
    }

    const controls = <FormArray>this.productForm.controls['itemRows'];
    
    if (controls.length > 1){
      for (let i = controls.length; i > 0; i--){
        controls.removeAt(i);
      }
    }
    controls.reset();
    this.filteredProductsData = [];
    this.filteredProductsData.push(this.filteredProductData);
    this.validateForm();
  }

  filter_by_grade(event, i){
    let grade_id = event.target.value;
    
    let temp: iProduct[] = this.filtered_products.filter(p => p.grade_id == grade_id);
    let fPD = [];
    temp.forEach(p => {
      let tempProd = this.productData.find(x => x.id == p.id.toString());
      fPD.push(tempProd);
    });
    
    this.filteredProductsData[i] = fPD;
  }

  onSubmit() {
    this.purchase_type == 0 ? this.submitNewOrder() : this.submitRenewal();
  }

  submitNewOrder(){
    //1 Create institute set institute id
    let sum:number = 0;
    let year_id: number;
    this.productForm.controls["itemRows"].value.forEach(row => {
      year_id = row.year_id;
      sum += row.quantity == null ? 0 : parseInt(row.quantity);
    });

    let start_date = this.academic_years.find( year => year.id == year_id).start_date;
    let end_date = this.academic_years.find( year => year.id == year_id).enddate;
    
    let licenses = sum;
    let i = this.orderForm.value;
    let type = this.institute_types.filter(x => x.id == i.institute_type)[0].name;
    let institute: iInstitute = {
      id: null,
      name: i.institute_name,
      address1: i.i_address_1,
      address2: i.i_address_2,
      city: i.i_city,
      state: i.i_state,
      zip: i.i_zip,
      phone_number: i.phone,
      website: i.website,
      institute_type: type,
      diocese_id: i.diocese,
      password_expiration: null,
      licenses: licenses,
      subscription_start_date: start_date,
      subscription_end_date: end_date,
      code: '',
      contact_name: i.contact_name
    };
    
    this.iservice = this.iServ.CreateInstitution(institute).subscribe( institute => {
      this.institute_id = institute['data'].id;

      //2 Create Insitute Admin User
      this.newUser = {
        first_name: i.first_name,
        last_name: i.last_name,
        email: i.email,
        password: i.password,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone_number: i.admin_phone,
        role_id: 2,
        institute_id: Number(this.institute_id),
      }
      this.uServ.CreateUser(this.newUser).subscribe(success => {
        //3 Assign curriculums to institute
        let curriculumIds:number[] = [];
        this.productForm.controls["itemRows"].value.forEach(row => {
          curriculumIds.push(row.curriculum_id);
        })
      

        curriculumIds.forEach((c: number) => {
          let inst_curr: iInstituteCurriculums = {institute_id: parseInt(this.institute_id), curriculum_id: c};
          
          this.icServ.CreateInstituteCurriculum(inst_curr).subscribe(x => { 
            console.log(x);
          })
        })

        //4 Create order (orders/orders)
        let products = [];
        this.productForm.controls["itemRows"].value.forEach(row => {
          products.push({id: parseInt(row.product), qty: parseInt(row.quantity)});
        });

        let product_json = {"products": products};
        let order: iOrder = {
          institute_id: this.institute_id,
          submitted_by: '-999',
          products: product_json,
          billing_first_name: this.billing_first_name,
          billing_last_name: this.billing_last_name,
          billing_address_1: this.b_address_1,
          billing_address_2: this.b_address_2,
          billing_city: this.b_city,
          billing_state: this.b_state,
          billing_zip: this.b_zip, 
          billing_phone: this.b_phone, 
          billing_email: this.b_email,
          shipping_address_1: this.s_address_1,
          shipping_address_2: this.s_address_2,
          shipping_city: this.s_city,
          shipping_state: this.s_state,
          shipping_zip: this.s_zip,
          invoice: this.payment_type == 1 ? true : false, 
          shipping_cost: this.total_shipping_cost.toString()
        };

        this.oservice = this.oServ.CreateOrder(order).subscribe( success => {
          this.order_id = success['id'];

          //5 Create payment (orders/payment)
          if (this.payment_type == 0){
            let a = this.selected_billing_address;
            let payment: iPayment = {
              id: null,
              billing_first_name: this.billing_first_name,
              billing_last_name: this.billing_last_name,
              billing_address_1: this.b_address_1,
              billing_address_2: this.b_address_2,
              billing_city: this.b_city,
              billing_state: this.b_state,
              billing_zipcode: this.b_zip,
              card_numner: parseInt(this.number),
              expire_month: parseInt(this.expiry.split('/')[0]),
              expire_year: parseInt(this.expiry.split('/')[1]),
              cvv: parseInt(this.cvc),
              amount: Number(this.total),
              order_id: parseInt(this.order_id)
            };
            this.oservice = this.oServ.CreatePayment(payment).subscribe( success => {
              this.toastr.success('Success!')
              window.location.href = "https://sophiainstituteforteachers.org/thankyou";
            }, error => {
              this.toastr.error('Error processing payment! ' + error.error.meta.errors);
              console.log(error);
            });
          } else {
            this.toastr.success('Success!')
            window.location.href = "https://sophiainstituteforteachers.org/thankyou";
          }
        }, error => {
          this.toastr.error('Error processing order!')
        });
      }, error => {
        this.toastr.error('Error creating digital admin!')
      });
    }, error => {
      this.toastr.error('Error creating institute!')
    });
  }

  submitRenewal(){
    let existing_licenses = 0;
    if (this.purchase_type == 2){
      this.iservice = this.iServ.GetInstitutionDetails(this.existing_institute_id).subscribe(i => {
        this.existing_institute = i['data'];
        existing_licenses = this.existing_institute.licenses;
      })
    }

    //1 Create institute set institute id
    let sum:number = 0;
    let year_id: number;
    this.productForm.controls["itemRows"].value.forEach(row => {
      year_id = row.year_id;
      sum += row.quantity == null ? 0 : parseInt(row.quantity);
    });

    setTimeout(() => {
      let start_date = this.academic_years.find( year => year.id == year_id).start_date;
      let end_date = this.academic_years.find( year => year.id == year_id).enddate;
      let licenses = sum + existing_licenses;
      let i = this.orderForm.value;
      let type = this.institute_types.filter(x => x.id == i.institute_type)[0].name;
      let institute = {
        id: this.existing_institute_id,
        licenses: licenses,
        subscription_start_date: start_date,
        subscription_end_date: end_date,
        contact_name: i.contact_name
      };
    
      this.iservice = this.iServ.UpdateInstitution(institute).subscribe( institute => {
        //2 Create or Update Insitute Admin User
        this.newUser = {
          first_name: i.first_name,
          last_name: i.last_name,
          email: i.email,
          password: i.password,
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
          phone_number: i.admin_phone,
          role_id: 2,
          institute_id: Number(this.existing_institute_id),
        }

        this.uServ.GetUserByEmail(i.email, this.existing_institute_id).subscribe(success => {
          if (success['data'] == "User doesn't exist") {
            this.uServ.CreateUser(this.newUser).subscribe(success => {
              //3 Assign curriculums to institute
              let curriculumIds:number[] = [];
              this.productForm.controls["itemRows"].value.forEach(row => {
                curriculumIds.push(row.curriculum_id);
              })
              
              var formdata = new FormData();
              curriculumIds.forEach((c: number) => {
                formdata.append("curriculum[][id]", c.toString());
              })
      
              if (this.purchase_type == 2){
                this.icServ.AppendInsituteCurriculum(this.existing_institute_id, formdata).subscribe(x => { 
                  console.log(x);
                })
              } else {
                this.icServ.UpdateInstituteCurriculum(this.existing_institute_id, formdata).subscribe(x => { 
                  console.log(x);
                })
              }
      
              //4 Create order (orders/orders)
              let products = [];
              this.productForm.controls["itemRows"].value.forEach(row => {
                products.push({id: parseInt(row.product), qty: parseInt(row.quantity)});
              });
      
              let product_json = {"products": products};
              let order: iOrder = {
                institute_id: this.existing_institute_id,
                submitted_by: '-999',
                products: product_json,
                billing_first_name: this.billing_first_name,
                billing_last_name: this.billing_last_name,
                billing_address_1: this.b_address_1,
                billing_address_2: this.b_address_2,
                billing_city: this.b_city,
                billing_state: this.b_state,
                billing_zip: this.b_zip, 
                billing_phone: this.b_phone, 
                billing_email: this.b_email,
                shipping_address_1: this.s_address_1,
                shipping_address_2: this.s_address_2,
                shipping_city: this.s_city,
                shipping_state: this.s_state,
                shipping_zip: this.s_zip,
                invoice: this.payment_type == 1 ? true : false, 
                shipping_cost: this.total_shipping_cost.toString() 
              };
      
              this.oservice = this.oServ.CreateOrder(order).subscribe( success => {
                this.order_id = success['id'];
      
                //5 Create payment (orders/payment)
                if (this.payment_type == 0){
                  let a = this.selected_billing_address;
                  let payment: iPayment = {
                    id: null,
                    billing_first_name: this.billing_first_name,
                    billing_last_name: this.billing_last_name,
                    billing_address_1: this.b_address_1,
                    billing_address_2: this.b_address_2,
                    billing_city: this.b_city,
                    billing_state: this.b_state,
                    billing_zipcode: this.b_zip,
                    card_numner: parseInt(this.number),
                    expire_month: parseInt(this.expiry.split('/')[0]),
                    expire_year: parseInt(this.expiry.split('/')[1]),
                    cvv: parseInt(this.cvc),
                    amount: Number(this.total),
                    order_id: parseInt(this.order_id)
                  };
                  this.oservice = this.oServ.CreatePayment(payment).subscribe( success => {
                    this.toastr.success('Success!')
                    window.location.href = "https://sophiainstituteforteachers.org/thankyou";
                  }, error => {
                    this.toastr.error('Error processing payment!')
                  });
                } else {
                  this.toastr.success('Success!')
                  window.location.href = "https://sophiainstituteforteachers.org/thankyou";
                }
              }, error => {
                this.toastr.error('Error processing order!')
              });
            }, error => {
              this.toastr.error('Error creating digital admin!')
            });
          } else {
            let existingUser: iUserExisting = {
              id: success['data']['id'],
              first_name: i.first_name,
              last_name: i.last_name,
              email: i.email,
              password: i.password,
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip: '',
              phone_number: i.admin_phone,
              role_id: 2,
              institute_id: Number(this.existing_institute_id),
            }

            this.uServ.UpdateUser(existingUser).subscribe(success => {
              //3 Assign curriculums to institute
              let curriculumIds:number[] = [];
              this.productForm.controls["itemRows"].value.forEach(row => {
                curriculumIds.push(row.curriculum_id);
              })
              
              var formdata = new FormData();
              curriculumIds.forEach((c: number) => {
                formdata.append("curriculum[][id]", c.toString());
              })
      
              if (this.purchase_type == 2){
                this.icServ.AppendInsituteCurriculum(this.existing_institute_id, formdata).subscribe(x => { 
                  console.log(x);
                })
              } else {
                this.icServ.UpdateInstituteCurriculum(this.existing_institute_id, formdata).subscribe(x => { 
                  console.log(x);
                })
              }
      
              //4 Create order (orders/orders)
              let products = [];
              this.productForm.controls["itemRows"].value.forEach(row => {
                products.push({id: parseInt(row.product), qty: parseInt(row.quantity)});
              });
      
              let product_json = {"products": products};
              let order: iOrder = {
                institute_id: this.existing_institute_id,
                submitted_by: '-999',
                products: product_json,
                billing_first_name: this.billing_first_name,
                billing_last_name: this.billing_last_name,
                billing_address_1: this.b_address_1,
                billing_address_2: this.b_address_2,
                billing_city: this.b_city,
                billing_state: this.b_state,
                billing_zip: this.b_zip,
                billing_phone: this.b_phone,
                billing_email: this.b_email,
                shipping_address_1: this.s_address_1,
                shipping_address_2: this.s_address_2,
                shipping_city: this.s_city,
                shipping_state: this.s_state,
                shipping_zip: this.s_zip,
                invoice: this.payment_type == 1 ? true : false, 
                shipping_cost: this.total_shipping_cost.toString() 
              };
      
              this.oservice = this.oServ.CreateOrder(order).subscribe( success => {
                this.order_id = success['id'];
      
                //5 Create payment (orders/payment)
                if (this.payment_type == 0){
                  let a = this.selected_billing_address;
                  let payment: iPayment = {
                    id: null,
                    billing_first_name: this.billing_first_name,
                    billing_last_name: this.billing_last_name,
                    billing_address_1: this.b_address_1,
                    billing_address_2: this.b_address_2,
                    billing_city: this.b_city,
                    billing_state: this.b_state,
                    billing_zipcode: this.b_zip,
                    card_numner: parseInt(this.number),
                    expire_month: parseInt(this.expiry.split('/')[0]),
                    expire_year: parseInt(this.expiry.split('/')[1]),
                    cvv: parseInt(this.cvc),
                    amount: Number(this.total),
                    order_id: parseInt(this.order_id)
                  };
                  this.oservice = this.oServ.CreatePayment(payment).subscribe( success => {
                    this.toastr.success('Success!')
                    window.location.href = "https://sophiainstituteforteachers.org/thankyou";
                  }, error => {
                    this.toastr.error('Error processing payment! ' + error.error.meta.errors);
                    console.log(error);
                  });
                } else {
                  this.toastr.success('Success!')
                  window.location.href = "https://sophiainstituteforteachers.org/thankyou";
                }
              }, error => {
                this.toastr.error('Error processing order!')
              });
            })
          }
        })
      }, error => {
        this.toastr.error('Error creating institute!')
      });
    }, 500);   
  }
  
  ngOnDestroy(): void {
    if (this.dservice){this.dservice.unsubscribe()}
    if (this.iservice){this.iservice.unsubscribe()}
    if (this.oservice){this.oservice.unsubscribe()}
    if (this.aservice){this.aservice.unsubscribe()}
    if (this.pcservice){this.pcservice.unsubscribe()}
    if(this.gservice){this.gservice.unsubscribe()};
    if(this.upsservice){this.upsservice.unsubscribe()};
  }
}
