<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="20.091" viewBox="0 0 26 20.091" style="cursor: pointer;">
  <defs>
    <style>
      .cls-1 {
        fill: none;
      }

      .cls-2 {
        clip-path: url(#clip-path);
      }

      .cls-3, .cls-4 {
        fill: #fff;
      }

      .cls-4 {
        opacity: 0.25;
      }
    </style>
    <clipPath id="clip-path">
      <rect class="cls-1" width="26" height="20.091"/>
    </clipPath>
  </defs>
  <g id="Icon_Hotspots_On" data-name="Icon / Hotspots / On" class="cls-2">
    <path id="Path_119" data-name="Path 119" class="cls-3" d="M18.472,23.591H7.1a1.807,1.807,0,0,1-1.809-1.8A2.692,2.692,0,0,1,7.6,19.143l4.87-.761-8.211-8.17a2.286,2.286,0,0,1,0-3.241,2.317,2.317,0,0,1,3.265-.007L9.661,9.09a2.269,2.269,0,0,1,.579-.968,2.32,2.32,0,0,1,3.033-.209,2.283,2.283,0,0,1,.585-.991,2.318,2.318,0,0,1,3.034-.208,2.244,2.244,0,0,1,.584-.992,2.317,2.317,0,0,1,3.265-.007l4.535,4.958a6.568,6.568,0,0,1-.216,9.1l-1.9,1.888A6.606,6.606,0,0,1,18.472,23.591ZM5.894,7.492a1.1,1.1,0,0,0-.783,1.872l9.049,9a.6.6,0,0,1-.333,1.016l-6.048.946A1.487,1.487,0,0,0,6.5,21.791a.6.6,0,0,0,.6.6H18.472a5.405,5.405,0,0,0,3.838-1.582l1.9-1.888a5.374,5.374,0,0,0,.177-7.441L19.869,6.543a1.1,1.1,0,0,0-1.533.02,1.088,1.088,0,0,0-.006,1.552l.052.052a.6.6,0,0,1,0,.848.6.6,0,0,1-.841.012h0a0,0,0,0,0,0,0h0s0,0,0,0L16.27,7.763a1.1,1.1,0,0,0-1.552,0,1.091,1.091,0,0,0-.007,1.552l1.258,1.252a.6.6,0,0,1,0,.85.606.606,0,0,1-.853,0L12.652,8.963a1.1,1.1,0,0,0-1.552,0,1.091,1.091,0,0,0-.007,1.552l2.464,2.452a.6.6,0,0,1,0,.85.606.606,0,0,1-.853,0l-6.034-6A1.1,1.1,0,0,0,5.894,7.492Z" transform="translate(-1 -3.5)"/>
    <path id="Path_120" data-name="Path 120" class="cls-3" d="M3.859,12.141a.5.5,0,0,1-.206-.044A4.5,4.5,0,1,1,9.6,6.153a.5.5,0,1,1-.911.412,3.5,3.5,0,1,0-4.62,4.62.5.5,0,0,1-.206.956Z" transform="translate(-1 -3.5)"/>
    <path id="Path_121" data-name="Path 121" class="cls-4" d="M5.894,7.492a1.1,1.1,0,0,0-.783,1.872l9.049,9a.6.6,0,0,1-.333,1.016l-6.048.946A1.487,1.487,0,0,0,6.5,21.791a.6.6,0,0,0,.6.6H18.472a5.405,5.405,0,0,0,3.838-1.582l1.9-1.888a5.374,5.374,0,0,0,.177-7.441L19.869,6.543a1.1,1.1,0,0,0-1.533.02,1.088,1.088,0,0,0-.006,1.552l.052.052a.6.6,0,0,1,0,.848.6.6,0,0,1-.841.012h0a0,0,0,0,0,0,0h0s0,0,0,0L16.27,7.763a1.1,1.1,0,0,0-1.552,0,1.091,1.091,0,0,0-.007,1.552l1.258,1.252a.6.6,0,0,1,0,.85.606.606,0,0,1-.853,0L12.652,8.963a1.1,1.1,0,0,0-1.552,0,1.091,1.091,0,0,0-.007,1.552l2.464,2.452a.6.6,0,0,1,0,.85.606.606,0,0,1-.853,0l-6.034-6A1.1,1.1,0,0,0,5.894,7.492Z" transform="translate(-1 -3.5)"/>
  </g>
</svg>
