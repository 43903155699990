import { Component } from '@angular/core';

@Component({
    selector: 'biglearnedcardspile',
    templateUrl: './biglearnedcardspile.component.html'
})

export class BigLearnedCardsPile {

  constructor( ) {  }
}
