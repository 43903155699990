import { Component, OnInit, OnDestroy } from '@angular/core';

import { SharedService } from '../../shared/services/shared.service';
import { AuthService } from '../../auth/auth.service';

import { iStudentNavUnits } from '../../models/studentunits.interface';
import { StudentUnitsService } from '../../services/StudentUnits.service';

@Component({
    selector: 'bigunitselect',
    templateUrl: './bigunitselect.component.html',
    providers: [ StudentUnitsService ]
})

export class BigUnitSelectComponent implements OnInit, OnDestroy {
  navbar_collapsed: boolean;
  nav_icon_selected: string;
  selected_unit_id: string;
  user_id: string;
  suservice: any;
  nav_units: iStudentNavUnits[];
  selected_nav_unit: iStudentNavUnits;

  constructor(
    private sharedService: SharedService,
    private suServ: StudentUnitsService,
    private authService: AuthService ) { 
  }

  ngOnInit() {
    this.user_id = this.authService.getId();
    this.suservice = this.suServ.GetStudentNavUnits(this.user_id).subscribe((nav_units: iStudentNavUnits[]) => {
      this.nav_units = nav_units['data'];
      this.selected_nav_unit = localStorage.getItem("selectedUnit") !== null ? JSON.parse(localStorage.getItem("selectedUnit")) : this.nav_units[0];
      this.sharedService.setSelectedUnit(this.selected_nav_unit);
    })

    this.sharedService.getSelectedUnit().subscribe((value) => {
      if (value){
        this.selected_nav_unit = value;
      }
    })
  }

  togglecaret(event){
    let test = document.querySelector('#dropdownMenuButton');
    var caret = test.querySelector('.caret-dropdown');
    caret.classList.toggle('active');
  }

  setUnit(unit: iStudentNavUnits){
    if (!unit.locked){
      this.selected_nav_unit = unit;
      this.sharedService.setSelectedUnit(this.selected_nav_unit);
      localStorage.setItem("selectedUnit", JSON.stringify(this.selected_nav_unit));
      localStorage.removeItem("selectedLessonId");
      localStorage.removeItem("selectedSectionId");
    }
  }
  
  ngOnDestroy() {
    if (this.suservice){ this.suservice.unsubscribe(); };
  }
}
