import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iStudentNavActivities } from '../models/studentactivities.interface';
import { iUnitDetails } from '../models/units.interface';
import { iStudentNavByUnit } from '../models/studentunits.interface';
import { iLittleKidsSection } from '../models/studentsections.interface';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
  })
};

@Injectable()
export class StudentGradesService {
  constructor(private http: HttpClient) {}
  
  GetActivitySteps(SectionId): Observable<iStudentNavActivities[]> {
    return this.http.get<iStudentNavActivities[]>(environment.conn_string + 'api/student_grades/activities_by_section/' + SectionId, httpOptions);
  }

  GetLittleKidsSections(CurriculumId): Observable<iLittleKidsSection[]> {
    return this.http.get<iLittleKidsSection[]>(environment.conn_string + 'api/student_grades/little_kids_sections/' + CurriculumId, httpOptions);
  }

  GetUnitDetails(UnitId): Observable<iUnitDetails> {
    return this.http.get<iUnitDetails>(environment.conn_string + 'api/student_grades/unit_details/' + UnitId, httpOptions);
  }

  GetStudentNavByUnit(UnitId: string): Observable<iStudentNavByUnit> {
    return this.http.get<iStudentNavByUnit>(environment.conn_string + 'api/student_grades/lessons/' + UnitId, httpOptions);
  }

  GetStudentActivityDetails(ActivityId: string): Observable<any> {
    return this.http.get<any>(environment.conn_string + 'api/student_grades/activity_details_native_web/' + ActivityId, httpOptions);
  }
}