<svg xmlns="http://www.w3.org/2000/svg" width="150" viewBox="0 0 256 50" style="cursor: pointer;">
  <defs>
    <style>
      .cls-secondary-color {
        fill: #ae529b;
      }

      .cls-white {
        fill: #fff;
      }

      .cls-2bigdone {
        font-size: 22px;
        font-family: Arimo-Bold, Arimo;
        font-weight: 700;
      }
    </style>
  </defs>
  <g id="Group_247" data-name="Group 247" transform="translate(1061 -18544)">
    <g id="Group_242" data-name="Group 242">
      <path id="Path_189" data-name="Path 189" class="cls-secondary-color" d="M4,0H252a4,4,0,0,1,4,4V46a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(-1061 18544)"/>
      <text id="DONE" class="cls-2bigdone cls-white" transform="translate(-1050 18557)"><tspan id="TDONE" x="85.219" y="20">DONE</tspan></text>
    </g>
    <path id="Path_190" data-name="Path 190" class="cls-white" d="M482.826-107.9a15,15,0,0,0-15,15,15,15,0,0,0,15,15,15,15,0,0,0,15-15A15,15,0,0,0,482.826-107.9Zm9.6,10.808L481.048-85.714a1.205,1.205,0,0,1-1.705,0L477.2-87.856l-3.975-3.975a1.205,1.205,0,0,1,0-1.705l1.289-1.289a1.205,1.205,0,0,1,1.705,0l3.975,3.975,9.236-9.236a1.205,1.205,0,0,1,1.705,0l1.289,1.289A1.206,1.206,0,0,1,492.426-97.092Z" transform="translate(-1507.326 18661.988)"/>
  </g>
</svg>


