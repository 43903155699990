import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { iGroupActivity } from '../../models/groupactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';
import { Subject } from 'rxjs/Subject';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

export interface iResponse {
  answer: string,
  groupId: number,
  questionId: number
}

@Component({
    selector: 'biggroupwork',
    templateUrl: './biggroupwork.component.html',
    styleUrls: ['./biggroupwork.component.scss'],
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class BigGroupWorkComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iGroupActivity;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  hidden: boolean;
  selected_group_id: any;
  saved_time: number;
  respondForm: FormGroup;
  private unsubscribe = new Subject<void>();
  response: iResponse[];
  directions: string;
  disabled_input: boolean;
  response_group_id: number;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private formBuilder: FormBuilder, 
    private authService: AuthService,
    private sharedService: SharedService ) {
      this.hidden = false;
      this.selected_group_id = null;
      this.response = [];
      this.disabled_input = false;
      this.response_group_id = -999;
     }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];

      if(this.activity.locked && this.activity.completed){
        this.disabled_input = true;
      }
      
      if (this.activity.response && this.activity.response.data){
        this.response_group_id = this.activity.response.data[0].groupId;
      }

      let i = 0;
      this.activity.data[0].questions.forEach(g =>{
        if (i == 0){
          this.respondForm = this.formBuilder.group({
            itemRows: this.formBuilder.array([this.initItemRows(g)])
          });
        } else {
          this.addExistingRow(g);
        }
        i++;
      })

      this.respondForm.valueChanges.pipe(
        debounceTime(500),
        switchMap(async (formValue) => this.submitActivity(false)),
        takeUntil(this.unsubscribe)
      ).subscribe(() => console.log('Saved'))

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data['directions'] && this.activity.data['directions'] !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  initItemRows(g: any) {
    let ans: string = '';
    if (this.activity.response && this.activity.response.data[g.id]){
      ans = this.activity.response.data[g.id].answer;
    }

    return this.formBuilder.group({
      questionId: [g.id],
      answer: [{value: ans, disabled: this.disabled_input}]
    });
  }

  addExistingRow(g: any) {
    const control = <FormArray>this.respondForm.controls['itemRows'];
    control.push(this.initItemRows(g));
  }

  getControls() {
    return (<FormArray>this.respondForm.get('itemRows')).controls;
  }

  setGroup(group_id) {
    let i = 0;
    this.activity.data[group_id].questions.forEach(g =>{
      if (i == 0){
        this.respondForm = this.formBuilder.group({
          itemRows: this.formBuilder.array([this.initItemRows(g)])
        });
      } else {
        this.addExistingRow(g);
      }
      i++;
    })

    this.hidden = true;
    this.selected_group_id = group_id == 0 ? "0" : group_id;
  }

  goBack(){
    if(this.activity.locked && this.activity.completed){
      this.hidden = false;
      this.selected_group_id = null;
    } else {
      if (confirm('Are you sure you want to go back? Once done, your answers for this group will be deleted.')) {
        this.hidden = false;
        this.selected_group_id = null;
  
        //clear out respond form
        this.respondForm.controls['itemRows']['controls'].forEach((element, index) => {
          this.respondForm.controls['itemRows']['controls'][index].controls['answer'].value = "";
          this.respondForm.value['itemRows'][index]['answer'] = "";
        });
        
        this.submitActivity(false);

        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      };
    }
  }

  submitActivity(show_success: boolean){
    this.response = [];
    this.respondForm.value['itemRows'].forEach(r => {
      this.response.push({
        answer: r.answer, 
        groupId: parseInt(this.selected_group_id), 
        questionId: r.questionId})
    });
    
    this.sharedService.submitActivityResponse(this.activity.id, this.response, show_success);

    this.sharedService.getSubmitActivitySuccess().subscribe(success => {
      if (success){
        this.saved_time = Date.now();
      }
    })
  }

  resubmit(){
    this.response = [];
    this.respondForm.value['itemRows'].forEach(r => {
      this.response.push({
        answer: r.answer, 
        groupId: parseInt(this.selected_group_id), 
        questionId: r.questionId})
    });
    this.resubmitResponse.emit(this.response);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
    this.unsubscribe.next();
  }
}
