import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iStudentNotifications } from '../models/notification.interface';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
    })
  };
  
  @Injectable()
  export class NotificationService {
  
    constructor(private http: HttpClient) {}

    GetNotifications(): Observable<iStudentNotifications[]> {
      return this.http.get<iStudentNotifications[]>(environment.conn_string + 'api/notifications/student_messages_native_web', httpOptions);
    }

    MarkRead(notification_id: string): Observable<iStudentNotifications> {
      return this.http.get<iStudentNotifications>(environment.conn_string + 'api/notifications/mark_as_read?student_notification_id=' + notification_id, httpOptions)
    }

    GetUnreadCount() {
      return this.http.get(environment.conn_string + 'api/notifications/unread_count', httpOptions);
    }
  }
  