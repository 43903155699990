import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iStudentNavSections, iStudentSection } from '../models/studentsections.interface';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
  })
};

@Injectable()
export class StudentSectionsService {
  constructor(private http: HttpClient) {}
  
  GetNavSections(StudentId, LessonId): Observable<iStudentNavSections[]> {
    return this.http.get<iStudentNavSections[]>(environment.conn_string + 'api/student_sections/get_nav_sections?student_id=' + StudentId + '&lesson_id=' + LessonId, httpOptions);
  }

  GetStudentSectionDetails(StudentId, SectionId): Observable<iStudentSection> {
    return this.http.get<iStudentSection>(environment.conn_string + 'api/student_sections/details/' + StudentId + '/' + SectionId, httpOptions);
  }
}