import { Component } from '@angular/core';

@Component({
    selector: 'littleactivitydonebutton',
    templateUrl: './littleactivitydonebutton.component.html'
})

export class LittleActivityDoneButton {

  constructor( ) {  }
}
