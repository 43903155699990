<div *ngIf="selected_section" id="accordion" class="activity-card-wrapper">
    <div class="grad-card-wrapper">
        <div class="card">
            <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                    <button [attr.id]="'activityCaret' + index" class="btn btn-link" style="padding-left:0px; padding-right:23px;" data-toggle="collapse" [attr.data-target]="'#collapse' + index" aria-expanded="true" aria-controls="collapseOne" (click)="togglecaret(index)">
                        <div class="card-header-caret-container">
                            <a href="#" class="card-header-dropdown-trigger"><span class="card-header-caret-dropdown active"></span></a>
                        </div> 
                    </button>
                    <span class="card-header-title">Step {{activity.activity_number}}</span>
                    <span *ngIf="!activity.completed && activity.locked" style="float: right; color: rgb(233, 225, 225);"><i class="far fa-circle fa-lg"></i></span>
                    <span *ngIf="!activity.completed && !activity.locked" style="float: right; color: #fff;"><i class="fas fa-file fa-lg"></i></span>
                    <span *ngIf="activity.completed && activity.locked" style="float: right; color: #fff;"><i class="fas fa-check-circle fa-lg"></i></span>
                    <span *ngIf="activity.completed && !activity.locked && !selected_section.completed" style="float: right; color: #fff;"><i class="fas fa-file fa-lg"></i></span>
                    <span *ngIf="activity.completed && !activity.locked && selected_section.completed" style="float: right; color: #fff;"><i class="fas fa-redo fa-flip-horizontal fa-lg"></i></span>
                </h5>
            </div>
            <div [attr.id]="'collapse' + index" class="collapse show" style="position: relative;" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body activity-card-body" [ngClass]="disabled_modal">
                    <bigtext *ngIf="activity.activity_type == 'Text'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigtext>
                    <bigrespond *ngIf="activity.activity_type == 'Respond'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigrespond>
                    <bighotspot *ngIf="activity.activity_type == 'Hot Spot'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bighotspot>
                    <bigmultiplechoice *ngIf="activity.activity_type == 'Multiple Choice'" [index]="index" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigmultiplechoice>
                    <bigphotoupload *ngIf="activity.activity_type == 'Photo Upload'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigphotoupload>
                    <bigchoose *ngIf="activity.activity_type == 'Choose'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigchoose>
                    <bigispy *ngIf="activity.activity_type == 'iSpy'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigispy>
                    <bigspotthedifference *ngIf="activity.activity_type == 'Spot the Difference'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigspotthedifference>
                    <bigfib *ngIf="activity.activity_type == 'Fill in the blank'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigfib>
                    <biggroupwork *ngIf="activity.activity_type == 'Group Activity'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></biggroupwork>
                    <bigcrossword *ngIf="activity.activity_type == 'Crossword Puzzle'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigcrossword>
                    <bigfingerdraw *ngIf="activity.activity_type == 'Finger Draw'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigfingerdraw>
                    <bigchart *ngIf="activity.activity_type == 'Chart'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigchart>
                    <bigpuzzle *ngIf="activity.activity_type == 'Puzzle'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigpuzzle>
                    <bigflashcards *ngIf="activity.activity_type == 'Flash Cards'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigflashcards>
                    <bigdraganddroptimeline *ngIf="activity.activity_type == 'Drag and Drop - Timeline'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigdraganddroptimeline>
                    <bigdraganddropfib *ngIf="activity.activity_type == 'Drag and Drop FIB'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigdraganddropfib>
                    <bigmatching *ngIf="activity.activity_type == 'Matching'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigmatching>
                    <bigwordsearch *ngIf="activity.activity_type == 'Word Search'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></bigwordsearch>
                    <shootandsort *ngIf="activity.activity_type == 'Shoot and Sort'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></shootandsort>
                    <drivinggame *ngIf="activity.activity_type == 'Driving Game'" [activity_id]="activity.id.toString()" (resubmitResponse)="storeDataForResubmit($event)" class="big-activity-text"></drivinggame>
                </div>

                <div *ngIf="activity.locked && !activity.completed" id="greyedOut" class="greyedOut">
                    <i class="fas fa-lock-alt fa-2x center-icon"></i>
                </div>
                <div *ngIf="activity.locked && activity.completed && 
                            activity.activity_type !== 'Crossword Puzzle' &&
                            activity.activity_type !== 'Spot the Difference' &&
                            activity.activity_type !== 'iSpy' &&
                            activity.activity_type !== 'Hot Spot' &&
                            activity.activity_type !== 'Group Activity'" id="greyedOut" class="greyedOut"></div>
            </div>
        </div>
    </div>
</div>
