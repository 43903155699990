import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { BigVertExpandedComponent } from "./bigvertexpanded.component";

const BIG_VERT_EXPANDED_ROUTE = [
    { path: '', component: BigVertExpandedComponent }
];

@NgModule ({
    declarations: [
        BigVertExpandedComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(BIG_VERT_EXPANDED_ROUTE)
    ],
    exports: [
        BigVertExpandedComponent
    ]
})

export class BigVertExpandModule {  }