import { Component } from '@angular/core';

@Component({
    selector: 'bigactivitydonedisabledbutton',
    templateUrl: './bigactivitydonedisabledbutton.component.html'
})

export class BigActivityDoneDisabledButton {

  constructor( ) {  }
}
