<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>
  
  <div class="row" style="margin-bottom: 20px;">
    <div class="col-md-6 text-center">{{ activity.data.left_column_title }}</div>
    <div class="col-md-6 text-center">{{ activity.data.right_column_title }}</div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div *ngFor="let left of left_column" class="left-set" [ngStyle]="{'border':left.active == true ? '6px solid ' + left.color : 'none'}" (click)="matchLeft(left.id)">
        <span *ngIf="left.text !== ''">
          {{ left.text }}
        </span>
        <span *ngIf="left.text === ''">
          <img [src]="left.image.file" />
        </span>
      </div>
    </div>
    <div class="col-md-6" style="border-left: 4px dotted #e3e3e3">
      <div *ngFor="let right of right_column" class="right-set" [ngStyle]="{'background-color':right.active == true ? right.color : '#f2f2f2', 'color':right.active == true ? '#fff' : '#5b5b5b'}" (click)="matchRight(right.id)">
        <span *ngIf="right.image === ''">
          {{ right.text }}
        </span>
        <span *ngIf="right.image !== ''">
          <img [src]="right.image.file" />
        </span>
      </div>
    </div>
  </div>

  <div class="text-center save-activity-button">
    <littleactivitydonedisabledbutton *ngIf="!activity.locked && !selected_section.completed && disabled" data-dismiss="modal"></littleactivitydonedisabledbutton>
    <littleactivitydonebutton *ngIf="!activity.locked && !activity.completed && !disabled" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed && !disabled" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></littleactivitysubmittedbutton>
  </div>
</div>