import { Component } from '@angular/core';

@Component({
    selector: 'littleactivitysubmittedbutton',
    templateUrl: './littleactivitysubmittedbutton.component.html'
})

export class LittleActivitySubmittedButton {

  constructor( ) {  }
}
