<!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="150" viewBox="0 0 291.21 104.23" style="cursor: pointer;">
  <defs>
    <style>
      .cls-1-tst {
        fill: #83b61f;
      }

      .cls-2-tst {
        fill: none;
      }

      .cls-primary-color {
        fill: #74ad99;
      }

      .cls-4-tst {
        fill: #fff;
        font-size: 32px;
        font-family: MuseoSans-900, Museo Sans;
        font-weight: 900;
      }

      .cls-5-tst {
        clip-path: url(#clip-path);
      }

      .cls-secondary-color {
        fill: #98d4c2;
      }

      .cls-7-tst, .cls-8-tst {
        stroke: none;
      }

      .cls-8-tst {
        fill: #fff;
      }

      .cls-9-tst {
        filter: url(#Get_Started_);
      }

      .cls-10-tst {
        filter: url(#Path_149);
      }
    </style>
    <filter id="Path_149" x="0" y="0" width="291.21" height="104.23" filterUnits="userSpaceOnUse">
      <feOffset dx="1" dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur"/>
      <feFlood flood-opacity="0.322"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Get_Started_" x="8.605" y="27.5" width="274" height="56" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_136" data-name="Rectangle 136" class="cls-1-tst" width="254.21" height="66.23" rx="24" transform="translate(389 653)"/>
    </clipPath>
  </defs>
  <g id="Group_272" data-name="Group 272" transform="translate(10330.605 -14582.385)">
    <g id="Group_271" data-name="Group 271" transform="translate(-10698.105 13958.885)">
      <g class="cls-10-tst" transform="matrix(1, 0, 0, 1, 367.5, 623.5)">
        <g id="Path_149-2" data-name="Path 149" class="cls-2-tst" transform="translate(16.5 15.5)">
          <path class="cls-7-tst" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z"/>
          <path class="cls-8-tst" d="M 24 0 C 10.74516296386719 0 0 10.74516296386719 0 23.99999618530273 L 0 45.22999572753906 C 0 58.48483276367188 10.74516296386719 69.22999572753906 24 69.22999572753906 L 232.2099914550781 69.22999572753906 C 245.4648284912109 69.22999572753906 256.2099914550781 58.48483276367188 256.2099914550781 45.22999572753906 L 256.2099914550781 23.99999618530273 C 256.2099914550781 10.74516296386719 245.4648284912109 0 232.2099914550781 0 L 24 0 M 24 -10 L 232.2099914550781 -10 C 250.9576568603516 -10 266.2099914550781 5.252330780029297 266.2099914550781 23.99999618530273 L 266.2099914550781 45.22999572753906 C 266.2099914550781 63.97766494750977 250.9576568603516 79.22999572753906 232.2099914550781 79.22999572753906 L 24 79.22999572753906 C 5.252334594726562 79.22999572753906 -10 63.97766494750977 -10 45.22999572753906 L -10 23.99999618530273 C -10 5.252330780029297 5.252334594726562 -10 24 -10 Z"/>
        </g>
      </g>
      <path id="Path_150" data-name="Path 150" class="cls-primary-color" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(384 641)"/>
      <path id="Path_148" data-name="Path 148" class="cls-primary-color" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(384 639)"/>
      <g class="cls-9-tst" transform="matrix(1, 0, 0, 1, 367.5, 623.5)">
        <text id="Get_Started_2" data-name="Get Started!" class="cls-4-tst" transform="translate(17.6 33.5)"><tspan x="32.912" y="30">Get Started!</tspan></text>
      </g>
      <g id="Mask_Group_19" data-name="Mask Group 19" class="cls-5-tst" transform="translate(-4 -13)">
        <path id="Path_147" data-name="Path 147" class="cls-secondary-color" d="M306.942,32.768c0,10.408-63.514,18.886-141.871,18.886S23.2,43.176,23.2,32.768,86.369,13.4,164.726,13.4,306.942,22.36,306.942,32.768Z" transform="translate(350.616 619.311)"/>
      </g>
    </g>
  </g>
</svg> -->

<div style="width: 100%;">
  <div class="little-activity-done-button">
    Get Started!
  </div>
</div>




