<div *ngIf="!navbar_collapsed && nav_icon_selected == 'nav' && student_nav" class="unit-nav-container">
  <perfect-scrollbar class="unit-nav-scrollbar-height">
    <div class="row">
      <div class="col-md-12 unit-nav-unit-header">
        Unit {{ student_nav[0].unit_number }}
      </div>
      <div *ngFor="let lesson of student_nav; let i = index;" 
           class="big-lesson-container" 
           [id]="lesson.id"
           [ngClass]="{'big-active-lesson-nav' : lesson.id == selected_lesson_id}"
           (mouseover)="tempActive($event, lesson.id, selected_lesson_id)" 
           (mouseout)="tempActive($event, lesson.id, selected_lesson_id)">
        <div class="unit-nav-lesson-header">
          {{ lesson_aka }} {{ lesson.lesson_number }}
        </div>
        <div *ngFor="let section of lesson.sections" class="row">
          <!-- Locked -->
          <div *ngIf="section.locked && !section.completed" class="unit-nav-section-icon-locked col-md-1">
            <i class="fas fa-lock-alt fa-xs"></i>
          </div>
          <!-- Unlocked -->
          <div *ngIf="!section.locked && !section.completed" class="unit-nav-section-icon-locked col-md-1">
            <i class="far fa-horizontal-rule fa-xs"></i>
          </div>
          <!-- Completed -->
          <div *ngIf="section.locked && section.completed" class="unit-nav-section-icon-completed col-md-1">
            <!-- <i class="fas fa-bookmark fa-xs"></i> -->
            <bigbookmarkicon></bigbookmarkicon>
          </div>
          <!-- Reassigned -->
          <div *ngIf="!section.locked && section.completed" class="unit-nav-section-icon-reassigned col-md-1">
            <i class="fas fa-redo fa-flip-horizontal fa-xs"></i>
          </div>
          <div *ngIf="section.locked && !section.completed" class="unit-nav-section-name col-md-10" [ngClass]="{'color-white' : !section.locked}">{{section.name}}</div>
          <div *ngIf="section.locked && section.completed" class="unit-nav-section-name col-md-10" [ngClass]="{'color-white' : !section.locked}" [routerLink]="['/app/sectionactivities']" (click)="setLessonAndSection(lesson.id, section.id)">{{section.name}}</div>
          <div *ngIf="!section.locked" class="unit-nav-section-name col-md-10" [ngClass]="{'color-white' : !section.locked}" [routerLink]="['/app/sectionactivities']" (click)="setLessonAndSection(lesson.id, section.id)">{{section.name}}</div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>