import { Component } from '@angular/core';

@Component({
    selector: 'bigactivitydonebutton',
    templateUrl: './bigactivitydonebutton.component.html'
})

export class BigActivityDoneButton {

  constructor( ) {  }
}
