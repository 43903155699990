import { Component } from '@angular/core';

@Component({
    selector: 'bigactivityresubmitdisabledbutton',
    templateUrl: './bigactivityresubmitdisabledbutton.component.html'
})

export class BigActivityResubmitDisabledButton {

  constructor( ) {  }
}
