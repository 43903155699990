import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { FilePond } from 'filepond';
import { iImage } from '../models/image.interface';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
  })
};

@Injectable()
export class ActivityService {
  constructor(private http: HttpClient) {}

  UploadFile(file: any): Observable<iImage> {
    return this.http.post<iImage>( environment.conn_string + 'api/activities/upload_file', file, httpOptions );
  }
}