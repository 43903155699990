<div *ngIf="activity">
  <div class="secondary-font text-center little-directions">
    Take some time to analyze this famous artwork!
  </div>
  <div class="text-center save-activity-button">
    <littleactivitygetstartedbutton 
      *ngIf="!activity.locked" 
      [attr.href]="'#difference-modal' + activity.activity_number"
      data-toggle="modal" 
      data-backdrop="static" 
      data-keyboard="false" 
      (click)="showSpots();">
    </littleactivitygetstartedbutton>
    <littleactivityviewsubmissionbutton 
      *ngIf="activity.locked && activity.completed"
      [attr.href]="'#difference-modal' + activity.activity_number"
      data-toggle="modal" 
      data-backdrop="static" 
      data-keyboard="false" 
      (click)="showSpots();">
    </littleactivityviewsubmissionbutton>
  </div>
</div>

<div *ngIf="activity && selected_section" class="modal fade" id="difference-modal{{activity.activity_number}}" tabindex="-1" role="dialog" aria-labelledby="details-modal-label" aria-hidden="true">
  <div class="modal-dialog mt-6 modal-xl sc-modal-border" role="document">
      <div class="modal-content border-0 sc-modal-content">
          <div class="modal-header px-5 text-white position-relative sc-modal-header">
            <div class="row" style="width: 100% !important;">
              <div class="col-md-10">
                <h4 class="mb-0 font-secondary" style="color: white;" id="hotspot-modal-label"><strong>{{ activity.data.art_title }}</strong></h4>
              </div>
              <div class="col-md-2 text-right">
                <span style="cursor: pointer; margin-right: 20px;">
                  <biginfoicon 
                    data-toggle="popover" 
                    [attr.data-content]="art_details"
                    data-html="true" 
                    data-trigger="click"></biginfoicon>
                </span>
                <!-- <bigtouchicon (click)="showHideSpots();"></bigtouchicon> -->
              </div>
            </div>
            <button class="btn-close position-absolute top-0 right-0 mt-2 mr-2" (click)="clearAllSpots()" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body py-4 px-5">
            <div class="row">
              <div class="col-md-6">
                <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>
              </div>
              <div class="col-md-6 text-right">
                <strong>{{ differences_left }}</strong> differences left
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <img [src]="activity.data.image_original.file" style="width: 100%; height:100%" />
              </div>
              <div class="col-md-6">
                <img [src]="activity.data.image_different.file" style="width: 100%; height:100%" id="stdImg" />
                <bighotspotbutton *ngFor="let spot of spots; let i = index;" 
                  data-toggle="popover"
                  id={{i}}              
                  [attr.data-content]="tool_tips[i]"
                  style="position:absolute;" 
                  [style.top.px]=style_top[i]
                  [style.left.px]=style_left[i]
                  [style.opacity]="spot.found ? '100' : '0'"
                  (click)="spotFound(spot,i)"></bighotspotbutton>
              </div>
            </div>

            <div class="text-center save-activity-button">
              <littleactivitydonedisabledbutton *ngIf="!activity.locked && !selected_section.completed && disabled" style="cursor: not-allowed;"></littleactivitydonedisabledbutton>
              <littleactivitydonebutton *ngIf="!activity.locked && !selected_section.completed && !activity.completed && !disabled" data-dismiss="modal" (click)="submitActivity(true)"></littleactivitydonebutton>
              <littleactivitydonebutton *ngIf="!activity.locked && !selected_section.completed && activity.completed && !selected_section.completed && !disabled" data-dismiss="modal" (click)="submitActivity(true)"></littleactivitydonebutton>
              <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed" (click)="clearAllSpots()" data-dismiss="modal"></littleactivitysubmittedbutton>
              <bigactivityresubmitdisabledbutton *ngIf="activity.completed && !activity.locked && selected_section.completed && disabled" style="cursor: not-allowed;"></bigactivityresubmitdisabledbutton>
              <bigactivityresubmitbutton *ngIf="activity.completed && !activity.locked && selected_section.completed && !disabled" (click)="resubmit();" data-dismiss="modal"></bigactivityresubmitbutton>
            </div>
          </div>
      </div>
  </div>
</div>

