import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iAcademicYear } from '../models/academicyear.interface';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
    //   'Authorization': localStorage.getItem('token')
    })
  };

@Injectable()
export class AcademicYearService {
    constructor(private http: HttpClient) { }

    CreateAcademicYear(AcademicYear: iAcademicYear): Observable<iAcademicYear> {
        return this.http.post<iAcademicYear>(environment.conn_string + "api/academic_years", AcademicYear, httpOptions);
    }

    GetAcademicYearDetails(AcademicYearId: string) {
        return this.http.get(environment.conn_string + "api/academic_years/details/" + AcademicYearId, httpOptions);
    }

    GetAcademicYears() {
        return this.http.get<iAcademicYear[]>(environment.conn_string + "api/academic_years", httpOptions);
    }

    UpdateAcademicYears(AcademicYear: iAcademicYear): Observable<iAcademicYear> {
        return this.http.put<iAcademicYear>(environment.conn_string + "api/academic_years/" + AcademicYear.id, AcademicYear, httpOptions);
    }

    //Standard Academic Years
    CreateStandardAcademicYear(AcademicYear: iAcademicYear): Observable<iAcademicYear> {
        return this.http.post<iAcademicYear>(environment.conn_string + "api/standard_academic_years", AcademicYear, httpOptions);
    }

    GetStandardAcademicYearDetails(AcademicYearId: string) {
        return this.http.get(environment.conn_string + "api/standard_academic_years/details/" + AcademicYearId, httpOptions);
    }

    GetStandardAcademicYears() {
        return this.http.get<iAcademicYear[]>(environment.conn_string + "api/standard_academic_years", httpOptions);
    }

    UpdateStandardAcademicYears(AcademicYear: iAcademicYear): Observable<iAcademicYear> {
        return this.http.put<iAcademicYear>(environment.conn_string + "api/standard_academic_years/" + AcademicYear.id, AcademicYear, httpOptions);
    }
}