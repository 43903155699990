import { Component } from '@angular/core';

@Component({
    selector: 'littleactivitygetstartedbutton',
    templateUrl: './littleactivitygetstartedbutton.component.html'
})

export class LittleActivityGetStartedButton {

  constructor( ) {  }
}
