<svg xmlns="http://www.w3.org/2000/svg" width="150" viewBox="0 0 256 50" style="cursor: pointer;">
  <defs>
    <style>
      .cls-primary-color {
        fill: #683868;
      }

      .cls-2, .cls-3 {
        fill: #fff;
      }

      .cls-2 {
        font-size: 22px !important;
        font-family: Arimo-Bold, Arimo;
        font-weight: 700;
      }
    </style>
  </defs>
  <g id="Group_243" data-name="Group 243" transform="translate(1061 -18447)">
    <g id="Group_241" data-name="Group 241" transform="translate(-1893.5 17313)">
      <path id="Path_149" data-name="Path 149" class="cls-primary-color" d="M4,0H252a4,4,0,0,1,4,4V46a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(832.5 1134)"/>
      <text id="Finish_Section" data-name="Finish Section" class="cls-2" transform="translate(982.75 1147)"><tspan x="-88.005" y="20">FINISH SECTION</tspan></text>
      <g id="Icon_Activities" data-name="Icon / Activities" transform="translate(853.5 1144)">
        <path id="Icon_Activities-2" data-name="Icon / Activities" class="cls-3" d="M25,3H8.333A3.343,3.343,0,0,0,5,6.333V33l11.667-5,11.667,5V6.333A3.343,3.343,0,0,0,25,3ZM22.167,23.833l-5.5-3.333-5.5,3.333,1.5-6.167L7.833,13.5l6.333-.5,2.5-5.833L19.167,13l6.333.5-4.833,4.167Z" transform="translate(-5 -3)"/>
      </g>
    </g>
  </g>
</svg>

