<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" viewBox="0 0 214.21 210.764">
  <defs>
    <filter id="Rectangle_150" x="1" y="0" width="159.21" height="196.522" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_148" x="40.1" y="31.764" width="133" height="178.999" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_152" x="55" y="14.001" width="159.21" height="196.522" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-3"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-3"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_154" x="39.62" y="13.951" width="143.951" height="186.628" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-4"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-4"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#e8a957"/>
      <stop offset="1" stop-color="#ce8b3d"/>
    </radialGradient>
    <filter id="Rectangle_149" x="0" y="0" width="159.21" height="196.524" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-5"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-5"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_150)">
        <rect id="Rectangle_150-2" data-name="Rectangle 150" width="115" height="160.999" rx="15" transform="translate(0 0)" fill="#fff"/>
      </g>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect width="110.142" height="153.141" fill="none"/>
    </clipPath>
    <filter id="Rectangle_147" x="40.1" y="31.764" width="133" height="179" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-6"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-6"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_151" x="55" y="14.001" width="159.21" height="196.524" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-7"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-7"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_153" x="39.62" y="13.951" width="143.951" height="186.63" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-8"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-8"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_99" data-name="Group 99" transform="translate(-31.9 -288.236)">
    <g id="Card_1" data-name="Card 1" transform="translate(-10.4 103.92) rotate(-10)">
      <g transform="matrix(0.98, 0.17, -0.17, 0.98, 9.65, 188.86)" filter="url(#Rectangle_149)">
        <g id="Rectangle_149-2" data-name="Rectangle 149" transform="translate(9 25.97) rotate(-10)" stroke="#fff" stroke-width="5" fill="url(#radial-gradient)">
          <rect width="115" height="161" rx="15" stroke="none"/>
          <rect x="2.5" y="2.5" width="110" height="156" rx="12.5" fill="none"/>
        </g>
      </g>
      <g id="Mask_Group_25" data-name="Mask Group 25" transform="matrix(1, 0, 0, 1, 14.99, 216.172)" opacity="0.25" clip-path="url(#clip-path)">
        <g id="Repeat_Grid_9" data-name="Repeat Grid 9" transform="translate(1 3.968)" clip-path="url(#clip-path-2)">
          <g transform="translate(-0.358 -216.126)">
            <path id="Path_634" data-name="Path 634" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -216.126)">
            <path id="Path_635" data-name="Path 635" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -216.126)">
            <path id="Path_636" data-name="Path 636" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -216.126)">
            <path id="Path_637" data-name="Path 637" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -216.126)">
            <path id="Path_638" data-name="Path 638" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -216.126)">
            <path id="Path_639" data-name="Path 639" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -216.126)">
            <path id="Path_640" data-name="Path 640" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -188.126)">
            <path id="Path_641" data-name="Path 641" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -188.126)">
            <path id="Path_642" data-name="Path 642" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -188.126)">
            <path id="Path_643" data-name="Path 643" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -188.126)">
            <path id="Path_644" data-name="Path 644" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -188.126)">
            <path id="Path_645" data-name="Path 645" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -188.126)">
            <path id="Path_646" data-name="Path 646" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -188.126)">
            <path id="Path_647" data-name="Path 647" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -160.126)">
            <path id="Path_648" data-name="Path 648" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -160.126)">
            <path id="Path_649" data-name="Path 649" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -160.126)">
            <path id="Path_650" data-name="Path 650" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -160.126)">
            <path id="Path_651" data-name="Path 651" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -160.126)">
            <path id="Path_652" data-name="Path 652" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -160.126)">
            <path id="Path_653" data-name="Path 653" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -160.126)">
            <path id="Path_654" data-name="Path 654" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -132.126)">
            <path id="Path_655" data-name="Path 655" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -132.126)">
            <path id="Path_656" data-name="Path 656" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -132.126)">
            <path id="Path_657" data-name="Path 657" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -132.126)">
            <path id="Path_658" data-name="Path 658" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -132.126)">
            <path id="Path_659" data-name="Path 659" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -132.126)">
            <path id="Path_660" data-name="Path 660" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -132.126)">
            <path id="Path_661" data-name="Path 661" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -104.126)">
            <path id="Path_662" data-name="Path 662" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -104.126)">
            <path id="Path_663" data-name="Path 663" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -104.126)">
            <path id="Path_664" data-name="Path 664" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -104.126)">
            <path id="Path_665" data-name="Path 665" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -104.126)">
            <path id="Path_666" data-name="Path 666" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -104.126)">
            <path id="Path_667" data-name="Path 667" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -104.126)">
            <path id="Path_668" data-name="Path 668" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -76.126)">
            <path id="Path_669" data-name="Path 669" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -76.126)">
            <path id="Path_670" data-name="Path 670" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -76.126)">
            <path id="Path_671" data-name="Path 671" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -76.126)">
            <path id="Path_672" data-name="Path 672" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -76.126)">
            <path id="Path_673" data-name="Path 673" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -76.126)">
            <path id="Path_674" data-name="Path 674" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -76.126)">
            <path id="Path_675" data-name="Path 675" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
        </g>
      </g>
      <text id="Vulgate" transform="translate(71.017 302.51)" fill="#fff" font-size="16" font-family="Arimo-Bold, Arimo" font-weight="700"><tspan x="-28.598" y="0">Vulgate</tspan></text>
    </g>
    <g id="Card_2" data-name="Card 2" transform="translate(67 110)">
      <g transform="matrix(1, 0, 0, 1, -35.1, 178.24)" filter="url(#Rectangle_147)">
        <g id="Rectangle_147-2" data-name="Rectangle 147" transform="translate(49.1 37.76)" stroke="#fff" stroke-width="5" fill="url(#radial-gradient)">
          <rect width="115" height="161" rx="15" stroke="none"/>
          <rect x="2.5" y="2.5" width="110" height="156" rx="12.5" fill="none"/>
        </g>
      </g>
      <g id="Mask_Group_24" data-name="Mask Group 24" transform="translate(14 216)" opacity="0.25" clip-path="url(#clip-path)">
        <g id="Repeat_Grid_8" data-name="Repeat Grid 8" transform="translate(1 3.968)" clip-path="url(#clip-path-2)">
          <g transform="translate(-0.358 -216.126)">
            <path id="Path_592" data-name="Path 592" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -216.126)">
            <path id="Path_593" data-name="Path 593" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -216.126)">
            <path id="Path_594" data-name="Path 594" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -216.126)">
            <path id="Path_595" data-name="Path 595" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -216.126)">
            <path id="Path_596" data-name="Path 596" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -216.126)">
            <path id="Path_597" data-name="Path 597" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -216.126)">
            <path id="Path_598" data-name="Path 598" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -188.126)">
            <path id="Path_599" data-name="Path 599" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -188.126)">
            <path id="Path_600" data-name="Path 600" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -188.126)">
            <path id="Path_601" data-name="Path 601" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -188.126)">
            <path id="Path_602" data-name="Path 602" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -188.126)">
            <path id="Path_603" data-name="Path 603" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -188.126)">
            <path id="Path_604" data-name="Path 604" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -188.126)">
            <path id="Path_605" data-name="Path 605" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -160.126)">
            <path id="Path_606" data-name="Path 606" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -160.126)">
            <path id="Path_607" data-name="Path 607" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -160.126)">
            <path id="Path_608" data-name="Path 608" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -160.126)">
            <path id="Path_609" data-name="Path 609" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -160.126)">
            <path id="Path_610" data-name="Path 610" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -160.126)">
            <path id="Path_611" data-name="Path 611" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -160.126)">
            <path id="Path_612" data-name="Path 612" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -132.126)">
            <path id="Path_613" data-name="Path 613" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -132.126)">
            <path id="Path_614" data-name="Path 614" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -132.126)">
            <path id="Path_615" data-name="Path 615" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -132.126)">
            <path id="Path_616" data-name="Path 616" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -132.126)">
            <path id="Path_617" data-name="Path 617" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -132.126)">
            <path id="Path_618" data-name="Path 618" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -132.126)">
            <path id="Path_619" data-name="Path 619" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -104.126)">
            <path id="Path_620" data-name="Path 620" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -104.126)">
            <path id="Path_621" data-name="Path 621" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -104.126)">
            <path id="Path_622" data-name="Path 622" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -104.126)">
            <path id="Path_623" data-name="Path 623" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -104.126)">
            <path id="Path_624" data-name="Path 624" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -104.126)">
            <path id="Path_625" data-name="Path 625" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -104.126)">
            <path id="Path_626" data-name="Path 626" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -76.126)">
            <path id="Path_627" data-name="Path 627" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -76.126)">
            <path id="Path_628" data-name="Path 628" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -76.126)">
            <path id="Path_629" data-name="Path 629" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -76.126)">
            <path id="Path_630" data-name="Path 630" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -76.126)">
            <path id="Path_631" data-name="Path 631" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -76.126)">
            <path id="Path_632" data-name="Path 632" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -76.126)">
            <path id="Path_633" data-name="Path 633" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
        </g>
      </g>
      <text id="Vulgate-2" data-name="Vulgate" transform="translate(71.033 302.337)" fill="#fff" font-size="16" font-family="Arimo-Bold, Arimo" font-weight="700"><tspan x="-28.598" y="0">Vulgate</tspan></text>
    </g>
    <g id="Card_3" data-name="Card 3" transform="matrix(0.985, 0.174, -0.174, 0.985, 147.573, 93.089)">
      <g transform="matrix(0.98, -0.17, 0.17, 0.98, -80.03, 212.27)" filter="url(#Rectangle_151)">
        <g id="Rectangle_151-2" data-name="Rectangle 151" transform="matrix(0.98, 0.17, -0.17, 0.98, 91.96, 20)" stroke="#fff" stroke-width="5" fill="url(#radial-gradient)">
          <rect width="115" height="161" rx="15" stroke="none"/>
          <rect x="2.5" y="2.5" width="110" height="156" rx="12.5" fill="none"/>
        </g>
      </g>
      <g id="Mask_Group_26" data-name="Mask Group 26" transform="translate(14.005 215.997)" opacity="0.25" clip-path="url(#clip-path)">
        <g id="Repeat_Grid_10" data-name="Repeat Grid 10" transform="translate(1 3.968)" clip-path="url(#clip-path-2)">
          <g transform="translate(-0.358 -216.126)">
            <path id="Path_676" data-name="Path 676" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -216.126)">
            <path id="Path_677" data-name="Path 677" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -216.126)">
            <path id="Path_678" data-name="Path 678" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -216.126)">
            <path id="Path_679" data-name="Path 679" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -216.126)">
            <path id="Path_680" data-name="Path 680" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -216.126)">
            <path id="Path_681" data-name="Path 681" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -216.126)">
            <path id="Path_682" data-name="Path 682" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -188.126)">
            <path id="Path_683" data-name="Path 683" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -188.126)">
            <path id="Path_684" data-name="Path 684" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -188.126)">
            <path id="Path_685" data-name="Path 685" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -188.126)">
            <path id="Path_686" data-name="Path 686" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -188.126)">
            <path id="Path_687" data-name="Path 687" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -188.126)">
            <path id="Path_688" data-name="Path 688" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -188.126)">
            <path id="Path_689" data-name="Path 689" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -160.126)">
            <path id="Path_690" data-name="Path 690" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -160.126)">
            <path id="Path_691" data-name="Path 691" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -160.126)">
            <path id="Path_692" data-name="Path 692" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -160.126)">
            <path id="Path_693" data-name="Path 693" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -160.126)">
            <path id="Path_694" data-name="Path 694" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -160.126)">
            <path id="Path_695" data-name="Path 695" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -160.126)">
            <path id="Path_696" data-name="Path 696" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -132.126)">
            <path id="Path_697" data-name="Path 697" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -132.126)">
            <path id="Path_698" data-name="Path 698" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -132.126)">
            <path id="Path_699" data-name="Path 699" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -132.126)">
            <path id="Path_700" data-name="Path 700" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -132.126)">
            <path id="Path_701" data-name="Path 701" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -132.126)">
            <path id="Path_702" data-name="Path 702" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -132.126)">
            <path id="Path_703" data-name="Path 703" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -104.126)">
            <path id="Path_704" data-name="Path 704" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -104.126)">
            <path id="Path_705" data-name="Path 705" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -104.126)">
            <path id="Path_706" data-name="Path 706" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -104.126)">
            <path id="Path_707" data-name="Path 707" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -104.126)">
            <path id="Path_708" data-name="Path 708" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -104.126)">
            <path id="Path_709" data-name="Path 709" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -104.126)">
            <path id="Path_710" data-name="Path 710" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -76.126)">
            <path id="Path_711" data-name="Path 711" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -76.126)">
            <path id="Path_712" data-name="Path 712" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -76.126)">
            <path id="Path_713" data-name="Path 713" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -76.126)">
            <path id="Path_714" data-name="Path 714" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -76.126)">
            <path id="Path_715" data-name="Path 715" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -76.126)">
            <path id="Path_716" data-name="Path 716" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -76.126)">
            <path id="Path_717" data-name="Path 717" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
        </g>
      </g>
      <text id="Vulgate-3" data-name="Vulgate" transform="translate(72.017 302.163)" fill="#fff" font-size="16" font-family="Arimo-Bold, Arimo" font-weight="700"><tspan x="-28.598" y="0">Vulgate</tspan></text>
    </g>
    <g id="Card_4" data-name="Card 4" transform="matrix(0.998, -0.07, 0.07, 0.998, 51.491, 101.71)">
      <g transform="matrix(1, 0.07, -0.07, 1, -32.55, 184.7)" filter="url(#Rectangle_153)">
        <g id="Rectangle_153-2" data-name="Rectangle 153" transform="matrix(1, -0.07, 0.07, 1, 48.62, 27.97)" stroke="#fff" stroke-width="5" fill="url(#radial-gradient)">
          <rect width="115" height="161" rx="15" stroke="none"/>
          <rect x="2.5" y="2.5" width="110" height="156" rx="12.5" fill="none"/>
        </g>
      </g>
      <g id="Mask_Group_27" data-name="Mask Group 27" transform="translate(13.995 216.002)" opacity="0.25" clip-path="url(#clip-path)">
        <g id="Repeat_Grid_11" data-name="Repeat Grid 11" transform="translate(1 3.968)" clip-path="url(#clip-path-2)">
          <g transform="translate(-0.358 -216.126)">
            <path id="Path_718" data-name="Path 718" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -216.126)">
            <path id="Path_719" data-name="Path 719" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -216.126)">
            <path id="Path_720" data-name="Path 720" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -216.126)">
            <path id="Path_721" data-name="Path 721" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -216.126)">
            <path id="Path_722" data-name="Path 722" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -216.126)">
            <path id="Path_723" data-name="Path 723" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -216.126)">
            <path id="Path_724" data-name="Path 724" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -188.126)">
            <path id="Path_725" data-name="Path 725" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -188.126)">
            <path id="Path_726" data-name="Path 726" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -188.126)">
            <path id="Path_727" data-name="Path 727" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -188.126)">
            <path id="Path_728" data-name="Path 728" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -188.126)">
            <path id="Path_729" data-name="Path 729" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -188.126)">
            <path id="Path_730" data-name="Path 730" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -188.126)">
            <path id="Path_731" data-name="Path 731" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -160.126)">
            <path id="Path_732" data-name="Path 732" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -160.126)">
            <path id="Path_733" data-name="Path 733" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -160.126)">
            <path id="Path_734" data-name="Path 734" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -160.126)">
            <path id="Path_735" data-name="Path 735" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -160.126)">
            <path id="Path_736" data-name="Path 736" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -160.126)">
            <path id="Path_737" data-name="Path 737" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -160.126)">
            <path id="Path_738" data-name="Path 738" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -132.126)">
            <path id="Path_739" data-name="Path 739" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -132.126)">
            <path id="Path_740" data-name="Path 740" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -132.126)">
            <path id="Path_741" data-name="Path 741" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -132.126)">
            <path id="Path_742" data-name="Path 742" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -132.126)">
            <path id="Path_743" data-name="Path 743" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -132.126)">
            <path id="Path_744" data-name="Path 744" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -132.126)">
            <path id="Path_745" data-name="Path 745" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -104.126)">
            <path id="Path_746" data-name="Path 746" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -104.126)">
            <path id="Path_747" data-name="Path 747" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -104.126)">
            <path id="Path_748" data-name="Path 748" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -104.126)">
            <path id="Path_749" data-name="Path 749" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -104.126)">
            <path id="Path_750" data-name="Path 750" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -104.126)">
            <path id="Path_751" data-name="Path 751" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -104.126)">
            <path id="Path_752" data-name="Path 752" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -76.126)">
            <path id="Path_753" data-name="Path 753" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -76.126)">
            <path id="Path_754" data-name="Path 754" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -76.126)">
            <path id="Path_755" data-name="Path 755" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -76.126)">
            <path id="Path_756" data-name="Path 756" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -76.126)">
            <path id="Path_757" data-name="Path 757" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -76.126)">
            <path id="Path_758" data-name="Path 758" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -76.126)">
            <path id="Path_759" data-name="Path 759" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
