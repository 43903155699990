import { Component } from '@angular/core';

@Component({
    selector: 'bigreviewcardspile',
    templateUrl: './bigreviewcardspile.component.html'
})

export class BigReviewCardsPile {

  constructor( ) {  }
}
