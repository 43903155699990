import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from "@angular/cdk/drag-drop";

import { FileUploadComponent } from '../pages/fileupload/fileupload.component';
import { ActivityCardComponent } from "../pages/activitycard/activitycard.component";
import { LittleActivityCardComponent } from "../pages/littleactivitycard/littleactivitycard.component";
import { BigActivityDoneDisabledButton } from "../svg/bigactivitydonedisabledbutton/bigactivitydonedisabledbutton.component";
import { BigActivityDoneButton } from "../svg/bigactivitydonebutton/bigactivitydonebutton.component";
import { BigActivityGetStartedButton } from "../svg/bigactivitygetstartedbutton/bigactivitygetstartedbutton.component";
import { BigActivityViewSubmissionButton } from "../svg/bigactivityviewsubmissionbutton/bigactivityviewsubmissionbutton.component";
import { LittleActivityViewSubmissionButton } from "../svg/littleactivityviewsubmissionbutton/littleactivityviewsubmissionbutton.component";
import { LittleActivityGetStartedButton } from "../svg/littleactivitygetstartedbutton/littleactivitygetstartedbutton.component";
import { BigActivityResubmitButton } from "../svg/bigactivityresubmitbutton/bigactivityresubmitbutton.component";
import { BigActivityResubmitDisabledButton } from "../svg/bigactivityresubmitdisabledbutton/bigactivityresubmitdisabledbutton.component";
import { BigActivitySaveButton } from "../svg/bigactivitysavebutton/bigactivitysavebutton.component";
import { BigActivitySubmittedButton } from "../svg/bigactivitysubmittedbutton/bigactivitysubmittedbutton.component";
import { BigBookmarkIconComponent } from "../svg/bigbookmarkicon/bigbookmarkicon.component";
import { BigHotSpotButton } from "../svg/bighotspotbutton/bighotspotbutton.component";
import { BigInfoIcon } from "../svg/biginfoicon/biginfoicon.component";
import { BigLearnedCardsPile } from "../svg/biglearnedcardspile/biglearnedcardspile.component";
import { BigReviewCardsPile } from "../svg/bigreviewcardspile/bigreviewcardspile.component";
import { BigSectionSubmitButton } from "../svg/bigsectionsubmitbutton/bigsectionsubmitbutton.component";
import { BigSectionSubmitDisabledButton } from "../svg/bigsectionsubmitdisabledbutton/bigsectionsubmitdisabledbutton.component";
import { BigTouchIcon } from "../svg/bigtouchicon/bigtouchicon.component";
import { LittleActivityDoneButton } from "../svg/littleactivitydonebutton/littleactivitydonebutton.component";
import { LittleActivityDoneDisabledButton } from "../svg/littleactivitydonedisabledbutton/littleactivitydonedisabledbutton.component";
import { LittleActivitySubmittedButton } from "../svg/littleactivitysubmittedbutton/littleactivitysubmittedbutton.component";
import { LittleSectionSubmitButton } from "../svg/littlesectionsubmitbutton/littlesectionsubmitbutton.component";
import { LittleSectionSubmitDisabledButton } from "../svg/littlesectionsubmitdisabledbutton/littlesectionsubmitdisabledbutton.component";
import { BigChartComponent } from "../activities/bigchart/bigchart.component";
import { BigChooseComponent } from "../activities/bigchoose/bigchoose.component";
import { BigCrosswordComponent } from "../activities/bigcrossword/bigcrossword.component";
import { BigFibComponent } from "../activities/bigfib/bigfib.component";
import { BigFingerDrawComponent } from "../activities/bigfingerdraw/bigfingerdraw.component";
import { BigFlashcardsComponent } from "../activities/bigflashcards/bigflashcards.component";
import { BigGroupWorkComponent } from "../activities/biggroupwork/biggroupwork.component";
import { BigHotSpotComponent } from "../activities/bighotspot/bighotspot.component";
import { BigiSpyComponent } from "../activities/bigispy/bigispy.component";
import { BigMutlipleChoiceComponent } from "../activities/bigmultiplechoice/bigmultiplechoice.component";
import { BigPhotoUploadComponent } from "../activities/bigphotoupload/bigphotoupload.component";
import { BigPuzzleComponent } from "../activities/bigpuzzle/bigpuzzle.component";
import { BigRespondComponent } from "../activities/bigrespond/bigrespond.component";
import { BigSpotTheDifferenceComponent } from "../activities/bigspotthedifference/bigspotthedifference.component";
import { BigTextComponent } from "../activities/bigtext/bigtext.component";
import { FilterPipe } from "../pipes/filter.pipe";
import { SafeHtmlPipe, SafeStylePipe } from "../pipes/sanitizer.pipe";
import { DrawingCanvasComponent } from "../components/drawingcanvas.component";
import { BigDragAndDropTimelineComponent } from "../activities/bigdraganddroptimeline/bigdraganddroptimeline.component";
import { BigDragAndDropFIBComponent } from "../activities/bigdraganddropfib/bigdraganddropfib.component";
import { BigMatchingComponent } from "../activities/bigmatching/bigmatching.component";
import { BigWordSearchComponent } from "../activities/bigwordsearch/bigwordsearch.component";


import { LittleChartComponent } from "../activities/littlechart/littlechart.component";
import { LittleChooseComponent } from "../activities/littlechoose/littlechoose.component";
import { LittleCrosswordComponent } from "../activities/littlecrossword/littlecrossword.component";
import { LittleFibComponent } from "../activities/littlefib/littlefib.component";
import { LittleFingerDrawComponent } from "../activities/littlefingerdraw/littlefingerdraw.component";
import { LittleFlashcardsComponent } from "../activities/littleflashcards/littleflashcards.component";
import { LittleGroupWorkComponent } from "../activities/littlegroupwork/littlegroupwork.component";
import { LittleHotSpotComponent } from "../activities/littlehotspot/littlehotspot.component";
import { LittleiSpyComponent } from "../activities/littleispy/littleispy.component";
import { LittleMutlipleChoiceComponent } from "../activities/littlemultiplechoice/littlemultiplechoice.component";
import { LittlePhotoUploadComponent } from "../activities/littlephotoupload/littlephotoupload.component";
import { LittlePuzzleComponent } from "../activities/littlepuzzle/littlepuzzle.component";
import { LittleRespondComponent } from "../activities/littlerespond/littlerespond.component";
import { LittleSpotTheDifferenceComponent } from "../activities/littlespotthedifference/littlespotthedifference.component";
import { LittleTextComponent } from "../activities/littletext/littletext.component";
import { LittleDragAndDropTimelineComponent } from "../activities/littledraganddroptimeline/littledraganddroptimeline.component";
import { LittleMatchingComponent } from "../activities/littlematching/littlematching.component";
import { LittleWordSearchComponent } from "../activities/littlewordsearch/littlewordsearch.component";

import { ShootAndSortComponent } from "../activities/shootandsort/shootandsort.component";
import { DrivingGameComponent } from "../activities/drivinggame/drivinggame.component";


import { FilePondModule, registerPlugin } from 'ngx-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { LittleDragAndDropFIBComponent } from '../activities/littledraganddropfib/littledraganddropfib.component';
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImagePreview);

@NgModule({
  declarations: [
    FileUploadComponent,
    ActivityCardComponent,
    LittleActivityCardComponent,
    BigActivityDoneButton,
    BigActivityDoneDisabledButton,
    BigActivityGetStartedButton,
    LittleActivityGetStartedButton,
    BigActivityResubmitButton,
    BigActivityResubmitDisabledButton,
    BigActivitySaveButton,
    BigActivitySubmittedButton,
    BigActivityViewSubmissionButton,
    BigBookmarkIconComponent,
    BigHotSpotButton,
    BigInfoIcon,
    BigLearnedCardsPile,
    BigReviewCardsPile,
    BigSectionSubmitButton,
    BigSectionSubmitDisabledButton,
    BigTouchIcon,
    LittleActivityDoneButton,
    LittleActivityDoneDisabledButton,
    LittleActivitySubmittedButton,
    LittleSectionSubmitButton,
    LittleSectionSubmitDisabledButton,
    BigChartComponent,
    BigChooseComponent,
    BigCrosswordComponent,
    BigFibComponent,
    BigFingerDrawComponent,
    BigFlashcardsComponent,
    BigGroupWorkComponent,
    BigHotSpotComponent,
    BigiSpyComponent,
    BigMutlipleChoiceComponent,
    BigPhotoUploadComponent,
    BigPuzzleComponent,
    BigRespondComponent,
    BigSpotTheDifferenceComponent,
    BigTextComponent,
    LittleTextComponent,
    LittleCrosswordComponent,
    FilterPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    DrawingCanvasComponent,
    BigDragAndDropTimelineComponent,
    BigMatchingComponent,
    BigWordSearchComponent,
    LittleChartComponent,
    LittleChooseComponent,
    LittleFibComponent,
    LittleFingerDrawComponent,
    LittleFlashcardsComponent,
    LittleGroupWorkComponent,
    LittleHotSpotComponent,
    LittleiSpyComponent,
    LittleMutlipleChoiceComponent,
    LittlePhotoUploadComponent,
    LittlePuzzleComponent,
    LittleRespondComponent,
    LittleSpotTheDifferenceComponent,
    LittleDragAndDropTimelineComponent,
    LittleDragAndDropFIBComponent,
    LittleMatchingComponent,
    LittleWordSearchComponent,
    BigDragAndDropFIBComponent,
    ShootAndSortComponent,
    DrivingGameComponent,
    LittleActivityViewSubmissionButton
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FilePondModule,
    DragDropModule
  ],
  exports: [
    FileUploadComponent,
    ActivityCardComponent,
    LittleActivityCardComponent,
    BigActivityDoneButton,
    BigActivityDoneDisabledButton,
    BigActivityGetStartedButton,
    LittleActivityGetStartedButton,
    BigActivityResubmitButton,
    BigActivityResubmitDisabledButton,
    BigActivitySaveButton,
    BigActivitySubmittedButton,
    BigActivityViewSubmissionButton,
    BigBookmarkIconComponent,
    BigHotSpotButton,
    BigInfoIcon,
    BigLearnedCardsPile,
    BigReviewCardsPile,
    BigSectionSubmitButton,
    BigSectionSubmitDisabledButton,
    BigTouchIcon,
    LittleActivityDoneButton,
    LittleActivityDoneDisabledButton,
    LittleActivitySubmittedButton,
    LittleSectionSubmitButton,
    LittleSectionSubmitDisabledButton,
    BigChartComponent,
    BigChooseComponent,
    BigCrosswordComponent,
    BigFibComponent,
    BigFingerDrawComponent,
    BigFlashcardsComponent,
    BigGroupWorkComponent,
    BigHotSpotComponent,
    BigiSpyComponent,
    BigMutlipleChoiceComponent,
    BigPhotoUploadComponent,
    BigPuzzleComponent,
    BigRespondComponent,
    BigSpotTheDifferenceComponent,
    BigTextComponent,
    LittleTextComponent,
    LittleCrosswordComponent,
    FilterPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    DrawingCanvasComponent,
    BigDragAndDropTimelineComponent,
    BigMatchingComponent,
    BigWordSearchComponent,
    LittleChartComponent,
    LittleChooseComponent,
    LittleFibComponent,
    LittleFingerDrawComponent,
    LittleFlashcardsComponent,
    LittleGroupWorkComponent,
    LittleHotSpotComponent,
    LittleiSpyComponent,
    LittleMutlipleChoiceComponent,
    LittlePhotoUploadComponent,
    LittlePuzzleComponent,
    LittleRespondComponent,
    LittleSpotTheDifferenceComponent,
    LittleDragAndDropTimelineComponent,
    LittleDragAndDropFIBComponent,
    LittleMatchingComponent,
    LittleWordSearchComponent,
    BigDragAndDropFIBComponent,
    ShootAndSortComponent,
    DrivingGameComponent,
    LittleActivityViewSubmissionButton
  ]
})
export class SharedModule {}
