<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>
    
  <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let e of timeline_elements; let i = index" cdkDrag>
          <div *ngIf="activity.data.resource_type == 'Text'">
              {{ e.text }}
          </div>
          <div *ngIf="activity.data.resource_type == 'Image'">
            <div *ngIf="e.image" class="text-center">
                <img [src]="e.image.file" />
            </div>
          </div>
      </div>
  </div>
  <div class="text-center save-activity-button">
      <bigactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
      <bigactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
      <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div>