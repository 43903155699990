import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iTextActivity } from '../../models/textactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';

declare var $: any;

@Component({
    selector: 'bigtext',
    templateUrl: './bigtext.component.html',
    styleUrls: ['./bigtext.component.scss'],
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class BigTextComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iTextActivity;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  dict: any = {};
  directions: string;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService ) { }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];

      if (this.activity && this.activity.data && this.activity.data.popup_vocab && this.activity.data.popup_vocab.length > 0){
        this.activity.data.popup_vocab.forEach(p => {
          this.dict[p['popup_word']] = p['popup_definition'];
        })

        this.activity.data.text = this.sharedService.addPopups(this.activity.data.text, this.dict);
        
        setTimeout(() => {
          $(function () {
            $('[data-toggle="tooltip"]').tooltip()
          })
        }, 1000);
      }

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data['directions'] && this.activity.data['directions'] !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  submitActivity(show_success: boolean){
    this.sharedService.submitActivityResponse(this.activity.id, {completed: true}, show_success);
  }

  resubmit(){
    this.resubmitResponse.emit({completed: true});
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
