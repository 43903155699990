import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../auth/auth.service';
import { iStudentNavActivities } from '../../models/studentactivities.interface';
import { iStudentSection } from '../../models/studentsections.interface';
import { StudentSectionsService } from '../../services/StudentSections.service';

import { SharedService } from '../../shared/services/shared.service';

import { LittleChartComponent } from "../../activities/littlechart/littlechart.component";
import { LittleChooseComponent } from "../../activities/littlechoose/littlechoose.component";
import { LittleCrosswordComponent } from "../../activities/littlecrossword/littlecrossword.component";
import { LittleFibComponent } from "../../activities/littlefib/littlefib.component";
import { LittleFingerDrawComponent } from "../../activities/littlefingerdraw/littlefingerdraw.component";
import { LittleFlashcardsComponent } from "../../activities/littleflashcards/littleflashcards.component";
import { LittleGroupWorkComponent } from "../../activities/littlegroupwork/littlegroupwork.component";
import { LittleHotSpotComponent } from "../../activities/littlehotspot/littlehotspot.component";
import { LittleiSpyComponent } from "../../activities/littleispy/littleispy.component";
import { LittleMutlipleChoiceComponent } from "../../activities/littlemultiplechoice/littlemultiplechoice.component";
import { LittlePhotoUploadComponent } from "../../activities/littlephotoupload/littlephotoupload.component";
import { LittlePuzzleComponent } from "../../activities/littlepuzzle/littlepuzzle.component";
import { LittleRespondComponent } from "../../activities/littlerespond/littlerespond.component";
import { LittleSpotTheDifferenceComponent } from "../../activities/littlespotthedifference/littlespotthedifference.component";
import { LittleTextComponent } from "../../activities/littletext/littletext.component";
import { LittleDragAndDropTimelineComponent } from "../../activities/littledraganddroptimeline/littledraganddroptimeline.component";
import { LittleDragAndDropFIBComponent } from "../../activities/littledraganddropfib/littledraganddropfib.component";
import { LittleMatchingComponent } from "../../activities/littlematching/littlematching.component";
import { LittleWordSearchComponent } from "../../activities/littlewordsearch/littlewordsearch.component";
import { ShootAndSortComponent } from "../../activities/shootandsort/shootandsort.component";
import { DrivingGameComponent } from "../../activities/drivinggame/drivinggame.component";

@Component({
    selector: 'littleactivitycard',
    templateUrl: './littleactivitycard.component.html',
    providers: [ 
      StudentSectionsService
    ]
})

export class LittleActivityCardComponent implements OnInit, OnDestroy {
  @ViewChild (LittleChartComponent) private littlechartcomponent: LittleChartComponent;
  @ViewChild (LittleChooseComponent) private littlechoosecomponent: LittleChooseComponent;
  @ViewChild (LittleCrosswordComponent) private littlecrosswordcomponent: LittleCrosswordComponent;
  @ViewChild (LittleFibComponent) private littlefibcomponent: LittleFibComponent;
  @ViewChild (LittleFingerDrawComponent) private littlefingerdrawcomponent: LittleFingerDrawComponent;
  @ViewChild (LittleFlashcardsComponent) private littleflashcardscomponent: LittleFlashcardsComponent;
  @ViewChild (LittleGroupWorkComponent) private littlegroupworkcomponent: LittleGroupWorkComponent;
  @ViewChild (LittleHotSpotComponent) private littlehotspotcomponent: LittleHotSpotComponent;
  @ViewChild (LittleiSpyComponent) private littleispycomponent: LittleiSpyComponent;
  @ViewChild (LittleMutlipleChoiceComponent) private littlemultiplechoicecomponent: LittleMutlipleChoiceComponent;
  @ViewChild (LittlePhotoUploadComponent) private littlephotouploadcomponent: LittlePhotoUploadComponent;
  @ViewChild (LittlePuzzleComponent) private littlepuzzlecomponent: LittlePuzzleComponent;
  @ViewChild (LittleRespondComponent) private littlerespondcomponent: LittleRespondComponent;
  @ViewChild (LittleSpotTheDifferenceComponent) private littlespotthedifferencecomponent: LittleSpotTheDifferenceComponent;
  @ViewChild (LittleTextComponent) private littletextcomponent: LittleTextComponent;
  @ViewChild (LittleDragAndDropTimelineComponent) private littledraganddroptimelinecomponent: LittleDragAndDropTimelineComponent;
  @ViewChild (LittleDragAndDropFIBComponent) private littledraganddropfibcomponent: LittleDragAndDropFIBComponent;
  @ViewChild (LittleMatchingComponent) private littlematchingcomponent: LittleMatchingComponent;
  @ViewChild (LittleWordSearchComponent) private littlewordsearchcomponent: LittleWordSearchComponent;
  @ViewChild (ShootAndSortComponent) private shootandsortcomponent: ShootAndSortComponent;
  @ViewChild (DrivingGameComponent) private drivinggamecomponent: DrivingGameComponent;

  @Input() activity: iStudentNavActivities;
  @Input() index: string;
  @Output() resubmitResponse = new EventEmitter();
  selected_section: iStudentSection;
  ssservice: any;
  disabled_modal: string = '';

  constructor(
    private sharedService: SharedService,
    private authService: AuthService,
    private ssServ: StudentSectionsService,
    private sanitizer: DomSanitizer
  ) {  }

  ngOnInit() {
    this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
      this.selected_section = section['data'];

      if (this.activity.locked && this.activity.completed && 
         (this.activity.activity_type == 'Crossword Puzzle' ||
          this.activity.activity_type == 'Spot the Difference' ||
          this.activity.activity_type == 'iSpy' ||
          this.activity.activity_type == 'Hot Spot' ||
          this.activity.activity_type == 'Group Activity')){
        this.disabled_modal = "disabled-modal";
      } else {
        this.disabled_modal = '';
      }
    }) 
  }

  togglecaret(i: string){
    let test = document.querySelector('#activityCaret' + i);
    var caret = test.querySelector('.card-header-caret-dropdown');
    caret.classList.toggle('active');
  }

  storeDataForResubmit(data: any) {
    this.resubmitResponse.emit(data);
  }

  getResubmitResponse(activity: iStudentNavActivities){
    switch(activity.activity_type) { 
      case 'Text': { 
         this.littletextcomponent.resubmit(); 
         break; 
      } 
      case 'Respond': { 
        this.littlerespondcomponent.resubmit(); 
         break; 
      } 
      case 'Hot Spot': { 
        this.littlehotspotcomponent.resubmit(); 
         break; 
      } 
      case 'Multiple Choice': { 
        this.littlemultiplechoicecomponent.resubmit(); 
         break; 
      } 
      case 'Photo Upload': { 
        this.littlephotouploadcomponent.resubmit(); 
         break; 
      } 
      case 'Choose': { 
        this.littlechoosecomponent.resubmit(); 
         break; 
      } 
      case 'iSpy': { 
        this.littleispycomponent.resubmit(); 
         break; 
      } 
      case 'Spot the Difference': { 
        this.littlespotthedifferencecomponent.resubmit(); 
         break; 
      } 
      case 'Fill in the blank': { 
        this.littlefibcomponent.resubmit(); 
         break; 
      } 
      case 'Group Activity': { 
        this.littlegroupworkcomponent.resubmit(); 
         break; 
      } 
      case 'Crossword Puzzle': { 
        this.littlecrosswordcomponent.resubmit(); 
         break; 
      } 
      case 'Finger Draw': { 
        this.littlefingerdrawcomponent.resubmit(); 
         break; 
      } 
      case 'Chart': { 
        this.littlechartcomponent.resubmit(); 
         break; 
      } 
      case 'Puzzle': { 
        this.littlepuzzlecomponent.resubmit(); 
         break; 
      } 
      case 'Flash Cards': { 
        this.littleflashcardscomponent.resubmit(); 
         break; 
      } 
      case 'Drag and Drop - Timeline': { 
        this.littledraganddroptimelinecomponent.resubmit(); 
         break; 
      } 
      case 'Drag and Drop FIB': { 
        this.littledraganddropfibcomponent.resubmit(); 
         break; 
      } 
      case 'Matching': { 
        this.littlematchingcomponent.resubmit(); 
         break; 
      } 
      case 'Word Search': { 
        this.littlewordsearchcomponent.resubmit(); 
         break; 
      } 
      case 'Shoot and Sort': { 
        this.shootandsortcomponent.resubmit(); 
         break; 
      }   
      case 'Driving Game': { 
        this.drivinggamecomponent.resubmit(); 
         break; 
      }  
      default: { 
         //statements; 
         break; 
      } 
   } 
  }
  
  ngOnDestroy() {
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
