import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../auth/auth.service';
import { StudentSessionsService } from '../../services/StudentSessions.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { iLittleKidsSection } from '../../models/studentsections.interface';
import { StudentUnitsService } from '../../services/StudentUnits.service';
import { SharedService } from '../../shared/services/shared.service';
import { iStudentNavUnits } from '../../models/studentunits.interface';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [
    StudentSessionsService,
    StudentGradesService, 
    StudentUnitsService
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  email: FormControl;
  password: FormControl;
  user_token: string;
  user_name: string;
  user_id: string;
  little_kids: boolean;
  primary_color: string;
  secondary_color: string;
  primary_font: string;
  secondary_font: string;
  aservice: any;
  course_logo: string;
  curriculum_id: string;
  curriculum_type: string;
  little_sections: iLittleKidsSection[];
  sgservice: any;
  ssservice: any;

  constructor(
    private authService: AuthService,
    public router: Router,
    public aServ: StudentSessionsService,
    private toastr: ToastrService,
    private titleServ: Title,
    private ssServ: StudentUnitsService,
    private sharedService: SharedService,
    private sgServ: StudentGradesService
  ) { 
    this.titleServ.setTitle("SIFT Native Web App");
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.loginForm = new FormGroup({
      email: this.email,
      password: this.password
    });
  }

  createFormControls() {
    this.email = new FormControl('', Validators.required);
    this.password = new FormControl('', Validators.required);
  }

  createForm() {
    this.loginForm = new FormGroup({
      email: this.email,
      password: this.password
    });
  }

  loginUser() {
    this.authService.login(
      this.user_token, 
      this.user_name,
      this.user_id,
      this.little_kids,
      this.primary_color,
      this.secondary_color,
      this.primary_font,
      this.secondary_font,
      this.course_logo,
      this.curriculum_id,
      this.curriculum_type);
    
      this.sgservice = this.sgServ.GetLittleKidsSections(this.authService.getCurriculumId()).subscribe(s => {
        this.little_sections = s['data']['sections'];
        
        let current_section: iLittleKidsSection = this.little_sections.find(s => {
          return !s.locked
        })
        
        this.ssservice = this.ssServ.GetStudentNavUnitDetails(this.authService.getId(), current_section.lesson.unit_id.toString()).subscribe((u: iStudentNavUnits) => {
          this.sharedService.setSelectedUnit(u['data']);
          
          localStorage.setItem("selectedUnit", JSON.stringify(u['data']));
          this.sharedService.setSelectedLessonId(current_section.lesson.id.toString());
          this.sharedService.setSelectedSectionId(current_section.id.toString());
          
          this.router.navigate(['/app/sectionactivities']);
          setTimeout(() => {
            this.sharedService.setCurrentPage('nav');
            this.sharedService.collapseNavbar(false);
          }, 500);
        })
      })
  }

  onSubmit() {
    if (this.loginForm.valid == true) {
      this.loginForm.value.email = this.loginForm.value.email.trim();

      this.aservice = this.aServ.AuthorizeStudent(this.loginForm.value).subscribe(resp => {
        console.log(resp['data']);
        
        this.user_token = resp['data'].token;
        localStorage.setItem('token', this.user_token);
        this.user_name = resp['data'].first_name + ' ' + resp['data'].last_name;
        this.user_id = resp['data'].id;
        this.little_kids = resp['data'].grade.little_kids;
        this.primary_color = resp['data'].grade.primary_color;
        this.secondary_color = resp['data'].grade.secondary_color;
        this.primary_font = resp['data'].grade.primary_font;
        this.secondary_font = resp['data'].grade.secondary_font;
        this.course_logo = resp['data'].course_logo.file;
        this.curriculum_id = resp['data'].curriculum_id;
        this.curriculum_type = resp['data'].curriculum_type.name;

        if (this.curriculum_id){
          this.loginUser();
          this.toastr.success('Login successful');
        } else {
          this.toastr.error('You are not enrolled in a classroom for the current academic year. Please contact your teacher or digital administrator to add you to a classroom.');
        }
      }, error => {
        this.toastr.error('Invalid username and/or password!')
      });
    }
  }

  ngOnDestroy(): void {
    if(this.aservice){ this.aservice.unsubscribe() };
    if(this.sgservice){ this.sgservice.unsubscribe(); };
    if(this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
