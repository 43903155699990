<!-- Start of sophiainstitutehelp Zendesk Widget script -->
<script>
  var runWidgetLogic = (function() {
     'use strict';
     var module = {};
     module.init = function(HCSearch, chat, contactForm) {
        if (zE) configureWidget(HCSearch, chat, contactForm);
        else {
           console.log("ERROR: No Web Widget running on the page.");
           return;
        }
     };
     function configureWidget(HCSearch, chat, contactForm) { // API configuration
        var widgets = { webWidget:{ launcher:{}, helpCenter:{}, chat:{}, contactForm:{} } }, ww = widgets.webWidget;
        ww.launcher.label = { '*': 'Have a Question?' }; // Web Widget button text
        ww.launcher.chatLabel = { '*': 'Chat' }; // Chat button text - When the Help Center Search option is disabled
        ww.helpCenter.chatButton = { '*': 'Chat with a person now' }; // Chat button text - when the Help Center Search option is enabled
        ww.helpCenter.title = { '*': 'BRAND Help Centre' }; // Web Widget header text
        if (!HCSearch) ww.helpCenter.suppress = true;
        if (!chat) ww.chat.suppress = true; else zopimConfig();
        if (!contactForm) ww.contactForm.suppress = true;
        window.zESettings = widgets;
     }
     return module;
  }());
  
  runWidgetLogic.init(false, false, true);
</script>
<!-- End of sophiainstitutehelp Zendesk Widget script -->

<section class="content">
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <div class="text-center" style="padding-top: 40px; padding-bottom: 40px;">
                <img class="img img-responsive" style="max-width: 278px;" src="../../../assets/img/logos/SIFT-logo.png" />
            </div>
        </div>
        <div class="col-md-3">
            <div class="text-center" style="margin-top: 70px;">
                <strong style="font-size: 18px; font-family: Arimo; color:#727272;">Questions or problems?</strong>
                <br>
                <a href="javascript:$zopim.livechat.window.show();">
                    <strong style="font-size: 18px; font-family: Arimo; color:#1799A9;">Live chat with Sophia staff</strong>
                </a>
            </div>
        </div>
    </div>
    <div style="background-color:#faf9f4;">
        <div class="container" data-layout="container">
            <div class="order-form-header">
                <span *ngIf="current_form == 'institute'">Institute Information</span>
                <span *ngIf="current_form == 'product'">Product and Payment Information</span>
            </div>
            <div class="row flex-center  py-6">
                <div class="col-md-8">
                    <!-- Institute Card -->
                    <div [hidden]="current_form !== 'institute'" class="card" style="box-shadow: 0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07); border-radius: 6px !important;">
                        <div class="card-body p-4 p-sm-5">
                            <div class="row stepper">
                                <div class="col-md-6">
                                    <div class="active-dot" style="cursor: pointer;" (click)="proceedToInstitute();">1</div> 
                                    <span class="step-title" style="cursor: pointer;" (click)="proceedToInstitute();">Institute information</span>
                                </div>
                                <div class="col-md-6">
                                    <div class="inactive-dot" style="cursor: pointer;" (click)="proceedToProduct();">2</div>
                                    <span class="step-title" style="cursor: pointer;" (click)="proceedToProduct();">Product Information</span>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Select Order Type</label>
                                        <br>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" id="newRadio" type="radio" name="newRadio" [checked]="purchase_type == 0" (change)="updatePurchaseType(0)">
                                            <label class="custom-control-label radio-label" for="newRadio">New Purchase</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" id="renewRadio" type="radio" name="renewRadio" [checked]="purchase_type == 1" (change)="updatePurchaseType(1)">
                                            <label class="custom-control-label radio-label" for="renewRadio">Renewal</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" id="addOnRadio" type="radio" name="addOnRadio" [checked]="purchase_type == 2" (change)="updatePurchaseType(2)">
                                            <label class="custom-control-label radio-label" for="addOnRadio">Is this an additional order for School Year 22/23?</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row text-left justify-content-between align-items-center mb-2" style="margin-top: 30px;">
                                <div class="col-auto">
                                    <h5>Institute Information</h5>
                                </div>
                            </div>
                            <form [formGroup]="orderForm" novalidate >
                                <div class="form-group">
                                    <Label>Select Institute Type</Label>
                                    <div class="select" style="position:relative;">
                                        <select class="form-control" id="institute_type_id" name="institute_id" formControlName="institute_type" required>
                                            <option [value]="it.id" *ngFor="let it of institute_types">{{it.name}}</option>
                                        </select>
                                        <i class="form-group__bar"></i>
                                        <i style="position:absolute; right: 0; margin-top: -26px; margin-right: 10px;" class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <Label>Select Diocese</Label>
                                    <div style="position:relative; padding: 0px !important;">
                                        <ng-select2
                                            [data]="diocesesData"
                                            [placeholder]="'Select a diocese...'"
                                            width="100%"
                                            name="diocese" 
                                            formControlName="diocese"
                                            (valueChanged)="getInstitutesByDiocese()">		
                                        </ng-select2>
                                        <i class="form-group__bar"></i>
                                        <i style="position:absolute; right: 0; margin-top: 10px; margin-right: 10px;" class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                                <div *ngIf="purchase_type !== 0" class="form-group">
                                    <Label>Select Institute</Label>
                                    <div style="position:relative; padding: 0px !important;">
                                        <ng-select2
                                            [data]="instituteData"
                                            [placeholder]="'Select an institute...'"
                                            width="100%"
                                            name="institute" 
                                            [(ngModel)]="existing_institute_id"
                                            [value]="existing_institute_id"
                                            [ngModelOptions]="{standalone: true}"
                                            (valueChanged)="getInstituteData()">		
                                        </ng-select2>
                                        <i class="form-group__bar"></i>
                                        <i style="position:absolute; right: 0; margin-top: 10px; margin-right: 10px;" class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                                <div *ngIf="purchase_type == 0" class="form-group">
                                    <input class="form-control" placeholder="Institute Name" formControlName="institute_name" />
                                </div>
                                <div *ngIf="purchase_type == 0" class="form-group">
                                    <input class="form-control" placeholder="Phone" formControlName="phone" />
                                </div>
                                <div *ngIf="purchase_type == 0" class="form-group">
                                    <input class="form-control" placeholder="Website" formControlName="website" />
                                </div>
                                <div *ngIf="purchase_type == 0" class="form-group">
                                    <!-- <input 
                                        class="form-control" 
                                        placeholder="Address 1" 
                                        formControlName="i_address_1" 
                                        (ngModelChange)="onChange($event)"
                                        autocomplete="smartystreets" /> -->
                                    <input  class="form-control"  placeholder="Address 1"  formControlName="i_address_1" />

                                    <div *ngFor="let address of addressOptions" [value]="address.text" (click)="setAddress(address)" class="address_result_list">
                                        {{ address.text }}
                                    </div>
                                </div>
                                <div *ngIf="purchase_type == 0" class="form-group">
                                    <input class="form-control" placeholder="Address 2" formControlName="i_address_2" />
                                </div>
                                <div *ngIf="purchase_type == 0" class="form-row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input class="form-control" placeholder="City" formControlName="i_city" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input class="form-control" placeholder="State" formControlName="i_state" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input class="form-control" placeholder="Zip" formControlName="i_zip" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input class="form-control" placeholder="Contact Name" formControlName="contact_name" />
                                </div>

                                <div class="row text-left justify-content-between align-items-center mb-2" style="margin-top: 50px;">
                                    <div class="col-auto">
                                        <h5>Digital Administrator Information</h5>
                                    </div>
                                </div>
                                <div class="row" style="margin-bottom: 20px;">
                                    <div class="col-md-12">
                                        <em>The Digital Administrator is the manager of the digital institute and upon logging into. the system will be able to set up classes, set up teachers, and import student information. Our support team will be contacting the digital administrator to help with any questions.</em>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input class="form-control" placeholder="First name" formControlName="first_name" />
                                </div>
                                <div class="form-group">
                                    <input class="form-control" placeholder="Last name" formControlName="last_name" />
                                </div>
                                <div class="form-group">
                                    <input class="form-control" placeholder="Phone Number" formControlName="admin_phone" />
                                </div>
                                <div class="form-group">
                                    <input class="form-control" placeholder="Email" formControlName="email" />
                                </div>
                                <div class="form-group">
                                    <input class="form-control" type="password" placeholder="Password" class="form-control" formControlName="password" />
                                </div>
                            </form>
                            
                            <div class="form-group"><button class="btn btn-primary btn-block mt-3" (click)="proceedToProduct()" [disabled]="!orderForm.valid">Proceed to Product Information</button></div>
                        </div>
                    </div>

                    <!-- Product Card -->
                    <div [hidden]="current_form !== 'product'" class="card" style="box-shadow: 0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07); border-radius: 6px !important;">
                        <div class="card-body p-4 p-sm-5">
                            <div class="row stepper">
                                <div class="col-md-6">
                                    <div class="inactive-dot" style="cursor: pointer;" (click)="proceedToInstitute();">1</div> 
                                    <span class="step-title" style="cursor: pointer;" (click)="proceedToInstitute();">Institute information</span>
                                </div>
                                <div class="col-md-6">
                                    <div class="active-dot" style="cursor: pointer;" (click)="proceedToProduct();">2</div>
                                    <span class="step-title" style="cursor: pointer;" (click)="proceedToProduct();">Product Information</span>
                                </div>
                            </div>
                            <div class="row text-left justify-content-between align-items-center mb-2">
                                <div class="col-auto">
                                    <h5>Product Selection</h5>
                                </div>
                            </div>

                            <div class="form-row" style="margin-top: 20px;">
                                <label style="margin-left: 10px;">Select the product types that you’d like to purchase</label>
                            </div>
                            <div class="form-row" style="margin-bottom: 20px;">
                                <div class="form-group form-check" style="margin-left: 10px;" *ngFor="let p of category_status">
                                    <input class="form-check-input" type="checkbox" (change)="filterCategories($event, p.id)" name="product_categories" id="product_categories">
                                    <label class="form-check-label" for="invoice">{{ p.name }}</label>
                                </div>
                            </div>

                            <form [formGroup]="productForm">
                                <div formArrayName="itemRows">
                                    <div *ngFor="let itemrow of getControls(); let i = index" [formGroupName]="i" style="border: 1px solid #d8e2ef; border-radius: .25rem; margin-bottom: 20px;">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="form-row">
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <label for="institute">Filter by Grade</label>
                                                            <div class="select">
                                                              <select class="form-control" id="grade_filter" name="grade_filter" (change)="filter_by_grade($event, i)" required>
                                                                <option [value]="g.id" *ngFor="let g of grades">{{g.name}}</option>
                                                              </select>
                                                              <i class="form-group__bar"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="form-group">
                                                            <Label>Select Product</Label>
                                                            <div style="position:relative; padding: 0px !important;">
                                                                <!-- <ng-select2
                                                                    [data]="filteredProductData"
                                                                    [placeholder]="'Select a product...'"
                                                                    width="100%"
                                                                    name="product" 
                                                                    formControlName="product"
                                                                    (valueChanged)="calculateSubtotal($event, i); validateForm();">		
                                                                </ng-select2> -->
                                                                <ng-select2
                                                                    [data]="filteredProductsData[i]"
                                                                    [placeholder]="'Select a product...'"
                                                                    width="100%"
                                                                    name="product" 
                                                                    formControlName="product"
                                                                    (valueChanged)="calculateSubtotal($event, i); validateForm();">		
                                                                </ng-select2>
                                                                <i class="form-group__bar"></i>
                                                                <i style="position:absolute; right: 0; margin-top: 10px; margin-right: 10px;" class="fas fa-chevron-down"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <Label>Quantity</Label>
                                                        <div class="form-group">
                                                            <input class="form-control" placeholder="" formControlName="quantity" (keyup)="calculateSubtotal(itemrow.value, i); validateForm();" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 text-right">
                                                        <Label>Subtotal</Label>
                                                        <div class="form-group">
                                                            <label>${{ itemrow.value["subtotal"] }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="productForm.controls.itemRows.controls.length > 1" class="text-right"><button type="button" (click)="deleteRow(i)" class="btn btn-link text-danger">Remove</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="discount" class="row text-right" style="padding-right: 22px;">
                                    <label style="color: red; opacity:.8;">Discount: -${{ discount }}</label>
                                </div>
                                <div *ngIf="shipping_cost_label !== ''" class="row text-right" style="padding-right: 22px;">
                                    <label>Shipping: {{ shipping_cost_label }}</label>
                                </div>
                                <div class="row text-right" style="padding-right: 22px;">
                                    <label>Total</label>
                                    <label>${{ total }}</label>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <button type="button" (click)="addNewRow()" class="btn btn-link text-primary"><i class="far fa-plus-circle"></i> Add additional product</button>
                                    </div>
                                </div>
                            </form>

                            <div class="row text-left justify-content-between align-items-center mb-2" style="margin-top: 50px;">
                                <div class="col-auto">
                                    <h5>Billing Information</h5>
                                </div>
                            </div>
                            <div class="form-row" style="margin-top: 20px;">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input class="form-control" placeholder="First name" name="billing_first_name" id="billing_first_name" [(ngModel)]="billing_first_name" (keyup)="validateForm()" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input class="form-control" placeholder="Last name" name="billing_last_name" id="billing_last_name" [(ngModel)]="billing_last_name" (keyup)="validateForm()" />
                                    </div>
                                </div>
                            </div>
                            <div style="margin-bottom: 18px;">
                                <input class="form-control" placeholder="Phone" name="b_phone" [(ngModel)]="b_phone" (keyup)="validateForm()" />
                            </div>
                            <div style="margin-bottom: 18px;">
                                <input class="form-control" placeholder="Email" name="b_email" [(ngModel)]="b_email" (keyup)="validateForm()" />
                            </div>
                            <div style="margin-bottom: 18px;">
                                <!-- <input 
                                    class="form-control" 
                                    placeholder="Address 1" 
                                    [(ngModel)]="b_address_1" 
                                    (ngModelChange)="onBillingChange($event)"
                                    autocomplete="smartystreets"
                                    (keyup)="validateForm()" /> -->
                                <input class="form-control" placeholder="Address 1" [(ngModel)]="b_address_1" (keyup)="validateForm()" />

                                <div *ngFor="let address of billingAddressOptions" [value]="address.text" (click)="setBillingAddress(address)" class="address_result_list">
                                    {{ address.text }}
                                </div>
                            </div>
                            <div style="margin-bottom: 18px;">
                                <input class="form-control" placeholder="Address 2" name="b_address_2" [(ngModel)]="b_address_2" (keyup)="validateForm()" />
                            </div>
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div style="margin-bottom: 18px;">
                                        <input class="form-control" placeholder="City" name="b_city" [(ngModel)]="b_city" (keyup)="validateForm()" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div style="margin-bottom: 18px;">
                                        <input class="form-control" placeholder="State" name="b_state" [(ngModel)]="b_state" (keyup)="validateForm()" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div style="margin-bottom: 18px;">
                                        <input class="form-control" placeholder="Zip" name="b_zip" [(ngModel)]="b_zip" (keyup)="validateForm()" />
                                    </div>
                                </div>
                            </div>

                            <div class="row text-left justify-content-between align-items-center mb-2" style="margin-top: 50px;">
                                <div class="col-auto">
                                    <h5>Shipping Information</h5>
                                </div>
                                <div class="text-right" style="color: red;">
                                    <label>Please provide a street address as we are unable to ship to P.O. Boxes</label>
                                </div>
                            </div>
                            <div class="text-right">
                                <div class="form-group form-check" style="margin-left: 10px;">
                                    <input class="form-check-input" type="checkbox" (change)="sameAsBilling($event)" name="invoice" id="invoice" #invoice>
                                    <label class="form-check-label" for="invoice">Same as billing</label>
                                </div>
                            </div>
                            <div style="margin-bottom: 18px;">
                                <!-- <input 
                                    class="form-control" 
                                    placeholder="Address 1" 
                                    [(ngModel)]="b_address_1" 
                                    (ngModelChange)="onBillingChange($event)"
                                    autocomplete="smartystreets"
                                    (keyup)="validateForm()" /> -->
                                <input class="form-control" placeholder="Address 1" [(ngModel)]="s_address_1" (keyup)="validateForm()" />

                                <div *ngFor="let address of billingAddressOptions" [value]="address.text" (click)="setBillingAddress(address)" class="address_result_list">
                                    {{ address.text }}
                                </div>
                            </div>
                            <div style="margin-bottom: 18px;">
                                <input class="form-control" placeholder="Address 2" name="s_address_2" [(ngModel)]="s_address_2" (keyup)="validateForm()" />
                            </div>
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div style="margin-bottom: 18px;">
                                        <input class="form-control" placeholder="City" name="s_city" [(ngModel)]="s_city" (keyup)="validateForm()" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div style="margin-bottom: 18px;">
                                        <input class="form-control" placeholder="State" name="s_state" [(ngModel)]="s_state" (keyup)="validateForm()" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div style="margin-bottom: 18px;">
                                        <input class="form-control" placeholder="Zip" name="s_zip" [(ngModel)]="s_zip" (keyup)="validateForm()" />
                                    </div>
                                </div>
                            </div>

                            <div class="row text-left justify-content-between align-items-center mb-2" style="margin-top: 50px;">
                                <div class="col-auto">
                                    <h5>Payment Details</h5>
                                </div>
                            </div>
                    
                            <div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Select Payment Type</label>
                                            <br>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input class="custom-control-input" id="customRadioInline1" type="radio" name="customRadioInline1" [checked]="payment_type" (change)="updatePaymentType(1)">
                                                <label class="custom-control-label radio-label" for="customRadioInline1">Invoice</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input class="custom-control-input" id="customRadioInline2" type="radio" name="customRadioInline1" [checked]="!payment_type" (change)="updatePaymentType(0); showCard();">
                                                <label class="custom-control-label radio-label" for="customRadioInline2">Credit Card</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="payment_type == 0">
                                    <div class="text-right" style="color: red;">
                                        <label>American Express not accepted, at this time</label>
                                    </div>
                                    <div class='card-wrapper'></div>

                                    <form name="cc-form" id="cc-form">
                                        <div class="form-row">
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    <label>Card Number</label>
                                                    <input class="form-control" placeholder="**** **** **** ****" name="number" id="number" [(ngModel)]="number" (keyup)="validateForm()" />
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label>Expiration</label>
                                                    <input class="form-control" placeholder="mm/yy" name="expiry" id="expiry" [(ngModel)]="expiry" (keyup)="validateForm()" />
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label>CVV</label>
                                                    <input class="form-control" placeholder="***" name="cvc" id="cvc" [(ngModel)]="cvc" (keyup)="validateForm()" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div *ngIf="payment_type == 1">
                                    <div class="form-row">
                                        <div class="form-group form-check" style="margin-left: 10px;">
                                            <input class="form-check-input" type="checkbox" (change)="checkValue($event)" name="invoice" id="invoice" #invoice>
                                            <label class="form-check-label" for="invoice">Send me an invoice</label>
                                        </div>
                                    </div>
                                </div>

                                <re-captcha (resolved)="resolved($event)" siteKey="6Lc1is4aAAAAAMJs2pPFZ_lJk7E38vRvfWg_fTyQ"></re-captcha>

                                <div class="form-group"><button class="btn btn-primary btn-block mt-3" type="submit" name="submit" [disabled]="disabled" (click)="calculateShippingCost()">Calculate Shipping</button></div>
                                <div *ngIf="discount" class="row text-right" style="padding-right: 22px;">
                                    <label style="color: red; opacity:.8;">Discount: -${{ discount }}</label>
                                </div>
                                <div *ngIf="shipping_cost_label !== ''" class="row text-right" style="padding-right: 22px;">
                                    <label>Shipping: {{ shipping_cost_label }}</label>
                                </div>
                                <div class="row text-right" style="padding-right: 22px;">
                                    <label>Total</label>
                                    <label>${{ total }}</label>
                                </div>
                                <div class="form-group"><button class="btn btn-success btn-block mt-3" type="submit" name="submit" [disabled]="!enable_submit" (click)="onSubmit()">Place Order</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button  
        [hidden]="true"
        #OrderTypeModalButton
        href="#order-type-modal"
        id="OrderTypeModalButton" 
        data-toggle="modal">Open Modal</button>
</section>

<div class="modal fade" id="order-type-modal" tabindex="-1" role="dialog" aria-labelledby="order-type-modal-label" aria-hidden="true">
    <div class="modal-dialog mt-6 modal-xl sc-modal-border" role="document">
        <div class="modal-content border-0 sc-modal-content">
            <div class="modal-header px-5 text-white position-relative sc-modal-header">
              <div class="row" style="width: 100% !important;">
                <div class="col-md-12">
                  <h4 class="mb-0 font-secondary text-center" id="order-type-modal-label"><strong>NOTE:</strong></h4>
                </div>
              </div>
              <button class="btn-close position-absolute top-0 right-0 mt-2 mr-2" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body py-4 px-5 text-center">
              <div>
                If you already have bought any licenses for 22/23, and would now like to ADD licenses, please select "Is this an additional order for the School Year 22/23?"
              </div>
              <div style="margin-top: 40px;">
                  If you choose "New Purchase" or "Renewal", your 22/23 licenses will be removed.
              </div>
            </div>
        </div>
    </div>
</div>