<svg xmlns="http://www.w3.org/2000/svg" width="150" viewBox="0 0 256 50" style="cursor: pointer;">
  <defs>
    <style>
      .cls-secondary-color {
        fill: #ae529b;
      }

      .cls-get-started {
        fill: #fff;
        font-size: 22px !important;
        font-family: Arimo-Bold, Arimo;
        font-weight: 700;
      }
    </style>
  </defs>
  <g id="Group_249" data-name="Group 249" transform="translate(-3917.5 -20159)">
    <g id="Group_248" data-name="Group 248" transform="translate(3085 18234)">
      <path id="Path_150" data-name="Path 150" class="cls-secondary-color" d="M4,0H252a4,4,0,0,1,4,4V46a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(832.5 1925)"/>
      <text id="Get_started_" data-name="Get started!" class="cls-get-started" transform="translate(844 1938)"><tspan x="36.541" y="20">GET STARTED!</tspan></text>
    </g>
  </g>
</svg>



