import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'smartystreets-javascript-sdk';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class SmartyStreetsService {
    key: string = "44667530319748156";
    auth_id: string = "f93fe761-9605-2e16-1b48-aeeaaaa1b6c9";
    auth_token: string = "aKR3nTcUJoPq8r36BRsi";

    constructor(private http: HttpClient) {}

    StreetAddressLookup(Street: string, City: string, State: string): Observable<any> {
        return this.http.get('https://us-street.api.smartystreets.com/street-address?key=' + this.key + '&street=' + Street + '&city=' + City + '&state=' + State + '&candidates=10&match=invalid', httpOptions);
    } 

    ZipCodeLookup(City: string, State: string): Observable<any> {
        return this.http.post<any>('https://us-zipcode.api.smartystreets.com/lookup?auth-id=' + this.auth_id + '&auth-token=' + this.auth_token + '&city=' + City + '&state=' + State, httpOptions);
    }

    AutocompleteAddress(term: string): any {
        const SmartyStreetsCore = _.core;
        const websiteKey = this.key;
        const Lookup = _.usAutocomplete.Lookup;
        const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
        const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials);
        const client = clientBuilder.buildUsAutocompleteClient();
        const lookup = new Lookup(term);
        return client.send(lookup);
      }
}