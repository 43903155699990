import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SharedService } from "./shared/services/shared.service";
import { routing } from "./app.routing";
import { AuthService } from "./auth/auth.service";
import { TokenInterceptor } from "./auth/token.interceptor";
import { AuthGuard } from "./auth/auth.guard";
import { LoginModule } from "./pages/login/login.module";
import { ProductOrderFormModule } from "./pages/productorderform/productorderform.module";
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { BigUnitSelectModule } from "./navigation/bigunitselect/bigunitselect.module";
import { BigNotificationsModule } from "./navigation/bignotifications/bignotifications.module";
import { VerticalNavBarModule } from './navigation/verticalnavbar/verticalnavbar.module';
import { BigVertCollapsedModule } from './navigation/bigvertcollapsed/bigvertcollapsed.module';
import { LittleVertCollapsedModule } from './navigation/littlevertcollapsed/littlevertcollapsed.module';
import { BigVertExpandModule } from "./navigation/bigvertexpanded/bigvertexpanded.module";
import { LittleVertExpandedModule } from "./navigation/littlevertexpanded/littlevertexpanded.module";
import { UnitVocabModule } from "./navigation/unitvocab/unitvocab.module";
import { UnitNavModule } from "./navigation/unitnav/unitnav.module";
import { LittleUnitNavModule } from "./navigation/littleunitnav/littleunitnav.module";
import { LittleNotificationsModule } from "./navigation/littlenotifications/littlenotifications.module";
import { LayoutModule } from "../app/layout/layout.module";
import { LittleUnitSelectModule } from "./navigation/littleunitselect/littleunitselect.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    routing,
    LoginModule,
    ProductOrderFormModule,
    ToastrModule.forRoot(),
    BigUnitSelectModule,
    BigNotificationsModule,
    VerticalNavBarModule,
    LittleVertCollapsedModule,
    BigVertExpandModule,
    LittleVertExpandedModule,
    UnitVocabModule,
    UnitNavModule,
    LittleUnitNavModule,
    LittleNotificationsModule,
    BigVertCollapsedModule,
    PerfectScrollbarModule,
    LayoutModule,
    LittleUnitSelectModule
  ],
  exports: [
    PerfectScrollbarModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    SharedService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
