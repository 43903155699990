import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  forwardRef
} from '@angular/core';

import { environment } from '../../../environments/environment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fileupload',
  templateUrl: './fileupload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {
  @Output() uploadEvent = new EventEmitter<object>();
  @Output() handleAdd = new EventEmitter<object>();

  @ViewChild('myPond', { static: true }) myPond: any;
  pondFiles: any;

  constructor( ) { }

  pondOptions = {
    class: 'my-filepond',
    multiple: true,
    instantUpload: true,
    labelIdle:
      "Drag & Drop your picture or <span class='filepond--label-action'>Browse</span>",
    acceptedFileTypes: 'image/jpeg, image/png, application/pdf, video/mp4, text/csv',
    allowImagePreview: true,
    server: {
      process:(fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        this.handleAdd.emit(file);

        const formData = new FormData();
        formData.append(fieldName, file, file.name);

        const request = new XMLHttpRequest();
        request.open(
          'POST',
          environment.conn_string + 'api/activities/upload_file'
        );
        
        request.upload.onprogress = e => {
          progress(e.lengthComputable, e.loaded, e.total);
        };
        
        request.onload = e => {
          if (request.status >= 200 && request.status < 300) {
            const resp_json = JSON.parse(request.responseText);
            this.uploadEvent.emit(resp_json.data);

            this.propagateChange(resp_json.data);

            load(resp_json.data.id);
          } else {
            error('error');
          }
        };

        request.send(formData);
        
        return {
          abort: () => {
            request.abort();
            abort();
          }
        };
      }
    }
  };

  writeValue(value: any) { }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }
}
