// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  constructor(public router: Router) {}
  public getToken(): string {
    return localStorage.getItem('token');
  }
  public getName(): string {
    return localStorage.getItem('name');
  }
  public getId(): string {
    return localStorage.getItem('id');
  }
  public getLittleKid(): string {
    return localStorage.getItem('little_kid');
  }
  public getPrimaryColor(): string {
    return localStorage.getItem('primary_color');
  }
  public getSecondaryColor(): string {
    return localStorage.getItem('secondary_color');
  }
  public getPrimaryFont(): string {
    return localStorage.getItem('primary_font');
  }
  public getSecondaryFont(): string {
    return localStorage.getItem('secondary_font');
  }
  public getCourseLogo(): string {
    return localStorage.getItem('course_logo');
  }
  public getCurriculumId(): string {
    return localStorage.getItem('curriculum_id');
  }
  public getCurriculumType(): string {
    return localStorage.getItem('curriculum_type');
  }
  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting
    // whether or not the token is expired
    return token === null ? false : true;
  }

  public login(token, name, id, little_kid, primary_color, secondary_color, primary_font, secondary_font, course_logo, curriculum_id, curriculum_type) {
    localStorage.setItem('token', token);
    localStorage.setItem('name', name);
    localStorage.setItem('id', id);
    localStorage.setItem('little_kid', little_kid);
    localStorage.setItem('primary_color', primary_color);
    localStorage.setItem('secondary_color', secondary_color);
    localStorage.setItem('primary_font', primary_font);
    localStorage.setItem('secondary_font', secondary_font);
    localStorage.setItem('course_logo', course_logo);
    localStorage.setItem('curriculum_id', curriculum_id);
    localStorage.setItem('curriculum_type', curriculum_type);
  }

  public logOut() {
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
