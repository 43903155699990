import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { iFibActivity } from '../../models/fibactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service'
import { iActivityResponse } from '../../models/studentactivities.interface';

@Component({
    selector: 'bigfib',
    templateUrl: './bigfib.component.html',
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class BigFibComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iFibActivity;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  response: string[];
  directions: string;
  updated_string: string;
  num_blanks: number;
  blanks: any[];

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService) {
      this.response = [];
      this.blanks = [];
    }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];
      console.log(this.activity);
      

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data.directions && this.activity.data.directions !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data.directions;
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })

      this.num_blanks = (this.activity.data.text.match(/\(\(____\)\)/g)).length;
      this.updated_string = this.activity.data.text;
      for (let i = 0; i < this.num_blanks; i++){
        this.updated_string = this.updated_string.replace("((____))", '<input id="blank' + i + this.activity.id + '" class="form-control" type="text" style="width: 150px; display: inline-block; margin-bottom: 10px;">');
      }
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.activity && this.activity.response && this.activity.response.data){
          for (let i = 0; i < this.num_blanks; i++){
            let blank = <HTMLInputElement>document.getElementById('blank' + i + this.activity.id);
            blank.value = this.activity.response.data[i];
          }
        }
    }, 1000);
  }

  submitActivity(show_success: boolean){
    for (let i = 0; i < this.num_blanks; i++){
      let blank = <HTMLInputElement>document.getElementById('blank' + i + this.activity.id);
      this.response.push(blank.value);
    }
    
    this.sharedService.submitActivityResponse(this.activity.id, this.response, show_success);
  }

  resubmit(){
    for (let i = 0; i < this.num_blanks; i++){
      let blank = <HTMLInputElement>document.getElementById('blank' + i + this.activity.id);
      this.response.push(blank.value);
    }
    
    this.resubmitResponse.emit(this.response);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
