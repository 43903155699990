import { Component } from '@angular/core';

@Component({
    selector: 'littlesectionsubmitbutton',
    templateUrl: './littlesectionsubmitbutton.component.html'
})

export class LittleSectionSubmitButton {

  constructor( ) {  }
}
