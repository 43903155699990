<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26" style="cursor: pointer !important;">
  <defs>
    <style>
      .cls-1 {
        fill: none;
      }

      .cls-2 {
        clip-path: url(#clip-path);
      }

      .cls-3 {
        fill: #fff;
      }
    </style>
    <clipPath id="clip-path">
      <rect class="cls-1" width="26" height="26"/>
    </clipPath>
  </defs>
  <g id="Icon_Info" data-name="Icon / Info" class="cls-2">
    <path id="Path_138" data-name="Path 138" class="cls-3" d="M13,0A13,13,0,1,0,26,13,13,13,0,0,0,13,0Zm1.765,19.373A1.856,1.856,0,0,1,13,21.32a1.856,1.856,0,0,1-1.765-1.947V13A1.858,1.858,0,0,1,13,11.05,1.858,1.858,0,0,1,14.765,13Zm0-12.5a1.765,1.765,0,1,1-3.531,0V6.445a1.765,1.765,0,0,1,3.531,0Z"/>
  </g>
</svg>