import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
// import { SharedModule } from '../../shared/shared.module';
// import { TextMaskModule } from 'angular2-text-mask';
// import { Select2Module } from 'ng2-select2';
import { ReactiveFormsModule } from '@angular/forms';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { ModalModule } from 'ngx-bootstrap/modal';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { LoginComponent } from './login.component';

const HOME_ROUTE = [{ path: 'login', component: LoginComponent }];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    // SharedModule,
    FormsModule,
    // TextMaskModule,
    // Select2Module,
    // BsDropdownModule.forRoot(),
    // ModalModule,
    ReactiveFormsModule,
    RouterModule.forChild(HOME_ROUTE),
    // PerfectScrollbarModule
  ]
})

export class LoginModule {}
