import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iChartActivity } from '../../models/chartactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { SharedService } from '../../shared/services/shared.service';
import { AuthService } from '../../auth/auth.service';

export interface activity_response{
  answerId: number,
  questionId: number
}

@Component({
    selector: 'bigchart',
    templateUrl: './bigchart.component.html',
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class BigChartComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iChartActivity;
  saved_time: number;
  selected_section: iStudentSection;
  ssservice: any;
  response: activity_response[];
  private bsCol: string = "col-md-12";
  directions: string;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private sharedService: SharedService,
    private authService: AuthService ) {
      this.response = [];
     }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe(activity => {
      this.activity = activity['data'];

      if (this.activity.response){
        this.activity.data.rows = this.activity.response.data;
      }

      let num: number = 12 / this.activity.data.rows[0].row.length;
    
      if (num == 2.4){
        this.bsCol = "col-md-2";
      } else {
        this.bsCol = "col-md-" + num.toString();
      }
      
      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data.directions && this.activity.data.directions !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data.directions;
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  submitActivity(show_success: boolean){
    this.sharedService.submitActivityResponse(this.activity.id, this.activity.data.rows, show_success);
    
    this.sharedService.getSubmitActivitySuccess().subscribe(success => {
      if (success){
        this.saved_time = Date.now();
      }
    })
  }

  resubmit(){
    this.resubmitResponse.emit(this.activity.data.rows);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
