import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout.component";
import { AuthGuard } from "../auth/auth.guard";

const LAYOUT_ROUTES: Routes = [
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: "", redirectTo: "home", pathMatch: "full" },
      { path: "sectionactivities", loadChildren: () => import('../pages/sectionactivities/sectionactivities.module').then(m => m.SectionActivitiesModule) },
      { path: "home", loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule) },
      { path: "saintcards", loadChildren: () => import('../pages/saintcards/saintcards.module').then(m => m.SaintCardsModule)},
      { path: "unitlanding", loadChildren: () => import('../pages/unit_landing/unit_landing.module').then(m => m.UnitLandingModule)}
    ],
  },
];

@NgModule({
  imports: [ RouterModule.forChild(LAYOUT_ROUTES) ],
  exports: [ RouterModule ]
})

export class layoutrouting{}

// export const LayoutRouting = RouterModule.forChild(LAYOUT_ROUTES);
