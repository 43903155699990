import { Component } from '@angular/core';

@Component({
    selector: 'bigactivitysavebutton',
    templateUrl: './bigactivitysavebutton.component.html'
})

export class BigActivitySaveButton {

  constructor( ) {  }
}
