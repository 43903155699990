import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iUserCreate, iUserExisting } from '../models/user.interface';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'jagyw1xbTYRq15AJF2uP6k3z'
  })
};

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  CreateUser(newUser: iUserCreate): Observable<iUserCreate> {
    return this.http.post<iUserCreate>(environment.conn_string + 'api/users', newUser, httpOptions);
  }

  GetUserByEmail(Email: string, InstituteId: string) {
    return this.http.get(environment.conn_string + 'api/users/by_email/?email=' + Email + '&institute_id=' + InstituteId, httpOptions);
  }

  UpdateUser(User: iUserExisting): Observable<iUserCreate> {
    return this.http.put<iUserExisting>(environment.conn_string + 'api/users/' + User.id, User, httpOptions);
  }
}
