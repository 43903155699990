<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>
  
  <div id="collage" style="text-align: center;">
    <div id="playPanel" style="padding:5px;display:none;">
        <div style="display:inline-block; margin:auto; width:400px; vertical-align:top;">
            <ul id="sortable" class="sortable"></ul>
        </div>
    </div>
  </div>

  <div class="text-center save-activity-button">
    <!-- <bigactivitydonedisabledbutton *ngIf="!activity.locked && !isComplete"></bigactivitydonedisabledbutton> -->
    <bigactivitysavebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></bigactivitysavebutton>
    <bigactivitysavebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></bigactivitysavebutton>
    <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div>