import { Component } from '@angular/core';

@Component({
    selector: 'bigactivityresubmitbutton',
    templateUrl: './bigactivityresubmitbutton.component.html'
})

export class BigActivityResubmitButton {

  constructor( ) {  }
}
