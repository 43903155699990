import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SharedModule } from "../../shared/shared.module";

import { UnitNavComponent } from "./unitnav.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

const UNIT_NAV_ROUTE = [
    { path: '', component: UnitNavComponent }
];

@NgModule ({
    declarations: [
        UnitNavComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule.forChild(UNIT_NAV_ROUTE),
        PerfectScrollbarModule
    ],
    providers: [
      {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      }
    ],
    exports: [
        UnitNavComponent
    ]
})

export class UnitNavModule {  }