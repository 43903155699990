<svg xmlns="http://www.w3.org/2000/svg" width="190"  viewBox="0 0 322.529 50" style="cursor: pointer;">
  <defs>
    <style>
      .cls-secondary-color {
        fill: #ae529b;
      }

      .cls-get-started {
        fill: #fff;
        font-size: 22px !important;
        font-family: Arimo-Bold, Arimo;
        font-weight: 700;
      }
    </style>
  </defs>
  <g id="Group_252" data-name="Group 252" transform="translate(-3909.454 -20083)">
    <g id="Group_251" data-name="Group 251" transform="translate(3085 18158)">
      <path id="Path_150" data-name="Path 150" class="cls-secondary-color" d="M5.04,0h312.45c2.783,0,5.039,1.791,5.039,4V46c0,2.209-2.256,4-5.039,4H5.04C2.256,50,0,48.209,0,46V4C0,1.791,2.256,0,5.04,0Z" transform="translate(824.454 1925)"/>
      <text id="View_submitted_activity" data-name="View submitted activity" class="cls-get-started" transform="translate(834 1938)"><tspan x="3.908" y="20">VIEW SUBMITTED ACTIVITY</tspan></text>
    </g>
  </g>
</svg>
