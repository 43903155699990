<div *ngIf="!navbar_collapsed && nav_icon_selected == 'nav' && student_nav" class="little-unit-nav-container">
  <perfect-scrollbar class="little-unit-nav-scrollbar-height">
    <span *ngFor="let lesson of student_nav">
      <div *ngFor="let section of lesson.sections" 
           [id]="section.id"
           class="row little-nav-section-container" 
           [routerLink]="['/app/sectionactivities']" 
           (click)="setLessonAndSection(lesson.id, section.id)"
           [ngClass]="{'little-active-section-nav' : section.id == selected_section_id}"
           (mouseover)="tempActive($event, section.id, selected_section_id)" 
           (mouseout)="tempActive($event, section.id, selected_section_id)">
        <div class="col-md-1" style="padding: 0px;">
          <img width="26" *ngIf="section.completed" src="../../assets/img/icons/little-bookmark.svg" style="margin-top: 8px;" />
          <img width="20" *ngIf="!section.completed" src="../../assets/img/icons/little-nav-bookmark-incomplete.svg"  style="margin-top: 8px; margin-left: 3px;" />
        </div>
        <div class="col-md-4" style="padding-right: 0px;">
          <img [src]="section.image.file" class="rounded little-nav-section-image"  />
        </div>
        <div class="col-md-7" style="padding-right: 0px;">
          <div class="little-section-name">{{ section.name }}</div>
          <div class="little-breadcrumb">Unit {{ lesson.unit_number }}, {{ lesson_aka }} {{ lesson.lesson_number}}</div>
        </div>
      </div>
    </span>
  </perfect-scrollbar>
</div>