import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iStudentNavUnits } from '../models/studentunits.interface';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
  })
};

@Injectable()
export class StudentUnitsService {
  constructor(private http: HttpClient) {}

  GetStudentNavUnits(StudentId: string): Observable<iStudentNavUnits[]> {
    return this.http.get<iStudentNavUnits[]>( environment.conn_string + 'api/student_units/get_student_nav_units/' + StudentId, httpOptions );
  }

  GetStudentNavUnitDetails(StudentId: string, UnitId: string): Observable<iStudentNavUnits> {
    return this.http.get<iStudentNavUnits>( environment.conn_string + 'api/student_units/get_student_nav_unit_details/' + StudentId + '/' + UnitId, httpOptions );
  }
}