import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { iShippingData } from '../models/order.interface';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
    // 'transId': 'ORDERID',
    // 'transactionSrc': 'FUZ-SIFT',
    // 'AccessLicenseNumber': '8DA7DA1356E508B2',
    // 'Username': 'Sophia1.qvm',
    // 'Password': '7525Ups!'
  })
};

@Injectable()
export class UpsService {
  constructor(private http: HttpClient) {}

  GetShippingCosts(ShippingData: iShippingData): Observable<any> {
    return this.http.post<any>( environment.conn_string + 'api/orders/shipping_rate', ShippingData, httpOptions );
  }
}