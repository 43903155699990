<div class="modal fade modal-fixed-right modal-theme overflow-hidden" id="notifications-modal" tabindex="-1" role="dialog" aria-labelledby="notifications-modal-label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-vertical" role="document">
    <div class="modal-content border-0 vh-100 scrollbar">
      <div class="modal-header modal-header-settings">
        <img src="../../../assets/img/icons/button-x.svg" width="50" type="button" data-dismiss="modal" />
        <h4 class="text-white little-notification-header-text">Notifications</h4>
      </div>
      <div class="nav-card card-notification shadow-none">
        <div class="list-group list-group-flush font-weight-normal fs--1">
          <div class="list-group-title border-bottom unread-title">NEW</div>
          <div *ngFor="let n of n_unread" class="list-group-item">
            <a class="notification notification-flush notification-unread unread-message" (click)="markNotificationAsRead(n.id); navigateToSection(n);">
              <div class="notification-avatar">
                <div class="avatar avatar-2xl mr-3">
                  <i *ngIf="n.subject == 'Student Notification'" class="fas fa-user fa-2x"></i>
                  <i *ngIf="n.subject == 'Classroom Notification'" class="fas fa-users fa-2x"></i>
                  <i *ngIf="n.subject == 'Scoring Notification'" class="fas fa-tasks fa-2x"></i>
                  <i *ngIf="n.subject == 'Reassigned Activity'" class="fas fa-redo fa-flip-horizontal fa-2x"></i>
                  <i *ngIf="!n.subject" class="fas fa-redo fa-flip-horizontal fa-2x"></i>
                </div>
              </div>
              <div class="notification-body">
                <p class="mb-1"><strong>{{ n.sender }}</strong> sent: {{ n.message }}</p>
                <span class="unread-notification-time"><i class="far fa-clock"></i> {{ n.timespan }}</span>
                <br>
                <span class="notification-date">{{ n.created_at | date }}</span>
              </div>
            </a>
          </div>

          <div class="list-group-title border-bottom read-title">READ</div>
          <div *ngFor="let n of n_read" class="list-group-item">
            <a class="notification notification-flush read-message" (click)="navigateToSection(n);">
              <div class="notification-avatar">
                <div class="avatar avatar-2xl mr-3">
                  <i *ngIf="n.subject == 'Student Notification'" class="fas fa-user fa-2x"></i>
                  <i *ngIf="n.subject == 'Classroom Notification'" class="fas fa-users fa-2x"></i>
                  <i *ngIf="n.subject == 'Scoring Notification'" class="fas fa-tasks fa-2x"></i>
                  <i *ngIf="n.subject == 'Reassigned Activity'" class="fas fa-redo fa-flip-horizontal fa-2x"></i>
                  <i *ngIf="!n.subject" class="fas fa-redo fa-flip-horizontal fa-2x"></i>
                </div>
              </div>
              <div class="notification-body">
                <p class="mb-1"><strong>{{ n.sender }}</strong> sent: {{ n.message }}</p>
                <span class="read-notification-time"><i class="far fa-clock"></i> {{ n.timespan }}</span>
                <br>
                <span class="notification-date">{{ n.created_at | date }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>