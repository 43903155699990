import { Component, Input, ElementRef, AfterViewInit, ViewChild, SimpleChanges } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'

@Component({
    selector: 'drawingcanvas',
    template: '<canvas style="position:absolute; top: 0; left: 10; z-index: 1 !important;" #canvas [ngStyle]="{\'border\': border_style}"></canvas><canvas style="position:absolute; top: 0; left: 10; z-index: 0 !important;" #canvas_background [ngStyle]="{\'border\': border_style}"></canvas>',
    styles: ['canvas { border-radius: 6px}']
})
export class DrawingCanvasComponent implements AfterViewInit {
    @ViewChild('canvas', { static: true }) public canvas: ElementRef;
    @ViewChild('canvas_background', { static: true }) public canvas_background: ElementRef;
    @Input() public width;
    @Input() public height;
    @Input() public selected_color;
    @Input() public selected_button;
    @Input() public selected_px;
    @Input() public background_image_url;
    imageData: any;
    canvasEl: HTMLCanvasElement;
    canvasBG: HTMLCanvasElement;
    border_style = "none";

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selected_color) {
            this.selected_color = changes.selected_color.currentValue;

            if (this.ctx) {
                this.ctx.strokeStyle = this.selected_color;
            }
        }

        if (changes.selected_button) {
            this.selected_button = changes.selected_button.currentValue;
        }

        if (changes.width) {
            if (this.canvasEl) {
                this.canvasEl.width = changes.width.currentValue;
            }
        }

        if (changes.height) {
            if (this.canvasEl) {
                this.canvasEl.height = changes.height.currentValue;
            }
        }

        if (changes.selected_px){
            // this.selected_button = "draw";
        }

        // if (changes.width){
        //     this.ngAfterViewInit();
        // }

        // if (changes.height){
        //     this.ngAfterViewInit();
        // }

        if (this.selected_button == "clear") {
            this.ctx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
            const img1 = new Image();

            console.log(this.background_image_url);
            if (this.background_image_url){
                img1.src = this.background_image_url;
                img1.crossOrigin = "Anonymous";
                img1.onload = () => {
                    let maxWidth = this.width;
                    let maxHeight = this.height;
                    let ratio = 0;
                    let width = img1.width;
                    let height = img1.height;
                    let cssWidth = img1.width;
                    let cssHeight = img1.height;

                    if (width > maxWidth){
                        ratio = maxWidth / width;
                        cssWidth = maxWidth;
                        cssHeight = height * ratio;
                        height = height * ratio;
                        width = width * ratio; 
                    }
                    
                    if (height > maxHeight){
                        ratio = maxHeight / height;
                        cssHeight = maxHeight;
                        cssWidth = width * ratio;
                        width = width * ratio;
                        height = height * ratio; 
                    }

                    this.ctx_bg.drawImage(img1, this.width / 2 - cssWidth / 2, this.height / 2 - cssHeight / 2, cssWidth, cssHeight);
                    // this.ctx_bg.drawImage(img, 0, 0, this.width, this.height);
                    // this.captureEvents(this.canvasEl);
                }
            } else {
                this.ctx_bg.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
            }
        }
    }

    private ctx: CanvasRenderingContext2D;
    private ctx_bg: CanvasRenderingContext2D;

    public ngAfterViewInit() {
        setTimeout(() => {
            this.canvasEl = this.canvas.nativeElement;
            this.canvasBG = this.canvas_background.nativeElement;
            this.ctx = this.canvasEl.getContext('2d');
            this.ctx_bg = this.canvasBG.getContext('2d');

            this.canvasEl.width = this.width;
            this.canvasEl.height = this.height;
            this.canvasBG.width = this.width;
            this.canvasBG.height = this.height;
            this.border_style = "1px solid #999";

            this.ctx.lineWidth = 5;
            this.ctx.lineCap = 'round';
            this.ctx.strokeStyle = this.selected_color;

            const img = new Image();
            if (this.background_image_url){
                // img.src = "https://www.gardeningknowhow.com/wp-content/uploads/2020/12/lonely-japanese-cherry.jpg";
                img.src = this.background_image_url;
                img.crossOrigin = "Anonymous";
                
                img.onload = () => {
                    let maxWidth = this.width;
                    let maxHeight = this.height;
                    let ratio = 0;
                    let width = img.width;
                    let height = img.height;
                    let cssWidth = img.width;
                    let cssHeight = img.height;

                    if (width > maxWidth){
                        ratio = maxWidth / width;
                        cssWidth = maxWidth;
                        cssHeight = height * ratio;
                        height = height * ratio;
                        width = width * ratio; 
                    }
                    
                    if (height > maxHeight){
                        ratio = maxHeight / height;
                        cssHeight = maxHeight;
                        cssWidth = width * ratio;
                        width = width * ratio;
                        height = height * ratio; 
                    }

                    this.ctx_bg.drawImage(img, this.width / 2 - cssWidth / 2, this.height / 2 - cssHeight / 2, cssWidth, cssHeight);
                    // this.ctx_bg.drawImage(img, 0, 0, this.width, this.height);
                    // this.captureEvents(this.canvasEl);
                }
                img.onerror = (e) => {
                    console.log('img load error');
                }
            }
            this.captureEvents(this.canvasEl);
        }, 1000);
    }

    private captureEvents(canvasEl: HTMLCanvasElement) {
        // this will capture all mousedown events from the canvas element
        fromEvent(canvasEl, 'mousedown')
            .pipe(
                switchMap((e) => {
                    // after a mouse down, we'll record all mouse moves
                    return fromEvent(canvasEl, 'mousemove')
                        .pipe(
                            // we'll stop (and unsubscribe) once the user releases the mouse
                            // this will trigger a 'mouseup' event    
                            takeUntil(fromEvent(canvasEl, 'mouseup')),
                            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
                            takeUntil(fromEvent(canvasEl, 'mouseleave')),
                            // pairwise lets us get the previous value to draw a line from
                            // the previous point to the current point    
                            pairwise()
                        )
                })
            )
            .subscribe((res: [MouseEvent, MouseEvent]) => {
                const rect = canvasEl.getBoundingClientRect();

                // previous and current position with the offset
                const prevPos = {
                    x: res[0].clientX - rect.left,
                    y: res[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].clientX - rect.left,
                    y: res[1].clientY - rect.top
                };

                
                this.drawOnCanvas(prevPos, currentPos);

                //save the state of the canvas
                this.imageData = this.ctx.getImageData(0, 0, canvasEl.width, canvasEl.height);
            });
    }

    private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
        if (!this.ctx) { return; }

        this.ctx.beginPath();

        if (this.selected_button == "draw") {
            this.ctx.globalCompositeOperation = 'source-over';
            this.ctx.strokeStyle = this.selected_color;
            this.ctx.lineWidth = this.selected_px;
            // prevPos.y=prevPos.y+32;
            // currentPos.y=currentPos.y+32;
        }

        if (this.selected_button == "erase") {
            this.ctx.globalCompositeOperation = 'destination-out';
            this.ctx.lineWidth = (10*this.selected_px)-10;
            prevPos.y=prevPos.y-5;
            currentPos.y=currentPos.y-5;
        }

        if (prevPos) {
            this.ctx.moveTo(prevPos.x, prevPos.y); // from
            this.ctx.lineTo(currentPos.x, currentPos.y);
            this.ctx.stroke();
        }
    }

    saveDrawing(textboxes: {text: string, x: number, y: number}[]): any {
        if (textboxes.length > 0){
            textboxes.forEach(t => {
                this.ctx.font = "18px Arimo";
                this.ctx.fillText(t.text, t.x, t.y);
            })
        }


        //merge main and background images
        this.ctx_bg.drawImage(this.canvasEl, 0, 0);
        
        let saved_drawing = this.canvasBG.toDataURL("image/png").replace("image/png", "image/octet-stream");
        console.log(saved_drawing);
        
        
        let image_file = this.dataURLtoFile(saved_drawing, 'image.png')
        // let image_file = this.dataURLtoBlob(saved_drawing)
        return image_file;
    }

    dataURLtoFile(dataurl, filename): any {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    dataURLtoBlob(dataurl): any {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }
}
