import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iMultipleChoiceActivity } from '../../models/multiplechoice.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { SharedService } from '../../shared/services/shared.service';
import { AuthService } from '../../auth/auth.service';

export interface activity_response {
  answerId: number,
  questionId: number
}

@Component({
  selector: 'bigmultiplechoice',
  templateUrl: './bigmultiplechoice.component.html',
  providers: [StudentGradesService, StudentSectionsService]
})

export class BigMutlipleChoiceComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Input() index: number;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iMultipleChoiceActivity;
  saved_time: number;
  selected_section: iStudentSection;
  ssservice: any;
  response: activity_response[];
  directions: string;

  constructor(
    private sgServ: StudentGradesService,
    private ssServ: StudentSectionsService,
    private sharedService: SharedService,
    private authService: AuthService) {
    this.response = [];
  }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe(activity => {
      this.activity = activity['data'];

      if (this.activity.response) {
        this.response = this.activity.response.data;
      } else {
        this.activity.data.questions.forEach(q => {
          this.response.push({ answerId: 200, questionId: q.id });
        })
      }

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data.directions && this.activity.data.directions !== "") {
          this.directions = "<strong>Directions:</strong> " + this.activity.data.directions;
        } else if (this.selected_section.section_directions) {
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  submitActivity(show_success: boolean) {
    setTimeout(() => {
      this.sharedService.submitActivityResponse(this.activity.id, this.response, show_success);

      this.sharedService.getSubmitActivitySuccess().subscribe(success => {
        if (success) {
          this.saved_time = Date.now();
        }
      })
    }, 1000);
  }

  resubmit() {
    this.resubmitResponse.emit(this.response);
  }

  ngOnDestroy() {
    if (this.sgservice) { this.sgservice.unsubscribe(); };
    if (this.ssservice) { this.ssservice.unsubscribe(); };
  }
}
