import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iFlashCardsActivity } from '../../models/flashcardsactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

export class iFlashCard{
  id: number;
  explanation: string; 
  word: string;
}

@Component({
  selector: 'bigflashcards',
  templateUrl: './bigflashcards.component.html',
  styleUrls: ['./bigflashcards.component.scss'],
  providers: [ StudentGradesService, StudentSectionsService ]
})

export class BigFlashcardsComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iFlashCardsActivity;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  review_deck: iFlashCard[];
  current_card: iFlashCard;
  learned_deck: iFlashCard[];
  show_definition: boolean;
  response: {score: string, completed: boolean};
  directions: string;
  word: any;
  totalWords: number;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService ) {
      this.review_deck = [];
      this.learned_deck = [];
      this.show_definition = false;
    }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];

      this.review_deck = this.activity.data.cards;
      this.totalWords = this.activity.data.cards.length;
      this.shuffleCards();
      
      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data['directions'] && this.activity.data['directions'] !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  selectCard() {
    if (this.review_deck.length > 0 && !this.current_card){
      this.current_card = this.review_deck[0];
      this.review_deck.shift();
      this.show_definition = false;
    }
  }

  drop(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
             moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                            event.container.data,
                            event.previousIndex,
                            event.currentIndex);
            this.review_deck.push(this.word);
          // this.review_deck.push(this.review_deck.shift());
            this.show_definition = false;
            this.current_card = null;
        }
    }

  drop1(event: CdkDragDrop<string[]>) {
        
        if (event.previousContainer === event.container) {
             moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {

            
            transferArrayItem(event.previousContainer.data,
                            event.container.data,
                            event.previousIndex,
                            event.currentIndex);
              this.learned_deck.splice(0, 0,this.word )
              this.show_definition = false;

               this.current_card = null;
        }
    }

  flipCard(word)
 {
    this.word = word;
    if (this.current_card){
      if (this.show_definition){
        // this.learned_deck.push(this.current_card);
        // this.learned_deck.reverse();
        // this.show_definition = false;
        // this.current_card = null;
      } else {
        this.show_definition = !this.show_definition;
      }
    }
  }

  shuffleCards() {
    for (let i = this.review_deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.review_deck[i], this.review_deck[j]] = [this.review_deck[j], this.review_deck[i]];
    }
  }

  resetDecks() {
    this.learned_deck.forEach(c => {
      this.review_deck.push(c);
    });
    this.shuffleCards();
    // this.current_card = null;
    this.learned_deck = [];
  }

  submitActivity(show_success: boolean){
    this.response = {score: this.learned_deck.length.toString(), completed: true};
    this.sharedService.submitActivityResponse(this.activity.id, this.response, show_success);
  }

  resubmit(){
    this.response = {score: this.learned_deck.length.toString(), completed: true};
    this.resubmitResponse.emit(this.response);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}