<ul *ngIf="selected_nav_unit" class="navbar-nav align-items-center d-none d-lg-block" style="padding-top: 6px;">
  <li class="nav-item">
    <div class="dropdown text-sans-serif mb-2">
      <button class="btn btn-falcon-default dropdown-toggle" 
              id="dropdownMenuButton" 
              type="button" 
              data-toggle="dropdown" 
              aria-haspopup="true" 
              aria-expanded="false"
              style="width:700px"
              (click)="togglecaret($event)">
        <div class="row">
          <div class="col-md-1">
            <img class="rounded" [src]="selected_nav_unit.unit_image.file" width="40" style="margin-top: 5px;" />
          </div>
          <div class="col-md-10 text-left" style="padding-left: 40px; padding-right: 0px;">
            <p class="unit-nav-unit-text">
              UNIT {{ selected_nav_unit.number }}
            </p>
            <p class="unit-nav-unit-name">
              {{ selected_nav_unit.name }}
            </p>
          </div>
          <div class="caret-container">
            <a href="#" id="dropdown-trigger"><span class="caret-dropdown"></span></a>
          </div>   
        </div>           
      </button>
      <div class="dropdown-menu py-0 unit-ddl-content" aria-labelledby="dropdownMenuButton">
        <a *ngFor="let unit of nav_units; let i = index;" class="dropdown-item unit-ddl-items">
          <div *ngIf="!unit.hard_locked" class="row unit-ddl-rows" [ngClass]="{'locked-unit-select': unit.locked}" (click)="setUnit(unit)">
            <div class="col-md-1">
              <img class="rounded" [src]="unit.unit_image.file" width="40" style="margin-top: 5px;" />
            </div>
            <div class="col-md-11 text-left" style="padding-left: 40px; padding-right: 0px;">
              <p class="unit-nav-unit-text">
                UNIT {{ unit.number }}
              </p>
              <p class="unit-nav-unit-name">
                {{ unit.name }}
                <span *ngIf="unit.locked" style="float: right;"><i class="fas fa-lock"></i></span>
              </p>
            </div>
          </div>
          <hr *ngIf="i < nav_units.length - 1 && !unit.hard_locked" style="margin:0px;">
        </a>
      </div>
    </div>
  </li>
</ul>