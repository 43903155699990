<div *ngIf="activity && response && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>

  <div *ngFor="let question of activity.data.questions; let i = index" style="margin-bottom: 20px;">
    {{question.question}}
    <div *ngFor="let answer of question.answers; let j=index" class="custom-control custom-radio">
      <input class="custom-control-input" 
             type="radio"
             name="response{{index}}{{i}}{{j}}"
             id="response{{question.id}}{{answer.id}}{{index}}{{i}}{{j}}"
             [checked]="response[question.id].answerId === answer.id"
             [(ngModel)]="response[question.id].answerId"
             [value]="answer.id"
             (click)="submitActivity(false)">
      <label class="custom-control-label font-primary" style="font-size: 20px;" for="response{{question.id}}{{answer.id}}{{index}}{{i}}{{j}}">{{ answer.answer }}</label>
    </div>
    <div class="auto-save-label">autosaved {{ saved_time | date:'shortTime' }}</div>
  </div>

  <div class="text-center save-activity-button">
    <littleactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></littleactivitysubmittedbutton>
  </div>
</div>
