<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>

  <div class="row">
    <!-- <div class="col-md-9" #canvas_container id="canvas_container" [style.background-image]="'url(' + background_image + ')'" style="background-size: contain; background-repeat: no-repeat; padding: 0; position: relative;"> -->
    <div class="col-md-9" #canvas_container id="canvas_container">
      <drawingcanvas #canvas [style.cursor]="'url('+icon+') 0 32, auto'" [selected_color]="selected_color" [background_image_url]="background_image" [selected_button]="selected_button" [selected_px]="selected_px" [width]="canvas_width" [height]="canvas_height"></drawingcanvas>
      <form [formGroup]="fdForm">
        <div formArrayName="itemRows">
          <div *ngFor="let itemrow of getControls(); let i = index" [formGroupName]="i">
            <span class="form-control text-center fd-text-container" style="width: 155px; height: auto; background-color:transparent; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 2 !important;" cdkDrag>
              <i class="form-control-feedback" style="cursor: pointer; color: red;" (click)="deleteRow(i)">&times;</i>
              <textarea class="form-control fd-text" type="text" placeholder="Enter text..." (keyup)="autoGrowTextZone($event)" id="tb{{i}}" style="width: 130px; height: auto; background-color:transparent; margin-left: -15px; margin-top: -5px; border: none;" formControlName="text" name="text"></textarea>
            </span>  
          </div>
        </div>
      </form> 
    </div>
         
    <div class="col-md-3" style="padding-left: 60px;">
      <div class="row">
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #000; cursor: pointer;" (click)="setColor('#000')"></div>
        </div>
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #fff; cursor: pointer; border: 1px solid #999"
            (click)="setColor('#fff')"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #c01f26; cursor: pointer;" (click)="setColor('#c01f26')">
          </div>
        </div>
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #3eb94a; cursor: pointer;" (click)="setColor('#3eb94a')">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #216495; cursor: pointer;" (click)="setColor('#216495')">
          </div>
        </div>
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #805925; cursor: pointer;" (click)="setColor('#805925')">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #f0e045; cursor: pointer;" (click)="setColor('#f0e045')">
          </div>
        </div>
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #f28425; cursor: pointer;" (click)="setColor('#f28425')">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #a73bf1; cursor: pointer;" (click)="setColor('#a73bf1')">
          </div>
        </div>
        <div class="col-md-6 text-center">
          <div class="color-picker" style="background-color: #ee87f2; cursor: pointer;" (click)="setColor('#ee87f2')">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6 text-center">
          <span (click)="setColor('#000')"><i class="fad fa-pencil display-4" title="Pencil"></i></span>
        </div>
        <div class="col-md-6 text-center">
          <span (click)="addNewRow()">
            <i class="fas fa-text fa-3x" style="cursor: pointer;"></i>
          </span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6 text-center">
          <span (click)="setEraser()"><i class="fad fa-eraser display-4" title="Eraser"></i> </span>
        </div>
        <div class="col-md-6 text-center">
          <span (click)="setclear()"><i class="fad fa-trash display-4" title="Clear"></i> </span>
        </div>
      </div>
      <div class="row" style="margin-top: 20px;">
        <div class="col-md-12">
          <select class="form-control" aria-label=".form-select-md example" name="px" id="px" [(ngModel)]="selected_px">
            <option value="2">2px</option>
            <option value="4">4px</option>
            <option value="6">6px</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center save-activity-button">
    <bigactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div>