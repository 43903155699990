import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { LittleNotificationsComponent } from "./littlenotifications.component";

const LITTLE_NOTIFICATIONS_ROUTE = [
    { path: '', component: LittleNotificationsComponent }
];

@NgModule ({
    declarations: [
        LittleNotificationsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(LITTLE_NOTIFICATIONS_ROUTE)
    ],
    exports: [
        LittleNotificationsComponent
    ]
})

export class LittleNotificationsModule {  }