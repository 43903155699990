import { Component } from '@angular/core';

@Component({
    selector: 'bigtouchicon',
    templateUrl: './bigtouchicon.component.html'
})

export class BigTouchIcon {

  constructor( ) {  }
}
