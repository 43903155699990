import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';

@Component({
    selector: 'verticalnavbar',
    templateUrl: './verticalnavbar.component.html',
    providers: [ ]
})

export class VerticalNavBarComponent implements OnInit, OnDestroy {
  little: boolean;
  vert_navbar_class: string;

  constructor( private sharedService: SharedService,
    private authService: AuthService ) {
    this.little = this.authService.getLittleKid() == 'true';

    this.vert_navbar_class = this.little ? "vert-nav-little" : "vert-nav-big";
  }

  ngOnInit() {
    this.sharedService.collapseNavbar(true);
  }
  
  ngOnDestroy() {
    
  }
}
