<!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="150" viewBox="0 0 291.21 104.23" style="cursor: pointer;">
  <defs>
    <filter id="Path_249" x="0" y="0" width="291.21" height="104.23" filterUnits="userSpaceOnUse">
      <feOffset dx="1" dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur"/>
      <feFlood flood-opacity="0.322"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_792" data-name="Rectangle 792" width="254.21" height="66.23" rx="24" transform="translate(389 653)" fill="#e4e8db"/>
    </clipPath>
    <filter id="Submitted" x="61.605" y="23.5" width="202" height="60" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Path_237" x="30.316" y="23.862" width="52.505" height="52.505" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-3"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-3"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_269" data-name="Group 269" transform="translate(12787.605 -16121.697)">
    <g id="Group_267" data-name="Group 267">
      <g id="Group_266" data-name="Group 266" transform="translate(0 -175)">
        <g transform="matrix(1, 0, 0, 1, -12787.61, 16296.7)" filter="url(#Path_249)">
          <g id="Path_249-2" data-name="Path 249" transform="translate(16.5 15.5)" fill="none">
            <path d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" stroke="none"/>
            <path d="M 24 0 C 10.74516296386719 0 0 10.74516296386719 0 23.99999618530273 L 0 45.22999572753906 C 0 58.48483276367188 10.74516296386719 69.22999572753906 24 69.22999572753906 L 232.2099914550781 69.22999572753906 C 245.4648284912109 69.22999572753906 256.2099914550781 58.48483276367188 256.2099914550781 45.22999572753906 L 256.2099914550781 23.99999618530273 C 256.2099914550781 10.74516296386719 245.4648284912109 0 232.2099914550781 0 L 24 0 M 24 -10 L 232.2099914550781 -10 C 250.9576568603516 -10 266.2099914550781 5.252330780029297 266.2099914550781 23.99999618530273 L 266.2099914550781 45.22999572753906 C 266.2099914550781 63.97766494750977 250.9576568603516 79.22999572753906 232.2099914550781 79.22999572753906 L 24 79.22999572753906 C 5.252334594726562 79.22999572753906 -10 63.97766494750977 -10 45.22999572753906 L -10 23.99999618530273 C -10 5.252330780029297 5.252334594726562 -10 24 -10 Z" stroke="none" fill="#fff"/>
          </g>
        </g>
        <path id="Path_250" data-name="Path 250" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(-12771.105 16314.197)" fill="#74ad99"/>
        <path id="Path_251" data-name="Path 251" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(-12771.105 16312.197)" fill="#858798"/>
        <g id="Mask_Group_69" data-name="Mask Group 69" transform="translate(-13159.105 15660.197)" clip-path="url(#clip-path)">
          <path id="Path_252" data-name="Path 252" d="M306.942,32.768c0,10.408-63.514,18.886-141.871,18.886S23.2,43.176,23.2,32.768,86.369,13.4,164.726,13.4,306.942,22.36,306.942,32.768Z" transform="translate(350.616 619.311)" fill="rgba(255,255,255,0.5)"/>
        </g>
        <g transform="matrix(1, 0, 0, 1, -12787.61, 16296.7)" filter="url(#Submitted)">
          <text id="Submitted-2" data-name="Submitted" transform="translate(70.61 29.5)" fill="#fff" font-size="32" font-family="Arimo-Bold, Arimo" font-weight="700"><tspan x="13.781" y="29">Submitted</tspan></text>
        </g>
      </g>
    </g>
    <g id="Group_268" data-name="Group 268" transform="translate(-1081 -981.302)">
      <g transform="matrix(1, 0, 0, 1, -11706.61, 17103)" filter="url(#Path_237)">
        <path id="Path_237-2" data-name="Path 237" d="M485.079-107.9a17.253,17.253,0,0,0-17.253,17.253A17.253,17.253,0,0,0,485.079-73.4a17.253,17.253,0,0,0,17.253-17.252A17.253,17.253,0,0,0,485.079-107.9ZM496.12-95.469,483.033-82.383a1.386,1.386,0,0,1-1.961,0l-2.463-2.463-4.572-4.572a1.386,1.386,0,0,1,0-1.961l1.482-1.483a1.386,1.386,0,0,1,1.961,0l4.572,4.572,10.623-10.623a1.386,1.386,0,0,1,1.961,0l1.483,1.482A1.387,1.387,0,0,1,496.12-95.469Z" transform="translate(-428.51 137.76)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg> -->

<div style="width: 100%;">
  <div class="little-activity-done-disabled-button">
    <i class="fas fa-check-circle"></i> Submitted
  </div>
</div>
