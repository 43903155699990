<ul *ngIf="navbar_collapsed" class="navbar-nav flex-column">
  <li class="nav-item big-vert-nav">

    <div class="nav-background">
      <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/app/unitlanding']" (click)="setSelected('landing')">
        <span class="nav-link-icon">
            <img src="../../../assets/img/icons/landing-page.png" />
        </span>
      </a>
    </div>

    <div class="nav-background">
      <a class="nav-link" (click)="collapseVerticalNavbar(false); setSelected('nav')">
        <span class="nav-link-icon">
            <img src="../../../assets/img/icons/activities-munano.png" />
        </span>
      </a>
    </div>
    
    <div class="nav-background" *ngIf="curriculum_type == 'School'">
      <a class="nav-link" (click)="collapseVerticalNavbar(false); setSelected('vocab')">
        <span class="nav-link-icon">
          <img src="../../../assets/img/icons/vocab.png" />
        </span>
      </a>
    </div>

    <div class="navbar-vertical-divider">
      <hr class="navbar-vertical-hr my-2" style="width: 48px; margin-top: -10px;" />
    </div>

    <div class="nav-background">
      <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/app/saintcards']" (click)="setSelected('cards')">
        <span class="nav-link-icon">
          <img src="../../../assets/img/icons/saint-cards.png" />
        </span>
      </a>
    </div>
    
    <div class="navbar-vertical-divider">
      <hr class="navbar-vertical-hr my-2" style="width: 48px;" />
    </div>

    <a [ngClass]="bell_classes" class="navbar_indicator" role="button" data-toggle="modal" href="#notifications-modal" (click)="triggerNotifications()">
      <div class="nav-background">
        <span class="nav-link-icon">
          <span class="fas fa-bell fs-4" data-fa-transform="shrink-6" style="color:white;"></span>
        </span>
      </div>
    </a>
  </li>
</ul>
      