<ul *ngIf="!navbar_collapsed" class="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center little-horizontal-container" style="width: 100%; padding-left: 20px;">
  
  <li class="nav-item horizontal-nav-items nav-background little-hztl-icon">
    <a [class]="unit_nav_class" (click)="setSelected('nav')">
      <div class="d-flex align-items-center">
        <span class="nav-link-icon">
          <img width="50" src="../../assets/img/icons/unit-nav-icon.svg" />
        </span>
      </div>
    </a>
  </li>

  <li class="nav-item horizontal-nav-items nav-background little-hztl-icon">
    <a [ngClass]="bell_classes" class="little_navbar_indicator_expanded" href="#notifications-modal" role="button" data-toggle="modal" (click)="triggerNotifications()">
      <div class="d-flex align-items-center">
        <span class="nav-link-icon">
          <img width="50" src="../../assets/img/icons/button-bell.svg" />
        </span>
      </div>
    </a>
  </li>

  <li class="nav-item horizontal-nav-items nav-background little-hztl-icon">
    <a class="nav-link" (click)="collapseVerticalNavbar(true);">
      <div class="d-flex align-items-center">
        <span class="nav-link-icon">
          <img width="50" src="../../assets/img/icons/button-x.svg" />
        </span>
      </div>
    </a>
  </li>
</ul>
      