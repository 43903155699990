import { Pipe, PipeTransform } from '@angular/core';
import { iUnitVocab } from '../models/units.interface';

@Pipe({ name: 'vocabFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
    transform(items: iUnitVocab[], searchText: string): any[] {
        if (!items) {
            return [];
        }

        if (!searchText) {
            return items;
        }

        searchText = searchText.toLowerCase();

        return items.filter(it => {
            return it.word.toLowerCase().includes(searchText);
        });
    }
}