import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { iDiocese } from '../models/diocese.interface';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
    })
};

@Injectable()
export class DioceseService {
    constructor(private http: HttpClient) { }

    GetActiveDioceses() {
        return this.http.get<iDiocese[]>(environment.conn_string + "api/dioceses/active_dioceses", httpOptions);
    }

    GetDioceses() {
        return this.http.get<iDiocese[]>(environment.conn_string + "api/dioceses", httpOptions);
    }
}