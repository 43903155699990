<script>
  // Enable pusher logging - don't include this in production
  Pusher.logToConsole = true;

  var pusher = new Pusher('703446197d942a22417c', {
    cluster: 'us2'
  });

  var channel = pusher.subscribe('my-channel');
  channel.bind('my-event', function(data) {
    alert(JSON.stringify(data));
  });
</script>

<div *ngIf="activity">
  <div class="secondary-font text-center">
    Let's play a game!
  </div>
  <div class="text-center save-activity-button">
    <bigactivitygetstartedbutton [attr.href]="'#drivinggame-modal' + activity.activity_number" data-toggle="modal" data-backdrop="static" data-keyboard="false" (click)="setupGame()"></bigactivitygetstartedbutton>
  </div>
</div>

<div *ngIf="activity && selected_section" class="modal fade" id="drivinggame-modal{{activity.activity_number}}" tabindex="-1" role="dialog" aria-labelledby="details-modal-label" aria-hidden="true">
  <div class="modal-dialog mt-6 modal-xl sc-modal-border" role="document">
      <div class="modal-content border-0 sc-modal-content">
          <div class="modal-header px-5 text-white position-relative sc-modal-header">
            <div class="row" style="width: 100% !important;">
              <div class="col-md-12">
                <h4 class="mb-0 font-secondary" style="color: white;" id="drivinggame-modal-label"><strong>{{ activity.title }}</strong></h4>
              </div>
            </div>
            <button class="btn-close position-absolute top-0 right-0 mt-2 mr-2" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body py-4 px-5">
            <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>
            <div id="unityContainer" data-pixelated="true" style="width: 1020px; height: 720px;"></div>

            <div class="text-center save-activity-button">
              <bigactivitydonedisabledbutton *ngIf="!activity.locked && !selected_section.completed && disabled" style="cursor: not-allowed;"></bigactivitydonedisabledbutton>
              <bigactivitydonebutton *ngIf="!activity.locked && !selected_section.completed && !activity.completed && !disabled" data-dismiss="modal" (click)="submitActivity(true)"></bigactivitydonebutton>
              <bigactivitydonebutton *ngIf="!activity.locked && !selected_section.completed && activity.completed && !disabled" data-dismiss="modal" (click)="submitActivity(true)" [disabled]="disabled"></bigactivitydonebutton>
              <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed" data-dismiss="modal"></bigactivitysubmittedbutton>
              <bigactivityresubmitdisabledbutton *ngIf="activity.completed && !activity.locked && selected_section.completed && disabled" style="cursor: not-allowed;"></bigactivityresubmitdisabledbutton>
              <bigactivityresubmitbutton *ngIf="activity.completed && !activity.locked && selected_section.completed && !disabled" (click)="resubmit();" data-dismiss="modal"></bigactivityresubmitbutton>
            </div>
          </div>
      </div>
  </div>
</div>

