import { Component } from '@angular/core';

@Component({
    selector: 'littleactivityviewsubmissionbutton',
    templateUrl: './littleactivityviewsubmissionbutton.component.html'
})

export class LittleActivityViewSubmissionButton {

  constructor( ) {  }
}
