import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { BigVertCollapsedComponent } from "./bigvertcollapsed.component";

const BIG_VERT_COLLAPSED_ROUTE = [
    { path: '', component: BigVertCollapsedComponent }
];

@NgModule ({
    declarations: [
        BigVertCollapsedComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(BIG_VERT_COLLAPSED_ROUTE)
    ],
    exports: [
        BigVertCollapsedComponent
    ]
})

export class BigVertCollapsedModule {  }