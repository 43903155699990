import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iMatchingActivity } from '../../models/matchingactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iImage } from '../../models/image.interface';

export interface iMatchedSet {
  id: number;
  text: string;
  image: iImage;
  active: boolean;
  color: string;
  partner_id: number;
}

@Component({
    selector: 'bigmatching',
    templateUrl: './bigmatching.component.html',
    styleUrls: ['./bigmatching.component.scss'],
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class BigMatchingComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iMatchingActivity;
  selected_section: iStudentSection;
  ssservice: any;
  left_column: iMatchedSet[];
  right_column: iMatchedSet[];
  colors: string[] = [
    "#7EAACA",
    "#74AD99",
    "#bd84b6",
    "#307863",
    "#007da1",
    "#004979",
    "#683868",
    "#9e032a",
    "#CE8B3D",
    "#A2CDEA",
    "#98D4C2",
    "#DEB0D2",
    "#47b090",
    "#43b3cf",
    "#0086c6",
    "#ae529b",
    "#d71440",
    "#E8A957"
  ]
  response: any[];
  directions: string;
  disabled: boolean;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService ) { 
      this.left_column = [];
      this.right_column = [];
      this.response = [];
      this.disabled = true;
    }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];

      this.activity.data.sets.forEach(s => {
        this.left_column.push({ 
          id: s.id, 
          text: s.word, 
          image: s.image, 
          active: false, 
          color: "",
          partner_id: null})

        this.right_column.push({ 
          id: s.id, 
          text: s.definition, 
          image: s.image, 
          active: false, 
          color: "",
          partner_id: null})
      })

      // if (this.activity['response'] && this.activity['response'].data.length !== 1){
      //   let temp: any[] = [];
      //   temp.push(this.activity['response'].data);
      //   this.activity['response'].data = temp;
      // }
      

      if (this.activity['response']?.data?.length){
        for (const [key, value] of Object.entries(this.activity['response'].data[0])) {
          this.matchLeft(value);
          this.matchRight(key);
        }
      }

      this.shuffleSets(this.left_column);
      this.shuffleSets(this.right_column);

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data['direction'] && this.activity.data['direction'] !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data['direction'];
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  shuffleSets(set: iMatchedSet[]){
    for (let i = set.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [set[i], set[j]] = [set[j], set[i]];
    }
  }

  matchLeft(id) {
    let left_index = this.left_column.findIndex((x) => x.id.toString() === id.toString());
    // if there is no color, set to the next color, look for same color on other side, if found, store the pair ids and remove the color from colors array
    if (this.left_column[left_index].color == ""){
      this.left_column[left_index].active = true;
      this.left_column[left_index].color = this.colors[0];

      this.checkOtherColumnForMatch(this.left_column, this.right_column, left_index);

      this.checkSameColumnForMatch(this.left_column, left_index);
    } else {
      // if there is a color, remove the color and the matching pair, if any
      this.resetElementAndPartner(this.left_column, this.right_column, left_index);

      // if this element was selected but user clicked on it again after selecting a new element from the other side
      this.overwriteElementAndPartner(this.left_column, this.right_column, left_index);
    }
  }

  matchRight(id) {
    let right_index = this.right_column.findIndex((x) => x.id.toString() === id.toString());
    // if there is no color, set to the next color, look for same color on other side, if found, store the pair ids and remove the color from colors array
    if (this.right_column[right_index].color == ""){
      this.right_column[right_index].active = true;
      this.right_column[right_index].color = this.colors[0];

      this.checkOtherColumnForMatch(this.right_column, this.left_column, right_index);

      this.checkSameColumnForMatch(this.right_column, right_index);
    } else {
      // if there is a color, remove the color and the matching pair, if any
      this.resetElementAndPartner(this.right_column, this.left_column, right_index);

      // if this element was selected but user clicked on it again after selecting a new element from the other side
      this.overwriteElementAndPartner(this.right_column, this.left_column, right_index);
    }
  }

  checkOtherColumnForMatch(this_column: iMatchedSet[], that_column: iMatchedSet[], this_index: number){
    let that_index = that_column.findIndex((x) => x.color === this.colors[0])
      if (that_index !== -1){
        this_column[this_index].partner_id = that_column[that_index].id;
        that_column[that_index].partner_id = this_column[this_index].id;
        this.colors.shift();
      }
  }

  checkSameColumnForMatch(column: iMatchedSet[], idx: number){
    let same: iMatchedSet[] = column.filter(x => x.color == this.colors[0]);
      
      if (same.length > 1){
        let same_index = same.findIndex(x => x.id !== column[idx].id);
        let final_index = column.findIndex(x => x.id === same[same_index].id);
        column[final_index].color = "";
        column[final_index].active = false;
      }
      this.checkAllSet();
  }

  resetElementAndPartner(this_column: iMatchedSet[], that_column: iMatchedSet[], this_index: number){
    this_column[this_index].color = "";
    this_column[this_index].active = false;
    if (this_column[this_index].partner_id !== null){
      let p_id = this_column[this_index].partner_id;
      let p_index = that_column.findIndex((x) => x.id === p_id);
      this.colors.unshift(that_column[p_index].color);
      that_column[p_index].color = "";
      that_column[p_index].active = false;
      that_column[p_index].partner_id = null;
      this_column[this_index].partner_id = null;
    }
  }

  overwriteElementAndPartner(this_column: iMatchedSet[], that_column: iMatchedSet[], this_index: number){
    let exists_index = that_column.findIndex((x) => x.color === this.colors[1]);
      
      if (exists_index > -1){
        this_column[this_index].color = this.colors[1];
        this_column[this_index].active = true;
        this_column[this_index].partner_id = that_column[exists_index].id;
        that_column[exists_index].partner_id = this_column[this_index].id;
        this.colors.splice(1, 1);
      }
      this.checkAllSet();
  }

  checkAllSet(){
    let d = false;
    this.right_column.forEach(x => {
      if(x.partner_id === null){
        d = true; 
      }
    })
    this.disabled = d;
  }

  submitActivity(show_success: boolean){
    this.right_column.sort((a,b) => (a.id > b.id) ? 1 : -1);

    this.right_column.forEach(x => {
      this.response.push(x.partner_id);
    })

    let r: any[] = [];
    r.push(this.response);
    
    this.sharedService.submitActivityResponse(this.activity.id, r, show_success);
  }

  resubmit(){
    this.right_column.sort((a,b) => (a.id > b.id) ? 1 : -1);

    this.right_column.forEach(x => {
      this.response.push(x.partner_id);
    })

    let r: any[] = [];
    r.push(this.response);
    
    this.resubmitResponse.emit(r);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
