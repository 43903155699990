import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iPayment, iOrder } from '../models/order.interface';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
  })
};

@Injectable()
export class OrderService {
  constructor(private http: HttpClient) {}

  CreateOrder(Order: iOrder): Observable<iOrder> {
    return this.http.post<iOrder>( environment.conn_string + 'api/orders/order', Order, httpOptions );
  }

  CreatePayment(Payment: iPayment): Observable<iPayment> {
    return this.http.post<iPayment>( environment.conn_string + 'api/orders/payment', Payment, httpOptions );
  }

//   GetSaintCards(): Observable<iSaintCard[]> {
//     return this.http.get<iSaintCard[]>(
//       environment.conn_string + 'api/saint_cards',
//       httpOptions
//     );
//   }

//   GetSaintCardDetails(SaintCardId: string) {
//     return this.http.get(
//       environment.conn_string + 'api/saint_cards/details/' +
//         SaintCardId,
//       httpOptions
//     );
//   }

//   UpdateSaintCard(SaintCard: iSaintCard): Observable<iSaintCard> {
//     return this.http.put<iSaintCard>(
//       environment.conn_string + 'api/saint_cards/' + SaintCard.id,
//       SaintCard,
//       httpOptions
//     );
//   }
}
