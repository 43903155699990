import { Component, OnInit, OnDestroy } from '@angular/core';

import { SharedService } from '../../shared/services/shared.service';

import { iUnitVocab } from '../../models/units.interface';
import { UnitsService } from '../../services/Units.service';

@Component({
    selector: 'unitvocab',
    templateUrl: './unitvocab.component.html',
    providers: [ UnitsService ]
})

export class UnitVocabComponent implements OnInit, OnDestroy {
  navbar_collapsed: boolean;
  nav_icon_selected: string;
  uservice: any;
  unit_vocab: iUnitVocab[];
  searchText: string;
  selected_unit_id: string;

  constructor(
    private sharedService: SharedService,
    private uServ: UnitsService ) { 
      this.unit_vocab = [];
      this.searchText = "";
  }

  ngOnInit() {
    this.sharedService.navbarCollapsedSubject.subscribe((value) => {
      this.navbar_collapsed = value;
    })

    this.sharedService.getCurrentPage().subscribe((value) => {
      this.nav_icon_selected = value;
    })

    this.sharedService.getSelectedUnit().subscribe((value) => {
      if (value){
        this.selected_unit_id = value.id.toString();
        this.getUnitVocab();
      }
    })
  }

  getUnitVocab(){
    this.uservice = this.uServ.GetUnitVocab(this.selected_unit_id).subscribe((vocab: iUnitVocab[]) => {
      this.unit_vocab = vocab['data']['unit_vocabularies'];
    })
  }
  
  ngOnDestroy() {
    if (this.uservice){ this.uservice.unsubscribe(); };
  }
}
