import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';

@Component({
    selector: 'littlevertexpanded',
    templateUrl: './littlevertexpanded.component.html',
    providers: [ ]
})

export class LittleVertExpandedComponent implements OnInit, OnDestroy {
  navbar_collapsed: boolean;
  current_page: string;
  vocab_class: string;
  unit_nav_class: string;
  bell_classes: string;
  little: boolean;

  constructor( 
    private authService: AuthService, 
    private sharedService: SharedService ) { 
      this.navbar_collapsed = true;
      this.sharedService.navbarCollapsedSubject.subscribe((value) => {
        this.navbar_collapsed = value;
      })
  }

  ngOnInit() { 
    this.sharedService.getCurrentPage().subscribe((value) => {
      this.current_page = value;

      this.little = this.authService.getLittleKid() == 'true';

      if (!this.little) {
        if (this.current_page == 'vocab'){
          this.vocab_class = "nav-link active";
          this.unit_nav_class = "nav-link";
        } else if ( this.current_page == 'nav'){
          this.vocab_class = "nav-link";
          this.unit_nav_class = "nav-link active";
        } else {
          this.vocab_class = "nav-link";
          this.unit_nav_class = "nav-link";
        }
      } else {
        this.vocab_class = "nav-link";
        this.unit_nav_class = "nav-link";
      }
    })

    this.sharedService.getBellClasses().subscribe((value) => {
      this.bell_classes = value;
    })
  }

  collapseVerticalNavbar(value: boolean){
    this.sharedService.collapseNavbar(value);
  }

  setSelected(type: string){
    this.sharedService.setCurrentPage(type);
  }

  triggerNotifications(){
    this.sharedService.getNotifications();
  }
  
  ngOnDestroy() { }
}
