import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "../app/pages/login/login.component";
import { ProductOrderFormComponent } from "../app/pages/productorderform/productorderform.component";

const ROUTES: Routes = [
  { path: "app", loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule) },
  { path: "", redirectTo: "app", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "productorderform", component: ProductOrderFormComponent }
  // { path: 'app', component: LayoutComponent },
  // { path: '', redirectTo: '/app', pathMatch: 'full' }
];

@NgModule({
  imports: [ RouterModule.forRoot(ROUTES) ],
  exports: [ RouterModule ]
})

export class routing{}

// export const routing = RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy' });