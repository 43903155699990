import { Component } from '@angular/core';

@Component({
    selector: 'bigsectionsubmitbutton',
    templateUrl: './bigsectionsubmitbutton.component.html'
})

export class BigSectionSubmitButton {

  constructor( ) {  }
}
