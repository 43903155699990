// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  conn_string: "https://sift-live-api.fuzati.com/",
  // conn_string: 'http://localhost:3000/',
  // conn_string: 'http://sift-dev-api.fuzati.com/',
  auth_token: "jagyw1xbTYRq15AJF2uP6k3z",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
