<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>

  <div [innerHtml]="updated_string | safeHtml"></div>

  <div class="text-center save-activity-button">
    <bigactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div>