<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>
    
  <div cdkDropListGroup>
    <div class="row">
      <div class="col-md-4">
        <div><strong>Word Bank</strong></div>
        <div cdkDropList id="i" #viewCards="cdkDropList"
          [cdkDropListConnectedTo]="[reviewCards0, reviewCards1, reviewCards2,reviewCards3,reviewCards4,reviewCards5,reviewCards6,reviewCards7,reviewCards8]"
          (cdkDropListDropped)="drop1($event)" [cdkDropListData]="word_bank">
          <div *ngFor="let word of word_bank; let i = index" class="text-center" cdkDrag>
            {{ word }}
          </div>
        </div>
      </div>
      <div class="col-md-8">
          <span *ngFor="let s of split_string; let i = index;">
            <span [innerHtml]="s | safeHtml"></span>
            <div *ngIf="i !== num_words"
                 [id]="i"
                 cdkDropList 
                 (cdkDropListDropped)="drop($event, totalBlanks[i], i)"
                 [cdkDropListData]="totalBlanks[i]"
                 style="width:150px; display:inline-block; background-color: lightgrey; text-align: center; margin-bottom: 10px;">
              <div style="width:150px; display:inline-block;" cdkDrag>{{ totalBlanks[i][0] }}</div>
            </div>
          </span>
      </div>
    </div>
  </div>
    
    <div class="text-center save-activity-button">
        <littleactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
        <littleactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
        <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></littleactivitysubmittedbutton>
    </div>
</div>