<div *ngIf="activity">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>
  <div class="row">
    <div class="col-lg-12 col-xl-8">
      <div id='puzzle' style="width:100%; text-align: center;"></div>
    </div>
    <div class="col-lg-12 col-xl-4">
      <div id='words'></div>
    </div>
  </div>
  
  <div class="text-center save-activity-button">
    <bigactivitydonedisabledbutton *ngIf="!activity.locked && !selected_section?.completed && disabled"></bigactivitydonedisabledbutton>
    <bigactivitydonebutton *ngIf="!activity.locked && !activity.completed && !disabled" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed && !disabled" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div>
  
  
