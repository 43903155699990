import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iDragAndDropFIBActivity } from '../../models/draganddropfib.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
    selector: 'littledraganddropfib',
    templateUrl: './littledraganddropfib.component.html',
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class LittleDragAndDropFIBComponent implements OnInit, OnDestroy {
    @Input() activity_id: string;
    @Output() resubmitResponse = new EventEmitter();
    sgservice: any;
    activity: iDragAndDropFIBActivity;
    selected_section: iStudentSection;
    ssservice: any;
    activity_response: iActivityResponse;
    modified_string: string;
    split_string: string[];
    words: string[];
    totalBlanks = [];
    num_words: number;
    word_bank: string[];
    directions: string;

    constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService) {
        this.split_string = [];
        this.words = [];
        this.word_bank = [];
    }

    ngOnInit() {
        this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
            this.activity = activity['data'];
            
            this.word_bank = this.activity.data.word_bank;

            if (!this.activity.response || !this.activity.response.data.length) {
                this.activity.data.word_bank.forEach((word, i) => {
                    this.totalBlanks.push([])
                });
            } else {
                this.activity.data.word_bank.forEach((word, i) => {
                    this.words.push(this.activity.response.data[word]);
                    this.totalBlanks.push([this.activity.response.data[word]]);
                    if (this.activity.response.data[word]){
                        this.word_bank = this.word_bank.filter(w => w !== word);
                    }
                });
            }

            this.word_bank.sort(() => Math.random() - 0.5);
            this.num_words = this.activity.data.word_bank.length;
            
            this.splitString();

            this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
                this.selected_section = section['data'];

                if (this.activity.data['directions'] && this.activity.data['directions'] !== ""){
                  this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
                } else if (this.selected_section.section_directions){
                  this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
                }
            })
        })
    }

    splitString(){
        this.modified_string = this.activity.data.text;

        if (this.modified_string.indexOf("<ol>") > -1){
            let ol_open_tag: number = this.modified_string.indexOf("<ol>");
            let ol_closing_tag: number = this.modified_string.indexOf("</ol>");

            //search through the string between these two indexes and replace each <li> with a div tag and an incrementing number starting at 1
            let temp_sub_string: string = this.modified_string.substring(ol_open_tag + 4, ol_closing_tag);

            let i = 1;
            while ( temp_sub_string.includes("<li>")){
                temp_sub_string = temp_sub_string.replace('<li>', '<div>' + i + '. ');
                temp_sub_string = temp_sub_string.replace('</li>', '</div>');
                i++;
            }
            
            this.modified_string = temp_sub_string;
        }

        while (this.modified_string.length > 0){
            let start_index = this.modified_string.indexOf('((');
            let end_index = this.modified_string.indexOf('))');

            if (end_index > -1){
                this.split_string.push(this.modified_string.substring(0, start_index));

                this.modified_string = this.modified_string.substr(end_index + 2, this.modified_string.length);
            } else {
                this.split_string.push(this.modified_string.substring(0, this.modified_string.length));
                this.modified_string = "";
            }   
        }
    }
    
    drop(event: CdkDragDrop<string[]>, dataarray?, id?) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            if (event.previousContainer.id == 'i') {
                if (dataarray.length) {
                    transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
                    if (event.currentIndex == 1) {
                        this.word_bank.splice(event.previousIndex, 0, dataarray[0])
                        this.totalBlanks[id] = [dataarray[1]];

                    } else {
                        this.word_bank.splice(event.previousIndex, 0, dataarray[1])
                        this.totalBlanks[id] = [dataarray[0]];
                        
                    }
                } else {
                    transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
                }

            }

            if (event.previousContainer.id != 'i') {
                let oldtarget = this.totalBlanks[event.previousContainer.id][0];
                if (dataarray.length) {
                    this.totalBlanks[event.previousContainer.id][0] = dataarray[0];
                    dataarray[0] = oldtarget;
                } else {
                    dataarray[0] = oldtarget;
                    this.totalBlanks[event.previousContainer.id] = [];
                }
            }
            
        }
    }

    drop1(event: CdkDragDrop<string[]>, index?) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    switchPositionsInArray(arr, old_index, new_index): string[] {
        if (old_index == -1){
            old_index = 0
        }
        
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        
        return arr;
    }
        
    submitActivity(show_success: boolean){
        this.words = [].concat(...this.totalBlanks);
        this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
            this.activity = activity['data'];

            interface IDictionary {
                [index: string]: string;
            }
            var student_response = {} as IDictionary;
            this.activity.data.word_bank.forEach((w, index) => {
                student_response[w] = this.words[index];
            })

            this.sharedService.submitActivityResponse(this.activity.id, student_response, show_success);
        });
    }

    resubmit(){
        this.words = [].concat(...this.totalBlanks);
        this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
            this.activity = activity['data'];

            interface IDictionary {
                [index: string]: string;
            }
            var student_response = {} as IDictionary;
            this.activity.data.word_bank.forEach((w, index) => {
                student_response[w] = this.words[index];
            })

            this.resubmitResponse.emit(student_response);
        });
    }

    ngOnDestroy() {
        if (this.sgservice){ this.sgservice.unsubscribe(); };
        if (this.ssservice){ this.ssservice.unsubscribe(); };
    }
}