<!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" viewBox="0 0 507.441 104.23" style="cursor: pointer;">
  <defs>
    <style>
      .little-view-cls-1 {
        fill: #e4e8db;
      }

      .little-view-cls-2 {
        fill: none;
      }

      .little-view-cls-3 {
        fill: #74ad99;
      }

      .cls-secondary-color {
        fill: #858798;
      }

      .little-view-cls-5 {
        clip-path: url(#clip-path);
      }

      .little-view-cls-6 {
        fill: rgba(255,255,255,0.5);
      }

      .little-view-cls-7 {
        fill: #fff;
        font-size: 32px;
        font-family: Arimo-Bold, Arimo;
        font-weight: 700;
      }

      .little-view-cls-8, .little-view-cls-9 {
        stroke: none;
      }

      .little-view-cls-9 {
        fill: #fff;
      }

      .little-view-cls-10 {
        filter: url(#View_Submitted_Activity);
      }

      .little-view-cls-11 {
        filter: url(#Path_249);
      }
    </style>
    <filter id="Path_249" x="0" y="0" width="507.441" height="104.23" filterUnits="userSpaceOnUse">
      <feOffset dx="1" dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur"/>
      <feFlood flood-opacity="0.322"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_792" data-name="Rectangle 792" class="little-view-cls-1" width="473.21" height="66.23" rx="24" transform="translate(0 -2.673)"/>
    </clipPath>
    <filter id="View_Submitted_Activity" x="7.347" y="22.652" width="491" height="60" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_275" data-name="Group 275" transform="translate(12886.452 -15995.37)">
    <g id="Group_274" data-name="Group 274" transform="translate(0 -130)">
      <g id="Group_267" data-name="Group 267">
        <g id="Group_266" data-name="Group 266" transform="translate(0 -169.654)">
          <g class="little-view-cls-11" transform="matrix(1, 0, 0, 1, -12886.45, 16295.02)">
            <g id="Path_249-2" data-name="Path 249" class="little-view-cls-2" transform="translate(16.5 15.5)">
              <path class="little-view-cls-8" d="M44.255,0H428.186c24.441,0,44.255,10.745,44.255,24V45.23c0,13.255-19.814,24-44.255,24H44.255C19.814,69.23,0,58.485,0,45.23V24C0,10.745,19.814,0,44.255,0Z"/>
              <path class="little-view-cls-9" d="M 44.25506591796875 0 C 19.81362915039062 0 0 10.74514007568359 0 23.99999618530273 L 0 45.22999572753906 C 0 58.48485565185547 19.81362915039062 69.22999572753906 44.25506591796875 69.22999572753906 L 428.186279296875 69.22999572753906 C 452.6277160644531 69.22999572753906 472.44140625 58.48485565185547 472.44140625 45.22999572753906 L 472.44140625 23.99999618530273 C 472.44140625 10.74514007568359 452.6277160644531 0 428.186279296875 0 L 44.25506591796875 0 M 44.25506591796875 -10 L 428.186279296875 -10 C 459.1167602539062 -10 482.44140625 4.616851806640625 482.44140625 23.99999618530273 L 482.44140625 45.22999572753906 C 482.44140625 64.61314392089844 459.1167602539062 79.22999572753906 428.186279296875 79.22999572753906 L 44.25506591796875 79.22999572753906 C 13.32464599609375 79.22999572753906 -10 64.61314392089844 -10 45.22999572753906 L -10 23.99999618530273 C -10 4.616851806640625 13.32464599609375 -10 44.25506591796875 -10 Z"/>
            </g>
          </g>
          <path id="Path_250" data-name="Path 250" class="little-view-cls-3" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(-12771.105 16310.524)"/>
          <path id="Path_251" data-name="Path 251" class="cls-secondary-color" d="M44.255,0H428.186c24.441,0,44.255,10.745,44.255,24V45.23c0,13.255-19.814,24-44.255,24H44.255C19.814,69.23,0,58.485,0,45.23V24C0,10.745,19.814,0,44.255,0Z" transform="translate(-12869.952 16310.524)"/>
          <g id="Mask_Group_69" data-name="Mask Group 69" class="little-view-cls-5" transform="translate(-12870.105 16313.197)">
            <path id="Path_252" data-name="Path 252" class="little-view-cls-6" d="M496.41,31.2c0,9.568-105.925,17.361-236.605,17.361S23.2,40.773,23.2,31.2,128.55,13.4,259.229,13.4,496.41,21.637,496.41,31.2Z" transform="translate(-23.2 -32.051)"/>
          </g>
          <g class="little-view-cls-10" transform="matrix(1, 0, 0, 1, -12886.45, 16295.02)">
            <text id="View_Submitted_Activity-2" data-name="View Submitted Activity" class="little-view-cls-7" transform="translate(16.35 28.65)"><tspan x="55.813" y="29">View Submitted Activity</tspan></text>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg> -->

<div style="width: 100%;">
  <div class="little-activity-view-submission-button">
    View Submitted Activity
  </div>
</div>


