import { Component, OnInit, OnDestroy } from '@angular/core';

import { SharedService } from '../../shared/services/shared.service';

import { StudentGradesService } from '../../services/StudentGrades.service';
import { iStudentNavByUnit } from '../../models/studentunits.interface';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'unitnav',
    templateUrl: './unitnav.component.html',
    providers: [ StudentGradesService ]
})

export class UnitNavComponent implements OnInit, OnDestroy {
  navbar_collapsed: boolean;
  sgservice: any;
  student_nav: iStudentNavByUnit;
  nav_icon_selected: string;
  selected_unit_id: string;
  selected_lesson_id: string;
  lesson_aka: string;

  constructor(
    private sharedService: SharedService,
    private sgServ: StudentGradesService,
    private authServ: AuthService) {
      this.sharedService.getSelectedLessonId().subscribe((value) => {
        this.selected_lesson_id = value;
      })
      this.lesson_aka = this.authServ.getCurriculumType() === "School" ? "Lesson" : "Session";
      this.sharedService.setReloadNavMenu(false);
      this.sharedService.getReloadNavMenu().subscribe((value) => {
        if (value){
          this.getUnitNav();
        }
      })
  }

  ngOnInit() {
    this.sharedService.navbarCollapsedSubject.subscribe((value) => {
      this.navbar_collapsed = value;
    })

    this.sharedService.getCurrentPage().subscribe((value) => {
      if(value){
        this.nav_icon_selected = value;
      }
    })

    this.sharedService.getSelectedUnit().subscribe((value) => {
      if (value){
        this.selected_unit_id = value.id.toString();
        this.getUnitNav();
      }
    })
  }

  collapseVerticalNavbar(value: boolean){
    this.sharedService.collapseNavbar(value);
  }

  getUnitNav(){
    this.sgservice = this.sgServ.GetStudentNavByUnit(this.selected_unit_id).subscribe((nav: iStudentNavByUnit) => {
      this.student_nav = nav['data']['lessons'];
    })
  }

  setLessonAndSection(lesson_id, section_id) {
    this.sharedService.setSelectedLessonId(lesson_id);
    this.sharedService.setSelectedSectionId(section_id);
    window.scroll(0,0);
  }

  tempActive(event, lesson_id, selected_lesson_id) {
    let el = document.getElementById(lesson_id);
    
    if (event.type == 'mouseover'){
      el.classList.add('big-active-lesson-nav');
    } else {
      if (lesson_id !== selected_lesson_id){
        el.classList.remove('big-active-lesson-nav');
      }
    }
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
  }
}
