import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

import { SharedService } from '../../shared/services/shared.service';

@Component({
    selector: 'bigvertcollapsed',
    templateUrl: './bigvertcollapsed.component.html',
    providers: [ ]
})

export class BigVertCollapsedComponent implements OnInit, OnDestroy {
  navbar_collapsed: boolean;
  open_notifications: any;
  bell_classes: string;
  curriculum_type: string;

  constructor( private sharedService: SharedService, private elRef: ElementRef, private authService: AuthService ) { 
    this.curriculum_type = this.authService.getCurriculumType();
    this.navbar_collapsed = true; 
    this.sharedService.navbarCollapsedSubject.subscribe((value) => {
      this.navbar_collapsed = value;
    })
  }

  ngOnInit() { 
    // const parentElement = this.elRef.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    this.open_notifications = document.getElementById('notifications-modal');

    this.sharedService.getBellClasses().subscribe((value) => {
      this.bell_classes = value;
    })
  }

  collapseVerticalNavbar(value: boolean){
    this.sharedService.collapseNavbar(value);
  }

  setSelected(type: string){
    this.sharedService.setCurrentPage(type);
  }

  triggerNotifications(){
    this.sharedService.getNotifications();
  }
  
  ngOnDestroy() { }
}
