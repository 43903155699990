<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>

  <div class="row">
    <div *ngFor="let image of images" class="col-md-6" style="margin-bottom: 40px; position: relative;">
      <span *ngIf="image.selected">
        <i class="fas fa-check-square fa-2x choose-icon"></i>
      </span>
      <img [src]="image.file" 
           class="rounded" 
           style="width:100%; cursor: pointer;" 
           (click)="selectImage(image)"
           [ngStyle]="{'border': image.selected ? border_style : ''}" />
    </div>
  </div>


  <div class="text-center save-activity-button">
    <littleactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></littleactivitysubmittedbutton>
  </div>
</div>