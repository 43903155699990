import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iProduct } from '../models/product.interface';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
    })
  };

@Injectable()
export class ProductService {
    constructor(private http: HttpClient) { }

    CreateProduct(Product: iProduct): Observable<iProduct> {
        return this.http.post<iProduct>(environment.conn_string + "api/products", Product, httpOptions);
    }

    UpdateProduct(Product: iProduct): Observable<iProduct> {
        return this.http.put<iProduct>(environment.conn_string + "api/products/" + Product.id, Product, httpOptions);
    }

    GetProductDetails(ProductId: string) {
        return this.http.get(environment.conn_string + "api/products/details/" + ProductId, httpOptions);
    }

    GetProducts(): Observable<iProduct[]> {
        return this.http.get<iProduct[]>(environment.conn_string + "api/products", httpOptions);
    }
}