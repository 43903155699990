import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iTextActivity } from '../../models/textactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';
import { PusherService } from '../../services/Pusher.service';
import { ToastrService } from 'ngx-toastr';

declare var UnityLoader: any;
declare var UnityProgress: any;

@Component({
    selector: 'drivinggame',
    templateUrl: './drivinggame.component.html',
    styleUrls: ['./drivinggame.component.scss'],
    providers: [ StudentGradesService, StudentSectionsService, PusherService ]
})

export class DrivingGameComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iTextActivity;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  gameInstance: any;
  scaleToFit: boolean;
  directions: string;
  disabled: boolean;
  pusher_activity_id: string;
  pusher_student_id: number;
  pusher_submitted: boolean;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService,
    private pusherServ: PusherService,
    private toastr: ToastrService ) {
      this.disabled = true;
      this.pusher_submitted = false;
    }

  ngOnInit() {
    this.pusherServ.channel.bind('my-event', data => {
      this.pusher_activity_id = data.activity_id;
      this.pusher_student_id = data.student_id;
      
      if (this.pusher_student_id.toString() === this.authService.getId() && this.pusher_activity_id === this.activity.id){
        this.pusher_submitted = true;
        this.disabled = false;
      }
    })

    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data['directions'] && this.activity.data['directions'] !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  setupGame(){
    let activity_id = this.activity.id;
    let section_id = this.activity.section_id;
    let lesson_id = localStorage.getItem('selectedLessonId');
    this.sharedService.getSelectedLessonId().subscribe(id => {
      if (id){
        lesson_id = id;
      }
    });
    let unit_id;
    this.sharedService.getSelectedUnit().subscribe(unit => {
      unit_id = unit.id;
    });
    let curriculum_id = this.authService.getCurriculumId();
    let token = this.authService.getToken();

    window['jsonDataString'] = '{"activityId":"' + activity_id + '", "sectionId":' + section_id + ', "unitId":' + unit_id + ', "lessonId":' + lesson_id + ', "token":"' + token + '", "curriculumId":' + curriculum_id + '}';
    this.gameInstance = UnityLoader.instantiate("unityContainer", "app/activities/drivinggame/Build/126eeb8723863ddd6b9abd882fe3fab9.json",{onProgress: UnityProgress});
    
    try {
      this.scaleToFit = !!JSON.parse("true");
    } catch (e) {
      this.scaleToFit = true;
    }
    
    window.addEventListener('resize', this.onResize);
  }

  onResize() {
    var canvas = this.gameInstance.Module.canvas;
    var container = this.gameInstance.container;
    var w;
    var h;

    if (this.scaleToFit) {
      w = window.innerWidth;
      h = window.innerHeight;

      var r = 720 / 1020;

      if (w * r > window.innerHeight) {
        w = Math.min(w, Math.ceil(h / r));
      }
      h = Math.floor(w * r);
    } else {
      w = 1020;
      h = 720;
    }

    container.style.width = canvas.style.width = w + "px";
    container.style.height = canvas.style.height = h + "px";
    container.style.top = Math.floor((window.innerHeight - h) / 2) + "px";
    container.style.left = Math.floor((window.innerWidth - w) / 2) + "px";
  }

  submitActivity(show_success: boolean){
    this.toastr.success('Activity saved!');
    //Refresh this page and nav menu
    this.sharedService.setReloadSectionActivities(true);
    this.sharedService.setReloadNavMenu(true);
  }

  resubmit(){
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];
      
      this.sharedService.resubmitActivityResponse(this.activity.id, this.activity.response.data , true);
      //Refresh this page and nav menu
      this.sharedService.setReloadSectionActivities(true);
      this.sharedService.setReloadNavMenu(true);
    })
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); }; 
    var el = document.getElementById('drivinggame-modal' + this.activity.activity_number);
    if (el) {
      el.remove();   
    } 
  }
}
