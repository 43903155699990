import { Component } from '@angular/core';

@Component({
    selector: 'bighotspotbutton',
    templateUrl: './bighotspotbutton.component.html'
})

export class BigHotSpotButton {

  constructor( ) {  }
}
