import { Component } from '@angular/core';

@Component({
    selector: 'littleactivitydonedisabledbutton',
    templateUrl: './littleactivitydonedisabledbutton.component.html'
})

export class LittleActivityDoneDisabledButton {

  constructor( ) {  }
}
