import { Injectable } from '@angular/core';

declare const Pusher: any;

@Injectable()
export class PusherService {
    pusher: any;
    channel: any;

    constructor() {
        this.pusher = new Pusher('703446197d942a22417c', {
            cluster: 'us2',
            encrypted: true
        });

        this.channel = this.pusher.subscribe('my-channel');
    }
}