import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { iStudentNavUnits } from '../../models/studentunits.interface';
import { iStudentNotifications } from '../../models/notification.interface';
import { NotificationService } from '../../services/Notification.service';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { iActivityResponse } from '../../models/studentactivities.interface';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SharedService {
    html: any;
    activity_response: iActivityResponse;

    //Lesson Id
    private lesson_id: BehaviorSubject<string> = new BehaviorSubject('');

    getSelectedLessonId(): Observable<string> {
        return this.lesson_id.asObservable();
    }

    setSelectedLessonId(id: string) {
        this.lesson_id.next(id);
    }

    //Section Id
    private section_id: BehaviorSubject<string> = new BehaviorSubject('');

    getSelectedSectionId(): Observable<string> {
        return this.section_id.asObservable();
    }

    setSelectedSectionId(id: string) {
        this.section_id.next(id);
    }

    //Selected Unit
    private selected_unit: BehaviorSubject<iStudentNavUnits> = new BehaviorSubject(null);

    getSelectedUnit(): Observable<iStudentNavUnits> {
        return this.selected_unit.asObservable();
    }

    setSelectedUnit(unit: iStudentNavUnits) {
        this.selected_unit.next(unit);
    }

    //Current Page
    private current_page: BehaviorSubject<string> = new BehaviorSubject('');

    getCurrentPage(): Observable<string> {
        return this.current_page.asObservable();
    }

    setCurrentPage(page: string) {
        this.current_page.next(page);
    }

    //Notifications
    private read_notifications: BehaviorSubject<iStudentNotifications[]> = new BehaviorSubject(null);

    getReadNotifications(): Observable<iStudentNotifications[]> {
        return this.read_notifications.asObservable();
    }

    setReadNotifications(read: iStudentNotifications[]) {
        this.read_notifications.next(read);
    }

    private unread_notifications: BehaviorSubject<iStudentNotifications[]> = new BehaviorSubject(null);

    getUnreadNotifications(): Observable<iStudentNotifications[]> {
        return this.unread_notifications.asObservable();
    }

    setUnreadNotifications(read: iStudentNotifications[]) {
        this.unread_notifications.next(read);
    }

    private bell_classes: BehaviorSubject<string> = new BehaviorSubject('');

    getBellClasses(): Observable<string> {
        return this.bell_classes.asObservable();
    }

    setBellClasses(classes: string) {
        this.bell_classes.next(classes);
    }

    getNotifications(){
        let notifications: iStudentNotifications[] = [];
        let n_unread: iStudentNotifications[] = [];
        let n_read: iStudentNotifications[] = [];
        let nServ: NotificationService;

        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem('token')
            })
        };

        const req = this.http
        .get<iStudentNotifications[]>(
            environment.conn_string + 'api/notifications/student_messages_native_web', 
            httpOptions
        )
        .subscribe((n:iStudentNotifications[]) => {
          notifications = n['data']['student_notifications'];
          
          notifications.forEach(n => {
            n['timespan'] = moment(n.created_at).fromNow();
            if (n.read){
              n_read.push(n);
            } else {
              n_unread.push(n);
            }
          })

          this.setReadNotifications(n_read);
          this.setUnreadNotifications(n_unread);
          
          if (n_unread.length > 0){
            this.setBellClasses("nav-link notification-indicator notification-indicator-primary px-0 icon-indicator");
          } else {
            this.setBellClasses("nav-link notification-indicator-primary px-0 icon-indicator");
          }
        })
    }

    //Navbar
    navbar_collapsed: boolean;
    navbarCollapsedSubject: Subject<boolean> = new Subject<boolean>();

    collapseNavbar(value: boolean){
        this.navbar_collapsed = value;
        this.navbarCollapsedSubject.next(this.navbar_collapsed);

        this.html = document.querySelector('html');
        if (value){
            if (!this.html.classList.contains('navbar-vertical-collapsed')){
                this.html.classList.add('navbar-vertical-collapsed');
            }
        } else {
            if (this.html.classList.contains('navbar-vertical-collapsed')){
                this.html.classList.remove('navbar-vertical-collapsed');
            }
        }
    }

    private reload_nav_menu: BehaviorSubject<boolean> = new BehaviorSubject(false);

    getReloadNavMenu(): Observable<boolean> {
        return this.reload_nav_menu.asObservable();
    }

    setReloadNavMenu(value: boolean) {
        this.reload_nav_menu.next(value);
    }

    private reload_section_activities: BehaviorSubject<boolean> = new BehaviorSubject(false);

    getReloadSectionActivities(): Observable<boolean> {
        return this.reload_section_activities.asObservable();
    }

    setReloadSectionActivities(value: boolean) {
        this.reload_section_activities.next(value);
    }

    constructor(
        private http: HttpClient,
        private toastr: ToastrService
    )  { }

    //Submit activity success
    private success: BehaviorSubject<boolean> = new BehaviorSubject(false);

    getSubmitActivitySuccess(): Observable<boolean> {
        return this.success.asObservable();
    }

    setSubmitActivitySuccess(value: boolean) {
        this.success.next(value);
    }

    submitActivityResponse(activity_id, response_data, show_success: boolean) {
        let unit_id: number;
        this.getSelectedUnit().subscribe(unit => {
            unit_id = unit.id;
        });
        
        
        this.activity_response = {
            activity_id: activity_id,
            section_id: parseInt(this.section_id.value),
            lesson_id: parseInt(this.lesson_id.value),
            unit_id: unit_id,
            data: {data: response_data}
        }
        
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem('token')
            })
        };

        const req = this.http
        .post<iActivityResponse>(
            environment.conn_string + 'api/activity_responses/submit',
            this.activity_response,
            httpOptions
        )
        .subscribe(
            res => {
                this.setSubmitActivitySuccess(true);
                if(show_success){
                    this.toastr.success('Activity saved!');
                }
            },
            err => {
                this.toastr.error('There was an error while trying to save your activity response!')
                this.setSubmitActivitySuccess(false);
            }
        );
    }

    resubmitActivityResponse(activity_id, response_data, show_success: boolean) {
        let unit_id: number;
        this.getSelectedUnit().subscribe(unit => {
            unit_id = unit.id;
        });
        
        this.activity_response = {
            activity_id: activity_id,
            section_id: parseInt(localStorage.getItem("selectedSectionId")),
            lesson_id: parseInt(localStorage.getItem("selectedLessonId")),
            unit_id: unit_id,
            data: {data: response_data}
        }
        
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem('token')
            })
        };

        const req = this.http
        .post<iActivityResponse>(
            environment.conn_string + 'api/activity_responses/resubmit',
            this.activity_response,
            httpOptions
        )
        .subscribe(
            res => {
                this.setSubmitActivitySuccess(true);
                if(show_success){
                    this.toastr.success('Activity resubmitted!');
                    this.setReloadNavMenu(true);
                }
            },
            err => {
                this.toastr.error('There was an error while trying to resubmit your activity response!')
                this.setSubmitActivitySuccess(false);
            }
        );
    }

    addPopups(text: string, dict): string{
        let popup_text = text;
    
        popup_text.split(" ").forEach(word => {
            if (word.indexOf("((") != -1){
                let start;
                let end;
                let cleaned_word;
                let new_word;
                let final_word;
                if (word.indexOf("))") != -1){
                    start = word.indexOf("((") + 2;
                    end = word.indexOf("))");
                    cleaned_word = word.substring(start, end);

                    if (dict[cleaned_word]) {
                        let title = dict[cleaned_word];
                        do {
                            title = title.replace("'", "&#39");
                        } while (title.indexOf("'") != -1);
                        new_word = "<span data-toggle='tooltip' title='" + title + "' class='color-primary'><strong>" + cleaned_word + "</strong></span>";
                    }

                    final_word = word.replace(cleaned_word, new_word);
                    final_word = final_word.replace("((", "");
                    final_word = final_word.replace("))", "");
            
                    popup_text = popup_text.replace(word, final_word);
                } else {
                    end = popup_text.indexOf("))");
                    start = popup_text.indexOf("((") + 2;
                    cleaned_word = popup_text.substring(start, end);

                    if (dict[cleaned_word]) {
                        let title = dict[cleaned_word];
                        do {
                            title = title.replace("'", "&#39");
                        } while (title.indexOf("'") != -1);
                        new_word = "<span data-toggle='tooltip' title='" + title + "' class='color-primary'><strong>" + cleaned_word + "</strong></span>";
                        console.log(new_word);
                    }
                    word = popup_text.substring(start - 2, end + 2);

                    final_word = word.replace(cleaned_word, new_word);
                    final_word = final_word.replace("((", "");
                    final_word = final_word.replace("))", "");
            
                    popup_text = popup_text.replace(word, final_word);
                }
            }
        });
        
        return popup_text;
    }
}