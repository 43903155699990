import { Component } from '@angular/core';

@Component({
    selector: 'biginfoicon',
    templateUrl: './biginfoicon.component.html'
})

export class BigInfoIcon {

  constructor( ) {  }
}
