// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import 'rxjs/add/operator/do';

import { AuthService } from './auth.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, public auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let change_token: boolean = true;

    if (this.router.url.includes('productorderform') || 
        this.router.url.includes('instituteadminsetup')) {
          change_token = false;
    }
    
    request = request.clone({
      setHeaders: {
        Authorization: change_token ? `${this.auth.getToken()}` : 'jagyw1xbTYRq15AJF2uP6k3z'
      }
    });
    return next.handle(request).do(
      succ => {},
      err => {
        if (err.status == 401) {
          // this.auth.logOut();
        }
      }
    );
      
  }
}
