<!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="150" viewBox="0 0 291.21 105.5" style="cursor: pointer;">
  <defs>
    <style>
      .cls-1-little-section-submit {
        fill: #83b61f;
      }

      .cls-2-little-section-submit {
        fill: none;
      }

      .cls-primary-color {
        fill: #74ad99;
      }

      .cls-4-little-section-submit {
        clip-path: url(#clip-path);
      }

      .cls-secondary-color {
        fill: #98d4c2;
      }

      .cls-6-little-section-submit, .cls-9-little-section-submit {
        fill: #fff;
      }

      .cls-6-little-section-submit {
        font-size: 32px;
        font-family: Arimo-Bold, Arimo;
        font-weight: 700;
      }

      .cls-7-little-section-submit {
        isolation: isolate;
      }

      .cls-8-little-section-submit {
        fill: #d3d3d3;
      }

      .cls-10-little-section-submit {
        fill: #e3e3e3;
      }

      .cls-11-little-section-submit, .cls-12-little-section-submit {
        stroke: none;
      }

      .cls-12-little-section-submit {
        fill: #fff;
      }

      .cls-13-little-section-submit {
        filter: url(#Path_232);
      }

      .cls-14-little-section-submit {
        filter: url(#Path_231);
      }

      .cls-15-little-section-submit {
        filter: url(#Path_230);
      }

      .cls-16-little-section-submit {
        filter: url(#Send_to_Teacher);
      }

      .cls-17-little-section-submit {
        filter: url(#Path_249);
      }
    </style>
    <filter id="Path_249" x="0" y="0" width="291.21" height="104.23" filterUnits="userSpaceOnUse">
      <feOffset dx="1" dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur"/>
      <feFlood flood-opacity="0.322"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_792" data-name="Rectangle 792" class="cls-1-little-section-submit" width="254.21" height="66.23" rx="24" transform="translate(389 653)"/>
    </clipPath>
    <filter id="Send_to_Teacher" x="61.605" y="11.5" width="202" height="94" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Path_230" x="43.821" y="29.197" width="46.226" height="51.836" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-3"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-3"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Path_231" x="31.264" y="29.197" width="58.783" height="48.375" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-4"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-4"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Path_232" x="42.962" y="29.197" width="47.085" height="51.836" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-5"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-5"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_260" data-name="Group 260" transform="translate(12787.605 -16296.697)">
    <g class="cls-17-little-section-submit" transform="matrix(1, 0, 0, 1, -12787.61, 16296.7)">
      <g id="Path_249-2" data-name="Path 249" class="cls-2-little-section-submit" transform="translate(16.5 15.5)">
        <path class="cls-11-little-section-submit" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z"/>
        <path class="cls-12-little-section-submit" d="M 24 0 C 10.74516296386719 0 0 10.74516296386719 0 23.99999618530273 L 0 45.22999572753906 C 0 58.48483276367188 10.74516296386719 69.22999572753906 24 69.22999572753906 L 232.2099914550781 69.22999572753906 C 245.4648284912109 69.22999572753906 256.2099914550781 58.48483276367188 256.2099914550781 45.22999572753906 L 256.2099914550781 23.99999618530273 C 256.2099914550781 10.74516296386719 245.4648284912109 0 232.2099914550781 0 L 24 0 M 24 -10 L 232.2099914550781 -10 C 250.9576568603516 -10 266.2099914550781 5.252330780029297 266.2099914550781 23.99999618530273 L 266.2099914550781 45.22999572753906 C 266.2099914550781 63.97766494750977 250.9576568603516 79.22999572753906 232.2099914550781 79.22999572753906 L 24 79.22999572753906 C 5.252334594726562 79.22999572753906 -10 63.97766494750977 -10 45.22999572753906 L -10 23.99999618530273 C -10 5.252330780029297 5.252334594726562 -10 24 -10 Z"/>
      </g>
    </g>
    <path id="Path_250" data-name="Path 250" class="cls-primary-color" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(-12771.105 16314.197)"/>
    <path id="Path_251" data-name="Path 251" class="cls-primary-color" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(-12771.105 16312.197)"/>
    <g id="Mask_Group_69" data-name="Mask Group 69" class="cls-4-little-section-submit" transform="translate(-13159.105 15660.197)">
      <path id="Path_252" data-name="Path 252" class="cls-secondary-color" d="M306.942,32.768c0,10.408-63.514,18.886-141.871,18.886S23.2,43.176,23.2,32.768,86.369,13.4,164.726,13.4,306.942,22.36,306.942,32.768Z" transform="translate(350.616 619.311)"/>
    </g>
    <g class="cls-16-little-section-submit" transform="matrix(1, 0, 0, 1, -12787.61, 16296.7)">
      <text id="Send_to_Teacher-2" data-name="Send to Teacher" class="cls-6-little-section-submit" transform="translate(70.61 17.5)"><tspan x="33.336" y="29">Send to </tspan><tspan x="31.82" y="59">Teacher</tspan></text>
    </g>
    <g id="Group_259" data-name="Group 259" class="cls-7-little-section-submit" transform="translate(-13593 11433.697)">
      <g id="Group_221" data-name="Group 221">
        <g class="cls-15-little-section-submit" transform="matrix(1, 0, 0, 1, 805.39, 4863)">
          <path id="Path_230-2" data-name="Path 230" class="cls-8-little-section-submit" d="M-1930.284,1811.3l28.225-33.835-26.4,23.08Z" transform="translate(1983.11 -1742.27)"/>
        </g>
        <g class="cls-14-little-section-submit" transform="matrix(1, 0, 0, 1, 805.39, 4863)">
          <path id="Path_231-2" data-name="Path 231" class="cls-9-little-section-submit" d="M-2474.867,1793.386l29.037,14.458,11.746-30.375Z" transform="translate(2515.13 -1742.27)"/>
        </g>
        <g class="cls-13-little-section-submit" transform="matrix(1, 0, 0, 1, 805.39, 4863)">
          <path id="Path_232-2" data-name="Path 232" class="cls-10-little-section-submit" d="M-1967.579,1799.211l.86,12.094,1.828-10.755,26.4-23.08Z" transform="translate(2019.54 -1742.27)"/>
        </g>
      </g>
    </g>
  </g>
</svg> -->

<div style="width: 100%;">
  <div class="little-section-submit-button">
    <div class="row">
      <div class="col-md-1" style="padding-left: 30px; padding-top: 7px;">
        <i class="fad fa-paper-plane icon"></i>
      </div>
      <div class="col-md-8">
        <div style="z-index: 10000 !important; position:absolute; left: 0px;">
          Send to Teacher
        </div>
      </div>
    </div>
  </div>
</div>