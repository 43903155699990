import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { iProductCategory } from '../models/productcategories.interface';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
    })
  };

@Injectable()
export class ProductCategoryService {
    constructor(private http: HttpClient) { }

    GetProductCategoryDetails(ProductCategoryId: string) {
        return this.http.get(environment.conn_string + "api/product_categories/details/" + ProductCategoryId, httpOptions);
    }

    GetProductCategories(): Observable<iProductCategory[]> {
        return this.http.get<iProductCategory[]>(environment.conn_string + "api/product_categories", httpOptions);
    }
}