<ul *ngIf="!navbar_collapsed" class="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center" style="width: 100%;">
  <li class="nav-item horizontal-nav-items nav-background">
    <a class="nav-link" [routerLink]="['/app/unitlanding']" (click)="collapseVerticalNavbar(true); setSelected('landing');">
      <div class="d-flex align-items-center">
        <span class="nav-link-icon">
            <img src="../../../assets/img/icons/landing-page.png" />
        </span>
      </div>
    </a>
  </li>
  <li class="nav-item horizontal-nav-items nav-background">
    <a [class]="unit_nav_class" (click)="setSelected('nav')">
      <div class="d-flex align-items-center">
        <span class="nav-link-icon">
            <img src="../../../assets/img/icons/activities-munano.png" style="margin-left: 4px;" />
        </span>
      </div>
    </a>
  </li>
  <li class="nav-item horizontal-nav-items nav-background" *ngIf="curriculum_type == 'School'">
    <a [class]="vocab_class" (click)="setSelected('vocab')">
      <div class="d-flex align-items-center">
        <span class="nav-link-icon">
            <img src="../../../assets/img/icons/vocab.png" style="margin-left: 2px;" />
        </span>
      </div>
    </a>
  </li>
  <li class="nav-item horizontal-nav-items">
    <div class="vertical_divider"></div>
  </li>
  <li class="nav-item horizontal-nav-items nav-background">
    <a class="nav-link" href="#" [routerLink]="['/app/saintcards']" (click)="collapseVerticalNavbar(true); setSelected('cards');">
      <div class="d-flex align-items-center">
        <span class="nav-link-icon">
            <img src="../../assets/img/icons/saint-cards.png" />
        </span>
      </div>
    </a>
  </li>
  <li class="nav-item horizontal-nav-items">
    <div class="vertical_divider"></div>
  </li>
  <li class="nav-item nav-background">
    <a [ngClass]="bell_classes" class="navbar_indicator" href="#notifications-modal" role="button" data-toggle="modal" (click)="triggerNotifications()">
      <div class="d-flex align-items-center">
        <span class="nav-link-icon">
          <span class="fas fa-bell fs-4" data-fa-transform="shrink-6" style="color:white;"></span>
        </span>
      </div>
    </a>
  </li>
</ul>
      