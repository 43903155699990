<div *ngIf="respondForm && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>
  <form [formGroup]="respondForm">
    <div formArrayName="itemRows">
      <div *ngFor="let itemrow of getControls(); let i = index" [formGroupName]="i" class="respond-wrapper">
          <span [innerHtml]="activity.data.questions[i].question | safeHtml"></span>
          <textarea class="form-control big-text-area" rows="3" formControlName="answer" name="answer"></textarea>
          <div class="auto-save-label">autosaved {{ saved_time | date:'shortTime' }}</div>
      </div>
    </div>
  </form>

  <div class="text-center save-activity-button">
    <bigactivitysavebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></bigactivitysavebutton>
    <bigactivitysavebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></bigactivitysavebutton>
    <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div>
