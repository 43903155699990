<div *ngIf="activity && selected_section">
  <span (click)="goBack();" [hidden]="!hidden" class="go_back">
    <i class="far fa-arrow-left"></i> Go back
  </span>
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" style="margin-bottom: 20px;"></div>
  <div class="row">
    <div *ngFor="let group of activity.data" class="col-md-6">
      <div class="group_card" (click)="setGroup(group.id);" [hidden]="hidden">
        {{ group.activity_title }}
      </div>
    </div>
  </div>

  <div *ngIf="selected_group_id">
    <div *ngIf="activity.data[selected_group_id].text !== ''" [innerHtml]="activity.data[selected_group_id].text | safeHtml" style="margin-bottom: 40px;"></div>
    
    <form [formGroup]="respondForm">
      <div formArrayName="itemRows">
        <div *ngFor="let itemrow of getControls(); let i = index" [formGroupName]="i" class="respond-wrapper">
          {{ activity.data[selected_group_id].questions[i].question }}
          <textarea *ngIf="!activity.locked || !activity.completed" class="form-control big-text-area" rows="3" [formControl]="respondForm.controls['itemRows'].controls[i].controls['answer']" name="answer"></textarea>
          <textarea *ngIf="activity.locked && activity.completed && selected_group_id == response_group_id" class="form-control big-text-area" rows="3" [formControl]="respondForm.controls['itemRows'].controls[i].controls['answer']" name="answer"></textarea>
          <textarea *ngIf="activity.locked && activity.completed && selected_group_id != response_group_id" class="form-control big-text-area" rows="3" disabled></textarea>
          <div class="auto-save-label">autosaved {{ saved_time | date:'shortTime' }}</div>
        </div>
      </div>
    </form>
  </div>

  <div class="text-center save-activity-button">
    <bigactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></bigactivitydonebutton>
    <bigactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></bigactivitysubmittedbutton>
  </div>
</div>