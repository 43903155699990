import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { VerticalNavBarComponent } from "./verticalnavbar.component";
import { BigVertCollapsedModule } from "../bigvertcollapsed/bigvertcollapsed.module";
import { LittleVertCollapsedModule } from "../littlevertcollapsed/littlevertcollapsed.module";
import { BigVertExpandModule } from "../bigvertexpanded/bigvertexpanded.module";
import { LittleVertExpandedModule } from "../littlevertexpanded/littlevertexpanded.module";
import { UnitVocabModule } from "../unitvocab/unitvocab.module";
import { UnitNavModule } from "../unitnav/unitnav.module";
import { LittleUnitNavModule } from "../littleunitnav/littleunitnav.module";

const VERTICAL_NAV_BAR_ROUTE = [
    { path: '', component: VerticalNavBarComponent }
];

@NgModule ({
    declarations: [
        VerticalNavBarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(VERTICAL_NAV_BAR_ROUTE),
        BigVertCollapsedModule,
        LittleVertCollapsedModule,
        BigVertExpandModule,
        LittleVertExpandedModule,
        UnitVocabModule,
        UnitNavModule,
        LittleUnitNavModule
    ],
    exports: [
        VerticalNavBarComponent
    ]
})

export class VerticalNavBarModule {  }