<!-- <span data-toggle="tooltip" data-placement="top" title="Mark each step DONE to submit to teacher">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="150" viewBox="0 0 291.21 104.5">
    <defs>
      <filter id="Path_245" x="0" y="0" width="291.21" height="104.23" filterUnits="userSpaceOnUse">
        <feOffset dx="1" dy="2" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="2.5" result="blur"/>
        <feFlood flood-opacity="0.322"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
      <filter id="Send_to_Teacher" x="61.605" y="10.5" width="202" height="94" filterUnits="userSpaceOnUse">
        <feOffset dy="3" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="3" result="blur-2"/>
        <feFlood flood-opacity="0.161"/>
        <feComposite operator="in" in2="blur-2"/>
        <feComposite in="SourceGraphic"/>
      </filter>
      <filter id="Path_230" x="43.821" y="29.197" width="46.226" height="51.836" filterUnits="userSpaceOnUse">
        <feOffset dy="3" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="3" result="blur-3"/>
        <feFlood flood-opacity="0.161"/>
        <feComposite operator="in" in2="blur-3"/>
        <feComposite in="SourceGraphic"/>
      </filter>
      <filter id="Path_231" x="31.264" y="29.197" width="58.783" height="48.375" filterUnits="userSpaceOnUse">
        <feOffset dy="3" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="3" result="blur-4"/>
        <feFlood flood-opacity="0.161"/>
        <feComposite operator="in" in2="blur-4"/>
        <feComposite in="SourceGraphic"/>
      </filter>
      <filter id="Path_232" x="42.962" y="29.197" width="47.085" height="51.836" filterUnits="userSpaceOnUse">
        <feOffset dy="3" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="3" result="blur-5"/>
        <feFlood flood-opacity="0.161"/>
        <feComposite operator="in" in2="blur-5"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Group_273" data-name="Group 273" transform="translate(10693.605 -17235)">
      <g id="Group_258" data-name="Group 258" transform="translate(-1 -68)">
        <g id="Group_257" data-name="Group 257" transform="translate(0 2)">
          <g transform="matrix(1, 0, 0, 1, -10692.61, 17301)" filter="url(#Path_245)">
            <g id="Path_245-2" data-name="Path 245" transform="translate(16.5 15.5)" fill="none">
              <path d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" stroke="none"/>
              <path d="M 24 0 C 10.74516296386719 0 0 10.74516296386719 0 23.99999618530273 L 0 45.22999572753906 C 0 58.48483276367188 10.74516296386719 69.22999572753906 24 69.22999572753906 L 232.2099914550781 69.22999572753906 C 245.4648284912109 69.22999572753906 256.2099914550781 58.48483276367188 256.2099914550781 45.22999572753906 L 256.2099914550781 23.99999618530273 C 256.2099914550781 10.74516296386719 245.4648284912109 0 232.2099914550781 0 L 24 0 M 24 -10 L 232.2099914550781 -10 C 250.9576568603516 -10 266.2099914550781 5.252330780029297 266.2099914550781 23.99999618530273 L 266.2099914550781 45.22999572753906 C 266.2099914550781 63.97766494750977 250.9576568603516 79.22999572753906 232.2099914550781 79.22999572753906 L 24 79.22999572753906 C 5.252334594726562 79.22999572753906 -10 63.97766494750977 -10 45.22999572753906 L -10 23.99999618530273 C -10 5.252330780029297 5.252334594726562 -10 24 -10 Z" stroke="none" fill="#fff"/>
            </g>
          </g>
          <path id="Path_246" data-name="Path 246" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(-10676.105 17318.5)" fill="rgba(116,173,153,0)"/>
          <path id="Path_247" data-name="Path 247" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(-10676.105 17317.5)" fill="#707071"/>
          <g transform="matrix(1, 0, 0, 1, -10692.61, 17301)" filter="url(#Send_to_Teacher)">
            <text id="Send_to_Teacher-2" data-name="Send to Teacher" transform="translate(70.61 16.5)" fill="#fff" font-size="32" font-family="MuseoSans-900, Museo Sans" font-weight="900"><tspan x="32.864" y="30">Send to </tspan><tspan x="30.192" y="60">Teacher</tspan></text>
          </g>
          <g id="Group_256" data-name="Group 256" transform="translate(-11498 12438)" style="isolation: isolate">
            <g id="Group_221" data-name="Group 221">
              <g transform="matrix(1, 0, 0, 1, 805.39, 4863)" filter="url(#Path_230)">
                <path id="Path_230-2" data-name="Path 230" d="M-1930.284,1811.3l28.225-33.835-26.4,23.08Z" transform="translate(1983.11 -1742.27)" fill="#d3d3d3"/>
              </g>
              <g transform="matrix(1, 0, 0, 1, 805.39, 4863)" filter="url(#Path_231)">
                <path id="Path_231-2" data-name="Path 231" d="M-2474.867,1793.386l29.037,14.458,11.746-30.375Z" transform="translate(2515.13 -1742.27)" fill="#fff"/>
              </g>
              <g transform="matrix(1, 0, 0, 1, 805.39, 4863)" filter="url(#Path_232)">
                <path id="Path_232-2" data-name="Path 232" d="M-1967.579,1799.211l.86,12.094,1.828-10.755,26.4-23.08Z" transform="translate(2019.54 -1742.27)" fill="#e3e3e3"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</span> -->

<div style="width: 100%;">
  <div class="little-activity-submit-disabled-button">
    <div class="row">
      <div class="col-md-1" style="padding-left: 30px; padding-top: 7px;">
        <i class="fad fa-paper-plane icon"></i>
      </div>
      <div class="col-md-8">
        Send to Teacher
      </div>
    </div>
  </div>
</div>
