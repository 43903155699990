import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iSpotTheDifferenceActivity } from '../../models/spotthedifferenceactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';

declare var $: any;

export class iSpots{
  id: number;
  text: string; 
  found: boolean;
}

@Component({
    selector: 'littlespotthedifference',
    templateUrl: './littlespotthedifference.component.html',
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class LittleSpotTheDifferenceComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iSpotTheDifferenceActivity;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  dict: any = {};
  style_top: string[];
  style_left: string[];
  tool_tips: string[];
  art_details: string;
  hide_spots: boolean;
  spots: iSpots[];
  differences_left: number;

  spotCheck: number[];
  disabled: boolean=true;
  directions: string;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService ) {
      this.tool_tips = [];
      this.style_left = [];
      this.style_top = [];
      this.hide_spots = true;
      this.spots = [];
      this.spotCheck=[];
     }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];
      console.log(this.activity)
      this.differences_left = this.activity.data.hotspots.length;
      this.activity.data.hotspots.forEach(h => {
        this.spots.push({id: h.id, text: h.text, found: false});
      })
      
      this.art_details = "<div>Artist:<br>" + this.activity.data.artist + "<br><br>Location:<br>" + this.activity.data.art_location + "</div>";

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data.directions && this.activity.data.directions !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data.directions;
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  img:any;
  showSpots(){
    this.img = document.getElementById("stdImg");
    this.drawHotSpots().then(res => this.addTooltips());
    this.disabled = true;  
  }

  showHideSpots(){
    this.hide_spots = !this.hide_spots;
  }

  drawHotSpots(){
    return new Promise((resolve) => {
      this.tool_tips = [];
      setTimeout(() => {
        let width = this.img.clientWidth;
        let height = this.img.clientHeight;

        const imag = new Image();
        imag.src = this.activity.data.image_different.file

        imag.onload = () => {
          let xScale = width / imag.naturalWidth;
          let yScale = height / imag.naturalHeight;

          this.activity.data.hotspots.forEach(spot => {
            let x = spot.x_coord * xScale - 45;
            let y = spot.y_coord * yScale - 45;
            this.style_top.push(y + 28.8 + "");
            this.style_left.push(x + 48 + "");
            this.tool_tips.push(spot.text);
          });
          this.hide_spots = false;
        }
        resolve(true);
      }, 500);
    });
  }

  spotFound(spot: iSpots,i:number){
    if(!spot.found) {
      this.differences_left--;
      this.spots.find(s => s.id === spot.id).found = true;

    }

    var check = this.spotCheck.length === 0 ? false : this.spotCheck.includes(i);
    
    if (check === false) {
      this.spotCheck.push(i);
      if (this.spots.length === this.spotCheck.length) {
        this.disabled = false;
      }
    }
  }

  clearAllSpots() {
    this.spotCheck = [];
    this.differences_left = this.activity.data.hotspots.length;
    this.spots.map((item) => item.found = false)
  }

  addTooltips(){
    setTimeout(() => {
      $(function(){
        $('[data-toggle="popover"]').popover();
      });

      $('[data-toggle="popover"]').on('click', function (e) {
        $('[data-toggle="popover"]').not(this).popover('hide');
      });
    }, 1000); 
  }

  submitActivity(show_success: boolean){
    this.sharedService.submitActivityResponse(this.activity.id, {completed: true}, show_success);
  }

  resubmit(){
    this.sharedService.resubmitActivityResponse(this.activity.id, {completed: true} , true);
    //Refresh this page and nav menu
    this.sharedService.setReloadSectionActivities(true);
    this.sharedService.setReloadNavMenu(true);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); }; 
    var el = document.getElementById('difference-modal' + this.activity.activity_number);
    el.remove();    

  }
}
