<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>
  <div class="row">
    <div class="col-lg-12 col-xl-8">
      <div id='puzzle'></div>
    </div>
    <div class="col-lg-12 col-xl-4">
      <div id='words'></div>
    </div>
  </div>
  
  <div class="text-center save-activity-button">
    <littleactivitydonedisabledbutton *ngIf="!activity.locked && !selected_section?.completed && disabled"></littleactivitydonedisabledbutton>
    <littleactivitydonebutton *ngIf="!activity.locked && !activity.completed && !disabled" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed && !disabled" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></littleactivitysubmittedbutton>
  </div>
</div>
  
  
