<!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="150" viewBox="0 0 291.21 104.23" style="cursor: pointer;">
  <defs>
    <style>
      .cls-1-little-done {
        fill: #858798;
      }

      .cls-2-little-done {
        fill: none;
      }

      .cls-disabled {
        fill: #858798;
      }

      .cls-4-text, .cls-7-little-done {
        fill: #fff;
      }

      .cls-4-text {
        font-size: 32px;
        font-family: Arimo-Bold, Arimo;
        font-weight: 700;
      }

      .cls-5-little-done {
        clip-path: url(#clip-path);
      }

      .cls-8-little-done, .cls-9-little-done {
        stroke: none;
      }

      .cls-9-little-done {
        fill: #fff;
      }

      .cls-10-little-done {
        filter: url(#Path_236);
      }

      .cls-11-little-done {
        filter: url(#Done_);
      }

      .cls-12-little-done {
        filter: url(#Path_149);
      }
    </style>
    <filter id="Path_149" x="0" y="0" width="291.21" height="104.23" filterUnits="userSpaceOnUse">
      <feOffset dx="1" dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2.5" result="blur"/>
      <feFlood flood-opacity="0.322"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Done_" x="51.605" y="27.5" width="224" height="54" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_136" data-name="Rectangle 136" class="cls-1-little-done" width="254.21" height="66.23" rx="24" transform="translate(389 653)"/>
    </clipPath>
    <filter id="Path_236" x="63.1" y="26.863" width="52.505" height="52.505" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-3"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-3"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_265" data-name="Group 265" transform="translate(-1522.948 -1953.27)">
    <g id="Group_262" data-name="Group 262" transform="translate(717.553 -722.73)">
      <g class="cls-12" transform="matrix(1, 0, 0, 1, 805.4, 2676)">
        <g id="Path_149-2" data-name="Path 149" class="cls-2-little-done" transform="translate(16.5 15.5)">
          <path class="cls-8-little-done" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z"/>
          <path class="cls-9-little-done" d="M 24 0 C 10.74516296386719 0 0 10.74516296386719 0 23.99999618530273 L 0 45.22999572753906 C 0 58.48483276367188 10.74516296386719 69.22999572753906 24 69.22999572753906 L 232.2099914550781 69.22999572753906 C 245.4648284912109 69.22999572753906 256.2099914550781 58.48483276367188 256.2099914550781 45.22999572753906 L 256.2099914550781 23.99999618530273 C 256.2099914550781 10.74516296386719 245.4648284912109 0 232.2099914550781 0 L 24 0 M 24 -10 L 232.2099914550781 -10 C 250.9576568603516 -10 266.2099914550781 5.252330780029297 266.2099914550781 23.99999618530273 L 266.2099914550781 45.22999572753906 C 266.2099914550781 63.97766494750977 250.9576568603516 79.22999572753906 232.2099914550781 79.22999572753906 L 24 79.22999572753906 C 5.252334594726562 79.22999572753906 -10 63.97766494750977 -10 45.22999572753906 L -10 23.99999618530273 C -10 5.252330780029297 5.252334594726562 -10 24 -10 Z"/>
        </g>
      </g>
      <path id="Path_150" data-name="Path 150" class="cls-disabled" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(821.895 2693.5)"/>
      <path id="Path_148" data-name="Path 148" class="cls-disabled" d="M24,0H232.21a24,24,0,0,1,24,24V45.23a24,24,0,0,1-24,24H24a24,24,0,0,1-24-24V24A24,24,0,0,1,24,0Z" transform="translate(821.895 2691.5)"/>
      <g class="cls-11-little-done" transform="matrix(1, 0, 0, 1, 805.4, 2676)">
        <text id="Done_2" data-name="Done!" class="cls-4-text" transform="translate(60.6 33.5)"><tspan x="57.672" y="29">Done!</tspan></text>
      </g>
      <g id="Mask_Group_19" data-name="Mask Group 19" class="cls-5-little-done" transform="translate(433.895 2039.5)">
        <path id="Path_147" data-name="Path 147" class="cls-disabled" d="M306.942,32.768c0,10.408-63.514,18.886-141.871,18.886S23.2,43.176,23.2,32.768,86.369,13.4,164.726,13.4,306.942,22.36,306.942,32.768Z" transform="translate(350.616 619.311)"/>
      </g>
      <g class="cls-10-little-done" transform="matrix(1, 0, 0, 1, 805.4, 2676)">
        <path id="Path_236-2" data-name="Path 236" class="cls-7-little-done" d="M485.079-107.9a17.253,17.253,0,0,0-17.253,17.253A17.253,17.253,0,0,0,485.079-73.4a17.253,17.253,0,0,0,17.253-17.252A17.253,17.253,0,0,0,485.079-107.9ZM496.12-95.469,483.033-82.383a1.386,1.386,0,0,1-1.961,0l-2.463-2.463-4.572-4.572a1.386,1.386,0,0,1,0-1.961l1.482-1.483a1.386,1.386,0,0,1,1.961,0l4.572,4.572,10.623-10.623a1.386,1.386,0,0,1,1.961,0l1.483,1.482A1.387,1.387,0,0,1,496.12-95.469Z" transform="translate(-395.73 140.76)"/>
      </g>
    </g>
  </g>
</svg> -->

<div style="width: 100%;">
  <div class="little-activity-done-disabled-button">
    <i class="fas fa-check-circle"></i> Done!
  </div>
</div>
