<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>
    
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let e of timeline_elements; let i = index" cdkDrag>
            <div *ngIf="activity.data.resource_type == 'Text'">
                {{ e.text }}
            </div>
            <div *ngIf="activity.data.resource_type == 'Image'">
                <div *ngIf="e.image" class="text-center">
                    <img [src]="e.image.file" />
                </div>
            </div>
        </div>
    </div>
    <div class="text-center save-activity-button">
        <littleactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
        <littleactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
        <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></littleactivitysubmittedbutton>
    </div>
</div>