<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>
  <div *ngIf="activity.response" style="margin-bottom: 40px;">
    <img [src]="activity.response.data.file" style="width: 100%; border-radius: 6px;" />
  </div>
  <fileupload (uploadEvent)="uploadedFiles($event)" name="image"></fileupload>
  

  <div class="text-center save-activity-button">
    <littleactivitydonedisabledbutton *ngIf="!activity.locked && !selected_section.completed && disabled" data-dismiss="modal"></littleactivitydonedisabledbutton>
    <littleactivitydonebutton *ngIf="!activity.locked && !activity.completed && !disabled" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed && !disabled" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></littleactivitysubmittedbutton>
  </div>
</div>