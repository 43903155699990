import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iTextActivity } from '../../models/textactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';
import { iImage } from '../../models/image.interface';

@Component({
    selector: 'bigphotoupload',
    templateUrl: './bigphotoupload.component.html',
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class BigPhotoUploadComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iTextActivity;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  response: iImage; 
  directions: string;
  disabled: boolean;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService ) {
      this.disabled = true; 
    }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data['directions'] && this.activity.data['directions'] !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  uploadedFiles($event) {
    this.response = $event;
    this.disabled = false;
  }

  submitActivity(show_success: boolean){
    this.sharedService.submitActivityResponse(this.activity.id, this.response, show_success);
  }

  resubmit(){
    this.resubmitResponse.emit(this.response);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
