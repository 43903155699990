<div *ngIf="activity && selected_section">
  <div *ngIf="directions" [innerHtml]="directions | safeHtml" class="little-directions"></div>

  <div *ngFor="let row of activity.data.rows; let i = index">
    <div class="row">
        <div *ngFor="let c of row.row" [ngClass]="bsCol" class="chartRow">
          <div *ngIf="c.answer == 'false' && i == 0" style="padding-left: 20px; padding-right: 20px;"><strong>{{c.cell}}</strong></div>
          <div *ngIf="c.answer == 'false' && i !== 0" style="padding-left: 20px; padding-right: 20px;">{{c.cell}}</div>
          <div *ngIf="c.answer == 'true'" style="width: 100%; height: 100%;padding-left: 5px; padding-right: 5px;">
            <textarea class="form-control little-chart-text-area" rows="3" [(ngModel)]="c.cell" (keyup)="submitActivity(false)"></textarea>
            <div class="auto-save-label">autosaved {{ saved_time | date:'shortTime' }}</div>
          </div>
        </div>
    </div>
  </div>

  <div class="text-center save-activity-button">
    <littleactivitydonebutton *ngIf="!activity.locked && !activity.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitydonebutton *ngIf="!activity.locked && activity.completed && !selected_section.completed" (click)="submitActivity(true)"></littleactivitydonebutton>
    <littleactivitysubmittedbutton *ngIf="activity.locked && activity.completed"></littleactivitysubmittedbutton>
  </div>
</div>
