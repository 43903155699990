import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { iCrosswordActivity } from '../../models/crosswordactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../shared/services/shared.service';
import { iActivityResponse } from '../../models/studentactivities.interface';

export interface iWord {
  direction: string,
  id: number,
  locations: number[],
  number: string,
  question: string,
  word: string
}

export interface cell{
  id: number,
  wbg: boolean,
  letter: string
};

@Component({
  selector: 'littlecrossword',
  templateUrl: './littlecrossword.component.html',
  providers: [ StudentGradesService, StudentSectionsService ]
})

export class LittleCrosswordComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iCrosswordActivity;
  selected_section: iStudentSection;
  ssservice: any;
  activity_response: iActivityResponse;
  response: string[][];
  across_words: iWord[];
  down_words: iWord[];
  max_num_words: number;
  cells: cell[];
  selected_word: iWord;
  directions: string;
  temp_cell_id: number = -999;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private authService: AuthService,
    private sharedService: SharedService ) {
      this.across_words = [];
      this.down_words = [];
      this.max_num_words = 0;
      this.cells = [] as {id:number, wbg:boolean, letter: string}[];

      this.response = [];
      for(var row: number = 0; row < 20; row++) {
        this.response[row] = [];
        for(var col: number = 0; col < 20; col++) {
            this.response[row][col] = "-";
        }
      }
    }

  ngOnInit() {
    for(let x = 0; x < 400; x++){
      let c: cell = {id: x, wbg: false, letter: ""};
      this.cells.push(c);
    }

    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe((activity) => {
      this.activity = activity['data'];

      this.activity.data.usedWords.forEach(w => {
        if (w.direction == "Across"){
          this.across_words.push(w);
        } else {
          this.down_words.push(w);
        }

        if (this.activity.response){
          w["locations"].forEach(loc => {
            this.cells[loc] = {wbg: true, letter: this.activity.response.data[Math.floor(loc / 20)][loc % 20], id: this.cells[loc].id};
          })
        } else {
          w["locations"].forEach(loc => {
            this.cells[loc] = {wbg: true, letter: "", id: this.cells[loc].id};
          })
        }
      })

      this.across_words.sort(function(a, b) {
        return parseInt(a.number) - parseInt(b.number);
      });

      this.down_words.sort(function(a, b) {
        return parseInt(a.number) - parseInt(b.number);
      });

      this.max_num_words = this.across_words.length >= this.down_words.length ? this.across_words.length : this.down_words.length;
      this.selected_word = this.across_words[0];

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data['directions'] && this.activity.data['directions'] !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  highlightHint(word: iWord){
    this.selected_word = word;
  }

  highlightCells(id){
    if (id !== this.temp_cell_id){
      let words = this.activity.data.usedWords.filter(w => w.locations.indexOf(id) > -1)
    
      if (words[1]){
        this.selected_word = this.selected_word == words[0] ? words[1] : words[0];
      } else {
        this.selected_word = words[0];
        this.temp_cell_id = id;
      }
    }
  }

  onKeyPress(event, index) {
    if (event.keyCode === 13){
      event.target.value = '';
    } else {
      this.cells[index].letter = event.key.toUpperCase();;
      if( event.inputType !== "deleteContentBackward" && event.keyCode !== 13 ){
        event.target.value = event.target.value.toUpperCase();
        let next_index = this.getNextHighestIndex(this.selected_word.locations, index);
        if (next_index < this.selected_word.locations.length){
          let id = this.selected_word.locations[next_index];
          let cell = document.getElementById(id.toString());
          cell.focus();
        }
      }
    }
  }

  getNextHighestIndex(arr, value): number {
    let i = arr.length;
    while (arr[--i] > value);
    return ++i; 
  }

  submitActivity(show_success: boolean){
    let index = 0;
    for (let row: number = 0; row < 20; row++){
      for (let col: number = 0; col < 20; col++){
        if (this.cells[index].letter !== ""){
          this.response[row][col] = this.cells[index].letter;
        }
        index++;
      }
    }
    
    this.sharedService.submitActivityResponse(this.activity.id, this.response, show_success);
  }

  resubmit(){
    let index = 0;
    for (let row: number = 0; row < 20; row++){
      for (let col: number = 0; col < 20; col++){
        if (this.cells[index].letter !== ""){
          this.response[row][col] = this.cells[index].letter;
        }
        index++;
      }
    }

    this.sharedService.resubmitActivityResponse(this.activity.id, this.response , true);
    //Refresh this page and nav menu
    this.sharedService.setReloadSectionActivities(true);
    this.sharedService.setReloadNavMenu(true);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
    var el = document.getElementById('crossword-modal' + this.activity.activity_number);
    el.remove(); 
  }
}
