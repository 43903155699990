<svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 17.111 22">
  <defs>
    <style>
      .cls-primary-color {
        fill: #fff;
      }
    </style>
  </defs>
  <path id="Icon_Activities" data-name="Icon / Activities" class="cls-primary-color" d="M19.667,3H7.444A2.452,2.452,0,0,0,5,5.444V25l8.556-3.667L22.111,25V5.444A2.452,2.452,0,0,0,19.667,3ZM17.589,18.278l-4.033-2.444L9.522,18.278l1.1-4.522L7.078,10.7l4.644-.367,1.833-4.278,1.833,4.278,4.644.367-3.544,3.056Z" transform="translate(-5 -3)"/>
</svg>
