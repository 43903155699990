import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from "ng-recaptcha";
import { NgSelect2Module } from 'ng-select2';

import { ProductOrderFormComponent } from "./productorderform.component";

const PRODUCT_ORDER_FORM_ROUTE = [
    { path: '', component: ProductOrderFormComponent }
];

@NgModule ({
    declarations: [
        ProductOrderFormComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(PRODUCT_ORDER_FORM_ROUTE),
        RecaptchaModule,
        NgSelect2Module
    ]
})

export class ProductOrderFormModule {  }