import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { iInstitute } from '../models/institute.interface';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'jagyw1xbTYRq15AJF2uP6k3z'
    })
};

@Injectable()
export class InstituteService {
    constructor(private http: HttpClient) { }

    CreateInstitution(Institute: iInstitute): Observable<any> {
        return this.http.post(environment.conn_string + "api/institutes", Institute, httpOptions);
    }

    GetInstitutionDetails(InstituteId: string) {
        return this.http.get(environment.conn_string + "api/institutes/details/" + InstituteId, httpOptions);
    }

    GetInstitutionsByDiocese(DioceseId: string) {
        return this.http.get(environment.conn_string + "api/institutes/by_diocese/" + DioceseId, httpOptions);
    }

    UpdateInstitution(Institute): Observable<iInstitute> {
        return this.http.put<iInstitute>(environment.conn_string + "api/institutes/" + Institute['id'], Institute, httpOptions);
    }
}