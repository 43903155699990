import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { AuthService } from '../../auth/auth.service';
import { iStudentNotifications } from '../../models/notification.interface';
import { NotificationService } from '../../services/Notification.service';
import { ToastrService } from 'ngx-toastr';
import { StudentUnitsService } from '../../services/StudentUnits.service';
import { iStudentNavUnits } from '../../models/studentunits.interface';

@Component({
    selector: 'littlenotifications',
    templateUrl: './littlenotifications.component.html',
    providers: [ NotificationService, StudentUnitsService ]
})

export class LittleNotificationsComponent implements OnInit, OnDestroy {
  n_unread: iStudentNotifications[];
  n_read: iStudentNotifications[];
  nservice: any;
  ssservice: any;
  bell_classes: string;

  constructor(
    private nServ: NotificationService,
    private ssServ: StudentUnitsService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit(){
    this.sharedService.getUnreadNotifications().subscribe(value => {
      this.n_unread = value;
    })

    this.sharedService.getReadNotifications().subscribe(value => {
      this.n_read = value;
    })
  }

  markNotificationAsRead(notification_id: string){
    this.nservice = this.nServ.MarkRead(notification_id).subscribe(success => {
      this.sharedService.getNotifications();
    }, error => {
      this.toastr.error('Oops, something went wrong!')
    })
  }

  navigateToSection(notification: iStudentNotifications){
    if (notification.unit_id && notification.lesson_id && notification.section_id){
      this.ssservice = this.ssServ.GetStudentNavUnitDetails(this.authService.getId(), notification.unit_id.toString()).subscribe((u: iStudentNavUnits) => {
        this.sharedService.setSelectedUnit(u['data']);
        this.sharedService.setSelectedLessonId(notification.lesson_id.toString());
        this.sharedService.setSelectedSectionId(notification.section_id.toString());
        this.router.navigate(['/app/sectionactivities']);
      })
    }
  }
  
  ngOnDestroy() {
    if (this.nservice){ this.nservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
  }
}
