<svg xmlns="http://www.w3.org/2000/svg" width="150" viewBox="0 0 256 50" style="cursor: pointer;">
  <defs>
    <style>
      .cls-secondary-color {
        fill: #ae529b;
      }

      .cls-fill-white {
        fill: #fff;
      }

      .cls-resubmit-btn {
        font-size: 22px !important;
        font-family: Arimo-Bold, Arimo;
        font-weight: 700;
      }
    </style>
  </defs>
  <g id="Group_245" data-name="Group 245" transform="translate(1061.5 -18237)">
    <g id="Group_227" data-name="Group 227" transform="translate(-7 66)">
      <path id="Path_166" data-name="Path 166" class="cls-secondary-color" d="M4,0H252a4,4,0,0,1,4,4V46a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(-1054.5 18171)"/>
      <text id="RESUBMIT" class="cls-resubmit-btn cls-fill-white" transform="translate(-1043.5 18183)"><tspan x="59.556" y="20">RESUBMIT</tspan></text>
    </g>
    <path id="undo-alt" class="cls-fill-white" d="M22.972,8a14.949,14.949,0,0,0-10.334,4.154l-2.16-2.16A1.452,1.452,0,0,0,8,11.02v8.108A1.452,1.452,0,0,0,9.452,20.58H17.56A1.452,1.452,0,0,0,18.586,18.1l-2.525-2.525a10.161,10.161,0,1,1,.247,15.07.725.725,0,0,0-.99.033l-2.4,2.4a.727.727,0,0,0,.029,1.054A15,15,0,1,0,22.972,8Z" transform="translate(-1048 18238.668)"/>
  </g>
</svg>
