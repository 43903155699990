<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" viewBox="0 0 214.21 210.764">
  <defs>
    <filter id="Rectangle_142" x="0" y="0" width="159.21" height="196.522" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_78" x="40.1" y="31.764" width="133" height="178.999" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_144" x="55" y="14" width="159.21" height="196.522" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-3"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-3"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_146" x="39.62" y="13.95" width="143.951" height="186.628" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-4"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-4"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#e8a957"/>
      <stop offset="1" stop-color="#ce8b3d"/>
    </radialGradient>
    <filter id="Rectangle_141" x="0" y="0" width="159.21" height="196.524" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-5"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-5"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_142)">
        <rect id="Rectangle_142-2" data-name="Rectangle 142" width="115" height="160.999" rx="15" transform="translate(0 0)" fill="#fff"/>
      </g>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect width="110.142" height="153.141" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-3">
      <rect width="29.287" height="29.287" fill="none"/>
    </clipPath>
    <filter id="Path_69" x="73.724" y="11.879" width="41.749" height="41.749" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-6"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-6"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_135" x="40.1" y="31.764" width="133" height="179" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-7"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-7"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path-6">
      <rect width="29.424" height="29.424" fill="none"/>
    </clipPath>
    <filter id="Path_69-2" x="113.966" y="40.246" width="38.597" height="38.597" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-8"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-8"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_143" x="55" y="14" width="159.21" height="196.524" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-9"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-9"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path-9">
      <rect width="29.311" height="29.311" fill="none"/>
    </clipPath>
    <filter id="Path_69-3" x="149.704" y="34.863" width="41.769" height="41.768" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-10"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-10"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_145" x="39.62" y="13.95" width="143.951" height="186.63" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-11"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-11"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path-12">
      <rect width="29.286" height="29.286" fill="none"/>
    </clipPath>
    <filter id="Path_69-4" x="112.996" y="23.645" width="39.88" height="39.88" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-12"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-12"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_100" data-name="Group 100" transform="translate(-31.9 -288.236)">
    <g id="Card_1" data-name="Card 1" transform="translate(-10.4 103.92) rotate(-10)">
      <g transform="matrix(0.98, 0.17, -0.17, 0.98, 9.65, 188.86)" filter="url(#Rectangle_141)">
        <g id="Rectangle_141-2" data-name="Rectangle 141" transform="translate(9 25.97) rotate(-10)" stroke="#fff" stroke-width="5" fill="url(#radial-gradient)">
          <rect width="115" height="161" rx="15" stroke="none"/>
          <rect x="2.5" y="2.5" width="110" height="156" rx="12.5" fill="none"/>
        </g>
      </g>
      <g id="Mask_Group_21" data-name="Mask Group 21" transform="matrix(1, 0, 0, 1, 14.005, 216)" opacity="0.25" clip-path="url(#clip-path)">
        <g id="Repeat_Grid_5" data-name="Repeat Grid 5" transform="translate(1 3.968)" clip-path="url(#clip-path-2)">
          <g transform="translate(-0.358 -216.126)">
            <path id="Path_466" data-name="Path 466" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -216.126)">
            <path id="Path_467" data-name="Path 467" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -216.126)">
            <path id="Path_468" data-name="Path 468" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -216.126)">
            <path id="Path_469" data-name="Path 469" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -216.126)">
            <path id="Path_470" data-name="Path 470" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -216.126)">
            <path id="Path_471" data-name="Path 471" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -216.126)">
            <path id="Path_472" data-name="Path 472" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -188.126)">
            <path id="Path_473" data-name="Path 473" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -188.126)">
            <path id="Path_474" data-name="Path 474" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -188.126)">
            <path id="Path_475" data-name="Path 475" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -188.126)">
            <path id="Path_476" data-name="Path 476" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -188.126)">
            <path id="Path_477" data-name="Path 477" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -188.126)">
            <path id="Path_478" data-name="Path 478" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -188.126)">
            <path id="Path_479" data-name="Path 479" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -160.126)">
            <path id="Path_480" data-name="Path 480" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -160.126)">
            <path id="Path_481" data-name="Path 481" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -160.126)">
            <path id="Path_482" data-name="Path 482" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -160.126)">
            <path id="Path_483" data-name="Path 483" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -160.126)">
            <path id="Path_484" data-name="Path 484" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -160.126)">
            <path id="Path_485" data-name="Path 485" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -160.126)">
            <path id="Path_486" data-name="Path 486" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -132.126)">
            <path id="Path_487" data-name="Path 487" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -132.126)">
            <path id="Path_488" data-name="Path 488" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -132.126)">
            <path id="Path_489" data-name="Path 489" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -132.126)">
            <path id="Path_490" data-name="Path 490" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -132.126)">
            <path id="Path_491" data-name="Path 491" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -132.126)">
            <path id="Path_492" data-name="Path 492" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -132.126)">
            <path id="Path_493" data-name="Path 493" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -104.126)">
            <path id="Path_494" data-name="Path 494" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -104.126)">
            <path id="Path_495" data-name="Path 495" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -104.126)">
            <path id="Path_496" data-name="Path 496" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -104.126)">
            <path id="Path_497" data-name="Path 497" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -104.126)">
            <path id="Path_498" data-name="Path 498" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -104.126)">
            <path id="Path_499" data-name="Path 499" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -104.126)">
            <path id="Path_500" data-name="Path 500" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -76.126)">
            <path id="Path_501" data-name="Path 501" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -76.126)">
            <path id="Path_502" data-name="Path 502" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -76.126)">
            <path id="Path_503" data-name="Path 503" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -76.126)">
            <path id="Path_504" data-name="Path 504" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -76.126)">
            <path id="Path_505" data-name="Path 505" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -76.126)">
            <path id="Path_506" data-name="Path 506" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -76.126)">
            <path id="Path_507" data-name="Path 507" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
        </g>
      </g>
      <text id="Vulgate" transform="translate(72.002 302.684)" fill="#fff" font-size="16" font-family="Arimo-Bold, Arimo" font-weight="700"><tspan x="-28.598" y="0">Vulgate</tspan></text>
      <g id="Ribbon" transform="matrix(1, 0, 0, 1, -130.437, -83.958)">
        <g id="noun_Ribbon_1770265" transform="translate(211 300)">
          <g id="Group_98" data-name="Group 98" transform="translate(0 0)">
            <path id="Path_156" data-name="Path 156" d="M140,137.971l17.066-8.489,17.1,8.489V91H140Z" transform="translate(-140 -91)" fill="#f2be37" fill-rule="evenodd"/>
          </g>
        </g>
        <g id="Icon_Star" data-name="Icon / Star" transform="translate(213.441 305.369)" clip-path="url(#clip-path-3)">
          <path id="Path_68" data-name="Path 68" d="M0,0H20.946V23.5H0Z" transform="translate(4.171 2.706)" fill="none"/>
          <g transform="matrix(0.98, 0.17, -0.17, 0.98, -73.35, -32.55)" filter="url(#Path_69)">
            <path id="Path_69-5" data-name="Path 69" d="M12.25,18.476,18.585,22.5,16.9,14.915l5.6-5.1-7.37-.658L12.25,2,9.37,9.154,2,9.812l5.6,5.1L5.916,22.5Z" transform="translate(80.41 19.82) rotate(-10)" fill="#fff"/>
          </g>
          <path id="Path_70" data-name="Path 70" d="M0,0H20.946V23.5H0Z" transform="translate(4.171 2.706)" fill="none"/>
        </g>
      </g>
    </g>
    <g id="Card_2" data-name="Card 2" transform="translate(67 110)">
      <g transform="matrix(1, 0, 0, 1, -35.1, 178.24)" filter="url(#Rectangle_135)">
        <g id="Rectangle_135-2" data-name="Rectangle 135" transform="translate(49.1 37.76)" stroke="#fff" stroke-width="5" fill="url(#radial-gradient)">
          <rect width="115" height="161" rx="15" stroke="none"/>
          <rect x="2.5" y="2.5" width="110" height="156" rx="12.5" fill="none"/>
        </g>
      </g>
      <g id="Mask_Group_18" data-name="Mask Group 18" transform="translate(14 216)" opacity="0.25" clip-path="url(#clip-path)">
        <g id="Repeat_Grid_2" data-name="Repeat Grid 2" transform="translate(1 3.968)" clip-path="url(#clip-path-2)">
          <g transform="translate(-0.358 -216.126)">
            <path id="Path_157" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -216.126)">
            <path id="Path_157-2" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -216.126)">
            <path id="Path_157-3" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -216.126)">
            <path id="Path_157-4" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -216.126)">
            <path id="Path_157-5" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -216.126)">
            <path id="Path_157-6" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -216.126)">
            <path id="Path_157-7" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -188.126)">
            <path id="Path_157-8" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -188.126)">
            <path id="Path_157-9" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -188.126)">
            <path id="Path_157-10" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -188.126)">
            <path id="Path_157-11" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -188.126)">
            <path id="Path_157-12" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -188.126)">
            <path id="Path_157-13" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -188.126)">
            <path id="Path_157-14" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -160.126)">
            <path id="Path_157-15" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -160.126)">
            <path id="Path_157-16" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -160.126)">
            <path id="Path_157-17" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -160.126)">
            <path id="Path_157-18" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -160.126)">
            <path id="Path_157-19" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -160.126)">
            <path id="Path_157-20" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -160.126)">
            <path id="Path_157-21" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -132.126)">
            <path id="Path_157-22" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -132.126)">
            <path id="Path_157-23" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -132.126)">
            <path id="Path_157-24" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -132.126)">
            <path id="Path_157-25" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -132.126)">
            <path id="Path_157-26" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -132.126)">
            <path id="Path_157-27" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -132.126)">
            <path id="Path_157-28" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -104.126)">
            <path id="Path_157-29" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -104.126)">
            <path id="Path_157-30" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -104.126)">
            <path id="Path_157-31" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -104.126)">
            <path id="Path_157-32" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -104.126)">
            <path id="Path_157-33" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -104.126)">
            <path id="Path_157-34" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -104.126)">
            <path id="Path_157-35" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -76.126)">
            <path id="Path_157-36" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -76.126)">
            <path id="Path_157-37" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -76.126)">
            <path id="Path_157-38" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -76.126)">
            <path id="Path_157-39" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -76.126)">
            <path id="Path_157-40" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -76.126)">
            <path id="Path_157-41" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -76.126)">
            <path id="Path_157-42" data-name="Path 157" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
        </g>
      </g>
      <text id="Vulgate-2" data-name="Vulgate" transform="translate(72.033 302.337)" fill="#fff" font-size="16" font-family="Arimo-Bold, Arimo" font-weight="700"><tspan x="-28.598" y="0">Vulgate</tspan></text>
      <g id="Ribbon-2" data-name="Ribbon" transform="translate(-130 -83.854)">
        <g id="noun_Ribbon_1770265-2" data-name="noun_Ribbon_1770265" transform="translate(211 300)">
          <g id="Group_98-2" data-name="Group 98" transform="translate(0 0)">
            <path id="Path_156-2" data-name="Path 156" d="M140,138.191l17.145-8.528,17.183,8.528V91H140Z" transform="translate(-140 -91)" fill="#f2be37" fill-rule="evenodd"/>
          </g>
        </g>
        <g id="Icon_Star-2" data-name="Icon / Star" transform="translate(213.452 305.394)" clip-path="url(#clip-path-6)">
          <path id="Path_68-2" data-name="Path 68" d="M0,0H21.044V23.615H0Z" transform="translate(4.19 2.719)" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, -118.55, -43.3)" filter="url(#Path_69-2)">
            <path id="Path_69-6" data-name="Path 69" d="M12.3,18.553,18.663,22.6l-1.689-7.621L22.6,9.848l-7.4-.661L12.3,2,9.4,9.187,2,9.848l5.623,5.128L5.934,22.6Z" transform="translate(120.97 44.25)" fill="#fff"/>
          </g>
          <path id="Path_70-2" data-name="Path 70" d="M0,0H21.044V23.615H0Z" transform="translate(4.19 2.719)" fill="none"/>
        </g>
      </g>
    </g>
    <g id="Card_3" data-name="Card 3" transform="matrix(0.985, 0.174, -0.174, 0.985, 147.573, 93.089)">
      <g transform="matrix(0.98, -0.17, 0.17, 0.98, -80.03, 212.27)" filter="url(#Rectangle_143)">
        <g id="Rectangle_143-2" data-name="Rectangle 143" transform="matrix(0.98, 0.17, -0.17, 0.98, 91.96, 20)" stroke="#fff" stroke-width="5" fill="url(#radial-gradient)">
          <rect width="115" height="161" rx="15" stroke="none"/>
          <rect x="2.5" y="2.5" width="110" height="156" rx="12.5" fill="none"/>
        </g>
      </g>
      <g id="Mask_Group_22" data-name="Mask Group 22" transform="translate(14.004 215.997)" opacity="0.25" clip-path="url(#clip-path)">
        <g id="Repeat_Grid_6" data-name="Repeat Grid 6" transform="translate(1 3.968)" clip-path="url(#clip-path-2)">
          <g transform="translate(-0.358 -216.126)">
            <path id="Path_508" data-name="Path 508" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -216.126)">
            <path id="Path_509" data-name="Path 509" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -216.126)">
            <path id="Path_510" data-name="Path 510" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -216.126)">
            <path id="Path_511" data-name="Path 511" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -216.126)">
            <path id="Path_512" data-name="Path 512" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -216.126)">
            <path id="Path_513" data-name="Path 513" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -216.126)">
            <path id="Path_514" data-name="Path 514" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -188.126)">
            <path id="Path_515" data-name="Path 515" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -188.126)">
            <path id="Path_516" data-name="Path 516" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -188.126)">
            <path id="Path_517" data-name="Path 517" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -188.126)">
            <path id="Path_518" data-name="Path 518" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -188.126)">
            <path id="Path_519" data-name="Path 519" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -188.126)">
            <path id="Path_520" data-name="Path 520" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -188.126)">
            <path id="Path_521" data-name="Path 521" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -160.126)">
            <path id="Path_522" data-name="Path 522" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -160.126)">
            <path id="Path_523" data-name="Path 523" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -160.126)">
            <path id="Path_524" data-name="Path 524" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -160.126)">
            <path id="Path_525" data-name="Path 525" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -160.126)">
            <path id="Path_526" data-name="Path 526" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -160.126)">
            <path id="Path_527" data-name="Path 527" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -160.126)">
            <path id="Path_528" data-name="Path 528" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -132.126)">
            <path id="Path_529" data-name="Path 529" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -132.126)">
            <path id="Path_530" data-name="Path 530" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -132.126)">
            <path id="Path_531" data-name="Path 531" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -132.126)">
            <path id="Path_532" data-name="Path 532" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -132.126)">
            <path id="Path_533" data-name="Path 533" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -132.126)">
            <path id="Path_534" data-name="Path 534" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -132.126)">
            <path id="Path_535" data-name="Path 535" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -104.126)">
            <path id="Path_536" data-name="Path 536" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -104.126)">
            <path id="Path_537" data-name="Path 537" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -104.126)">
            <path id="Path_538" data-name="Path 538" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -104.126)">
            <path id="Path_539" data-name="Path 539" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -104.126)">
            <path id="Path_540" data-name="Path 540" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -104.126)">
            <path id="Path_541" data-name="Path 541" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -104.126)">
            <path id="Path_542" data-name="Path 542" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -76.126)">
            <path id="Path_543" data-name="Path 543" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -76.126)">
            <path id="Path_544" data-name="Path 544" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -76.126)">
            <path id="Path_545" data-name="Path 545" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -76.126)">
            <path id="Path_546" data-name="Path 546" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -76.126)">
            <path id="Path_547" data-name="Path 547" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -76.126)">
            <path id="Path_548" data-name="Path 548" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -76.126)">
            <path id="Path_549" data-name="Path 549" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
        </g>
      </g>
      <text id="Vulgate-3" data-name="Vulgate" transform="translate(72.002 301.989)" fill="#fff" font-size="16" font-family="Arimo-Bold, Arimo" font-weight="700"><tspan x="-28.598" y="0">Vulgate</tspan></text>
      <g id="Ribbon-3" data-name="Ribbon" transform="translate(-130.971 -83.97)">
        <g id="noun_Ribbon_1770265-3" data-name="noun_Ribbon_1770265" transform="translate(211 300)">
          <g id="Group_98-3" data-name="Group 98" transform="translate(0 0)">
            <path id="Path_156-3" data-name="Path 156" d="M140,138.009l17.079-8.5,17.116,8.5V91H140Z" transform="translate(-140 -91)" fill="#f2be37" fill-rule="evenodd"/>
          </g>
        </g>
        <g id="Icon_Star-3" data-name="Icon / Star" transform="translate(213.443 305.374)" clip-path="url(#clip-path-9)">
          <path id="Path_68-3" data-name="Path 68" d="M0,0H20.963V23.524H0Z" transform="translate(4.174 2.708)" fill="none"/>
          <g transform="matrix(0.98, -0.17, 0.17, 0.98, -162.5, -9.13)" filter="url(#Path_69-3)">
            <path id="Path_69-7" data-name="Path 69" d="M12.259,18.49l6.34,4.028-1.682-7.591,5.6-5.108-7.376-.659L12.259,2,9.376,9.159,2,9.818l5.6,5.108L5.919,22.517Z" transform="matrix(0.98, 0.17, -0.17, 0.98, 160.64, 38.55)" fill="#fff"/>
          </g>
          <path id="Path_70-3" data-name="Path 70" d="M0,0H20.963V23.524H0Z" transform="translate(4.174 2.708)" fill="none"/>
        </g>
      </g>
    </g>
    <g id="Card_4" data-name="Card 4" transform="matrix(0.998, -0.07, 0.07, 0.998, 51.491, 101.71)">
      <g transform="matrix(1, 0.07, -0.07, 1, -32.55, 184.71)" filter="url(#Rectangle_145)">
        <g id="Rectangle_145-2" data-name="Rectangle 145" transform="matrix(1, -0.07, 0.07, 1, 48.62, 27.97)" stroke="#fff" stroke-width="5" fill="url(#radial-gradient)">
          <rect width="115" height="161" rx="15" stroke="none"/>
          <rect x="2.5" y="2.5" width="110" height="156" rx="12.5" fill="none"/>
        </g>
      </g>
      <g id="Mask_Group_23" data-name="Mask Group 23" transform="translate(13.995 216.001)" opacity="0.25" clip-path="url(#clip-path)">
        <g id="Repeat_Grid_7" data-name="Repeat Grid 7" transform="translate(1 3.968)" clip-path="url(#clip-path-2)">
          <g transform="translate(-0.358 -216.126)">
            <path id="Path_550" data-name="Path 550" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -216.126)">
            <path id="Path_551" data-name="Path 551" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -216.126)">
            <path id="Path_552" data-name="Path 552" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -216.126)">
            <path id="Path_553" data-name="Path 553" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -216.126)">
            <path id="Path_554" data-name="Path 554" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -216.126)">
            <path id="Path_555" data-name="Path 555" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -216.126)">
            <path id="Path_556" data-name="Path 556" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -188.126)">
            <path id="Path_557" data-name="Path 557" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -188.126)">
            <path id="Path_558" data-name="Path 558" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -188.126)">
            <path id="Path_559" data-name="Path 559" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -188.126)">
            <path id="Path_560" data-name="Path 560" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -188.126)">
            <path id="Path_561" data-name="Path 561" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -188.126)">
            <path id="Path_562" data-name="Path 562" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -188.126)">
            <path id="Path_563" data-name="Path 563" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -160.126)">
            <path id="Path_564" data-name="Path 564" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -160.126)">
            <path id="Path_565" data-name="Path 565" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -160.126)">
            <path id="Path_566" data-name="Path 566" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -160.126)">
            <path id="Path_567" data-name="Path 567" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -160.126)">
            <path id="Path_568" data-name="Path 568" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -160.126)">
            <path id="Path_569" data-name="Path 569" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -160.126)">
            <path id="Path_570" data-name="Path 570" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -132.126)">
            <path id="Path_571" data-name="Path 571" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -132.126)">
            <path id="Path_572" data-name="Path 572" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -132.126)">
            <path id="Path_573" data-name="Path 573" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -132.126)">
            <path id="Path_574" data-name="Path 574" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -132.126)">
            <path id="Path_575" data-name="Path 575" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -132.126)">
            <path id="Path_576" data-name="Path 576" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -132.126)">
            <path id="Path_577" data-name="Path 577" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -104.126)">
            <path id="Path_578" data-name="Path 578" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -104.126)">
            <path id="Path_579" data-name="Path 579" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -104.126)">
            <path id="Path_580" data-name="Path 580" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -104.126)">
            <path id="Path_581" data-name="Path 581" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -104.126)">
            <path id="Path_582" data-name="Path 582" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -104.126)">
            <path id="Path_583" data-name="Path 583" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -104.126)">
            <path id="Path_584" data-name="Path 584" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(-0.358 -76.126)">
            <path id="Path_585" data-name="Path 585" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(15.642 -76.126)">
            <path id="Path_586" data-name="Path 586" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(31.642 -76.126)">
            <path id="Path_587" data-name="Path 587" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(47.642 -76.126)">
            <path id="Path_588" data-name="Path 588" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(63.642 -76.126)">
            <path id="Path_589" data-name="Path 589" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(79.642 -76.126)">
            <path id="Path_590" data-name="Path 590" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
          <g transform="translate(95.642 -76.126)">
            <path id="Path_591" data-name="Path 591" d="M-7.178-7.178l15.94,4.06,4.06,15.94-15.94-4.06Z" transform="translate(14.5 226.277) rotate(45)" fill="#e8a957"/>
          </g>
        </g>
      </g>
      <g id="Ribbon-4" data-name="Ribbon" transform="translate(-130.858 -84.083)">
        <g id="noun_Ribbon_1770265-4" data-name="noun_Ribbon_1770265" transform="translate(211 300)">
          <g id="Group_98-4" data-name="Group 98" transform="translate(0 0)">
            <path id="Path_156-4" data-name="Path 156" d="M140,137.97l17.065-8.488,17.1,8.488V91H140Z" transform="translate(-140 -91)" fill="#f2be37" fill-rule="evenodd"/>
          </g>
        </g>
        <g id="Icon_Star-4" data-name="Icon / Star" transform="translate(213.441 305.369)" clip-path="url(#clip-path-12)">
          <path id="Path_68-4" data-name="Path 68" d="M0,0H20.945V23.5H0Z" transform="translate(4.17 2.706)" fill="none"/>
          <g transform="matrix(1, 0.07, -0.07, 1, -115.14, -36.58)" filter="url(#Path_69-4)">
            <path id="Path_69-8" data-name="Path 69" d="M12.25,18.476,18.585,22.5,16.9,14.915l5.6-5.1-7.37-.658L12.25,2,9.37,9.154,2,9.812l5.6,5.1L5.916,22.5Z" transform="matrix(1, -0.07, 0.07, 1, 119.86, 29.22)" fill="#fff"/>
          </g>
          <path id="Path_70-4" data-name="Path 70" d="M0,0H20.945V23.5H0Z" transform="translate(4.17 2.706)" fill="none"/>
        </g>
      </g>
    </g>
  </g>
</svg>
