import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { iRespondActivity } from '../../models/respondactivity.interface';
import { StudentGradesService } from '../../services/StudentGrades.service';
import { iStudentSection } from '../../models/studentsections.interface';
import { StudentSectionsService } from '../../services/StudentSections.service';
import { SharedService } from '../../shared/services/shared.service';
import { Subject } from 'rxjs/Subject';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'bigrespond',
    templateUrl: './bigrespond.component.html',
    providers: [ StudentGradesService, StudentSectionsService ]
})

export class BigRespondComponent implements OnInit, OnDestroy {
  @Input() activity_id: string;
  @Output() resubmitResponse = new EventEmitter();
  sgservice: any;
  activity: iRespondActivity;
  saved_time: number;
  respondForm: FormGroup;
  selected_section: iStudentSection;
  ssservice: any;
  private unsubscribe = new Subject<void>();
  directions: string;

  constructor( 
    private sgServ: StudentGradesService, 
    private ssServ: StudentSectionsService,
    private formBuilder: FormBuilder, 
    private sharedService: SharedService,
    private authService: AuthService ) { }

  ngOnInit() {
    this.sgservice = this.sgServ.GetStudentActivityDetails(this.activity_id).subscribe(activity => {
      this.activity = activity['data'];

      let i = 0;
      this.activity.data.questions.forEach(g =>{
        if (i == 0){
          this.respondForm = this.formBuilder.group({
            itemRows: this.formBuilder.array([this.initItemRows(g)])
          });
        } else {
          this.addExistingRow(g);
        }
        i++;
      })

      this.respondForm.valueChanges.pipe(
        debounceTime(500),
        switchMap(async (formValue) => this.submitActivity(false)),
        takeUntil(this.unsubscribe)
      ).subscribe(() => console.log('Saved'))

      this.ssservice = this.ssServ.GetStudentSectionDetails(this.authService.getId(), this.activity.section_id).subscribe((section: iStudentSection) => {
        this.selected_section = section['data'];

        if (this.activity.data['directions'] && this.activity.data['directions'] !== ""){
          this.directions = "<strong>Directions:</strong> " + this.activity.data['directions'];
        } else if (this.selected_section.section_directions){
          this.directions = "<strong>Directions:</strong> " + this.selected_section.section_directions;
        }
      })
    })
  }

  initItemRows(g: any) {
    let ans: string = '';
    if (this.activity.response && this.activity.response.data[g.id]){
      ans = this.activity.response.data[g.id].answer;
    }

    return this.formBuilder.group({
      questionId: [g.id],
      answer: [ans]
    });
  }

  addExistingRow(g: any) {
    const control = <FormArray>this.respondForm.controls['itemRows'];
    control.push(this.initItemRows(g));
  }

  getControls() {
    return (<FormArray>this.respondForm.get('itemRows')).controls;
  }

  submitActivity(show_success: boolean){
    this.sharedService.submitActivityResponse(this.activity.id, this.respondForm.value['itemRows'], show_success);
    
    this.sharedService.getSubmitActivitySuccess().subscribe(success => {
      if (success){
        this.saved_time = Date.now();
      }
    })
  }

  resubmit(){
    this.resubmitResponse.emit(this.respondForm.value['itemRows']);
  }
  
  ngOnDestroy() {
    if (this.sgservice){ this.sgservice.unsubscribe(); };
    if (this.ssservice){ this.ssservice.unsubscribe(); };
    this.unsubscribe.next();
  }
}
